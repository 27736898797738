import { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useRef } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Fragment } from "react";

const SupportInfoPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const options = ["Pending", "Open", "Solved"];
  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const fileRef = useRef(null);
  const [editorData, setEditorData] = useState("");
  return (
    <div className="d-flex flex-column" style={{ gap: "2em" }}>
      <div
        className="container-fluid d-flex flex-row"
        style={{
          backgroundColor: "white",
          overflow: "auto",
          padding: "1em",
          borderRadius: "20px",
        }}>
        <div
          className="d-flex flex-column"
          style={{
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "20em",
          }}>
          <b>#123456789</b>
          <span>Mr. Customer</span>
        </div>
        <div
          className="d-flex"
          style={{
            padding: "1em 1.5em",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}>
          <h5 style={{ color: "black" }}>Subject of the query</h5>
        </div>
        <div
          className="d-flex flex-column"
          style={{
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "20em",
          }}>
          <span>2021-03-08 01:36:13</span>
          <div
            style={{
              padding: "0.2em 0.5em",
              borderRadius: "20px",
              color: "white",
              backgroundColor: "black",
            }}>
            Pending
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "white",
          overflow: "auto",
          padding: "1em",
          borderRadius: "20px",
          height: "500px",
          gap: "0.5em",
        }}>
        <div style={{ wdith: "100%" }}>
          <CKEditor
            editor={ClassicEditor}
            data={editorData}
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "height",
                  "200px",
                  editor.editing.view.document.getRoot()
                );
              });
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEditorData(data);
              console.log({ event, editor, data });
            }}
            // onBlur={(event, editor) => {
            //   console.log("Blur.", editor);
            // }}
            // onFocus={(event, editor) => {
            //   console.log("Focus.", editor);
            // }}
            config={{
              toolbarLocation: "bottom",
            }}
          />
        </div>

        {/* File Input */}
        <div
          className="d-flex flex-column"
          style={{
            gap: "1em",
            padding: "1em",
          }}>
          <div className="row">
            <div
              style={{
                backgroundColor: "lightgray",
                padding: "0.5em 0.8em",
                width: "auto",
                cursor: "pointer",
              }}
              onClick={() => fileRef.current.click()}>
              Browse
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "0.5em 0.8em",
                backgroundColor: "#ebf0ec",
                flexGrow: 1,
              }}>
              <span
                style={{
                  maxWidth: "700px",
                  whiteSpace: "pre",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {selectedFile?.name ?? "Choose File"}
              </span>
              {selectedFile && (
                <i
                  className="fa fa-trash-alt"
                  style={{
                    color: "red",
                    cursor: "pointer",
                    marginLeft: "auto",
                  }}
                  onClick={() => setSelectedFile(null)}></i>
              )}
            </div>
            <input
              type="file"
              className="custom-file-input"
              onChange={(e) => {
                if (e.target?.files[0]) {
                  setSelectedFile(e.target?.files[0]);
                }
              }}
              // accept="image/*"
              lang="en"
              ref={fileRef}
              style={{ display: "none" }}
            />
          </div>

          {/* Multi select button */}
          <Fragment>
            <ButtonGroup
              ref={anchorRef}
              aria-label="split button"
              style={{
                marginLeft: "auto",
                transform: "translateX(1em)",
              }}>
              <Button
                onClick={handleClick}
                style={{
                  background: "black",
                  textTransform: "none",
                  fontWeight: "normal",
                  color: "white",
                }}>
                Submit as &nbsp; <b> {`${options[selectedIndex]}`}</b>
              </Button>
              <Button
                size="small"
                variant="contained"
                style={{ backgroundColor: "black", color: "white" }}
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}>
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {options.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }>
                            Submit as &nbsp; <b>{`${option}`}</b>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Fragment>
        </div>

        {/* Info */}
        <div
          className="d-flex flex-row"
          style={{
            gap: "1em",
          }}>
          {/* Avatar container */}
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYWDbQcdhuPaF9J8RoGNGChMXvFoSr399QJHjYWpnWg-JeloILq52-wv95wuZvBgGvSy4&usqp=CAU"
            alt="Avatar"
            className="rounded-circle"
            style={{
              width: "50px",
              height: "50px",
            }}
          />
          <div className="d-flex flex-column">
            <b>Mr. Customer</b>
            <span>{`${new Date()}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportInfoPage;
