import url from "../../config/axios";
import React, { useEffect, useState } from "react";
import moment from "moment";

export default function LicenseDetail() {
  const [license, setLicense] = useState([]);
  const [status, setStatus] = useState("");

  const queryParams = new URLSearchParams(window.location.search);
  let verificationId = queryParams.get("id");
  // const [outlet, setOutlet] = useState(null);

  function checkColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "color-red";
        break;
      case "Assign To Doctor":
        result = "color-blue";
        break;
      case "Cancelled":
        result = "color-brown";
        break;
      case "In progress":
        result = "color-purple";
        break;
      case "Completed":
        result = "color-green";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }

  const GetLicense = () => {
    url
      .get(`/verification/verificationId/?id=${verificationId}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          console.log(license);
          setLicense(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    GetLicense();
  });

  const updateStatus = async () => {
    url
      .put(`/verification/?id=${verificationId}`, { status: status })
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          alert("Successfully Updated");
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  // const getOutlet = () => {
  //     url.get(``)
  //         .then(async (res) => {
  //             console.log('res', res)
  //             if (res.data.Message === "Success") {
  //                 setOutlet(res.data.data);
  //             }
  //             else {
  //                 alert(res.data.data);
  //             }
  //         })
  //         .catch((e) => console.log(e));
  // }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12">
            <div className="card-new usr-m-b" style={{ padding: "20px" }}>
              <div className="card-history-user license-detail">
                {/* <h5>Order Details</h5> */}
                <div
                  className="d-flex justify-content-between"
                  style={{ color: "gray" }}
                >
                  <div>
                    <h6>Submission Date:</h6>
                    <h5>{moment(license.createdAt).format("MM/DD/YYYY")}</h5>
                  </div>

                  <div>
                    <h6>Payment Status:</h6>
                    <h5 className={checkColor(license.status)}>
                      {license.status}
                    </h5>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{ color: "gray" }}
                >
                  {/* <h6>{order.createdAt}</h6>
                  <h6>{order.status}</h6>
                  <h6>{order.outletID}</h6> */}
                </div>
                <div class="border-top my-3"></div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="group-comb">
                    <label>FirstName</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={license.firstName}
                        //   onChange={(e) => setTitle(e.target.value)}
                        className="form-control large-input"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <label>Last Name</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={license.lastName}
                        //   onChange={(e) => setTitle(e.target.value)}
                        className="form-control large-input"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <label>Date Of Birth</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={moment(license.patientBirthdate).format(
                          "MM/DD/YYYY"
                        )}
                        //   onChange={(e) => setTitle(e.target.value)}
                        className="form-control large-input"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <label>Phone Number</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={license.phoneNumber}
                        //   onChange={(e) => setTitle(e.target.value)}
                        className="form-control large-input"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <label>Gender</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={license.gender}
                        //   onChange={(e) => setTitle(e.target.value)}
                        className="form-control large-input"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <label>Email Address</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={license.email}
                        //   onChange={(e) => setTitle(e.target.value)}
                        className="form-control large-input"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <label>City</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={license.city}
                        //   onChange={(e) => setTitle(e.target.value)}
                        className="form-control large-input"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <label>State</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={license.state}
                        //   onChange={(e) => setTitle(e.target.value)}
                        className="form-control large-input"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="group-comb">
                    <label>Address</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={license.address}
                        //   onChange={(e) => setTitle(e.target.value)}
                        className="form-control large-input"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <label>Zip Code</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={license.zipCode}
                        //   onChange={(e) => setTitle(e.target.value)}
                        className="form-control large-input"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <label>Your Condition</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={license.medicalCondition}
                        //   onChange={(e) => setTitle(e.target.value)}
                        className="form-control large-input"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <h6>State ID Card / Driver's License</h6>
                </div>
                <div className="col-md-6">
                  <img alt="License" src={license.image} />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card-new usr-m-b">
              <div
                className="card-history-user mt-4"
                style={{ height: "110px" }}
              >
                <h5>Update Status</h5>
                <div className="" style={{ display: "flex" }}>
                  <select
                    defaultValue={license.status}
                    className="form-control"
                    style={{ width: "50%" }}
                    id="exampleFormControlSelect1"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Select Status
                    </option>
                    <option value="Completed">Completed</option>
                    <option value="Pending">Pending</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Assign To Doctor">Assing To Doctor</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                  <button
                    onClick={updateStatus}
                    className="btn btn-primary ml-2"
                    // onClick={() => updateStatus}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
