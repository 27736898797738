import {useState, useEffect} from 'react';
import url from "../../config/axios";

function EditModal({ closeModal, product, GetAllInventory }) {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [brand, setBrand] = useState("");
  const [stockQty, setStockQty] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [track, setTrack] = useState(false);

  useEffect(() => {
    // console.log(product, 'product D:');
    if(product){
      setStockQty(product.quantity);
      setDiscountPrice(product.discountPrice);
      setOriginalPrice(product.originalPrice);
      setName(product.product.name);
      setBrand(product.product.hasOwnProperty("brandName") ? product.product.brandName : "");
      setImage(product.product.image);
    }
  }, [product]);

  const updateInventory = () => {
    let body = {
      outletChainID: product.outletChainID,
      consumerId: product.consumerId,
      productID: product.productID,
      quantity: stockQty,
      originalPrice: originalPrice,
      discountPrice: discountPrice,
    };
    console.log(body, 'body')
    url
      .put(`/inventory/?productID=${product.productID}&outletChainID=${product.outletChainID}`, body)
      .then(async (res) => {
        // console.log(res, 'res')
        if (res.data.Message === "Failure") {
          alert(res.data.data);
        } else {
          GetAllInventory();
          alert("Inventory Updated");
          closeModal(false);
          
        }
      })
      .catch((e) => console.log(e));
  };

  const styles = {
    display: "block",
    // backgroundColor: "rgb(0,0,0)" /* Fallback color */,
    backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */,
    fontFamily: "Roboto",
  };

  const title = {
    fontSize: "22px",
    fontWeight: "500",
  };

  const text1 = {
    fontSize: "16px",
    fontWeight: "500",
    color: "#1F6355",
  };

  const text2 = {
    fontSize: "12px",
    fontWeight: "400",
    color: "#1F6355",
  };

  const mark = {
    color: "#898989",
    background: "none",
  };

  const productDetails = {
    backgroundColor: "#f9f9f9",
    borderRadius: "7px",
  };

  const title2 = {
    fontFamily: "Roboto",
    fontSize: "26px",
    fontWeight: "700",
    color: "#000000",
  };

  return (
    <div className="modal" style={styles}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header" style={title}>
            <span>Inventory Product Details</span>
            <i
              className="fas fa-times"
              onClick={() => {
                closeModal(false);
              }}
            />
          </div>
          <div className="modal-body">
            <div
              className="d-flex justify-content-start p-3"
              style={productDetails}
            >
              <img src={image} alt="" width="40px" height="40px" />
              <span className="mx-3">
                <p style={text1} className="m-0 p-0">
                  {name}
                </p>
                <p style={text2} className="m-0 p-0">
                  <mark style={mark}>Brand:</mark> {brand}
                </p>
              </span>
            </div>
            <p style={title2} className="my-3">
              Inventory Details
            </p>
            <div className="row">
              <div className="col-md-12">
                <span className="solo-chain">Track Inventory?</span>
              </div>
              <div className="col-md-6 switch-col">
                <label className="switch">
                  <input type="checkbox" checked={track} onChange={() => setTrack(!track)} />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="col-md-6">
                <div className="group-comb">
                  <div className="form-group border border-secondary">
                    <label>Quantity in Stock</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="3"
                      value={stockQty}
                      onChange={(e) => setStockQty(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <p style={title2} className="my-3">
              Billing Details
            </p>
            <div className="row">
              <div className="col-md-6">
                <div className="group-comb">
                  <div className="form-group border border-secondary">
                    <label>Discounted Price</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput"
                      placeholder="$ 20.00"
                      value={discountPrice}
                      onChange={(e) => setDiscountPrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="group-comb">
                  <div className="form-group border border-secondary">
                    <label>Original Price</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInpu1"
                      placeholder="$ 25.00"
                      value={originalPrice}
                      onChange={(e) => setOriginalPrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-light"
              onClick={() => {
                closeModal(false);
              }}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={updateInventory}>Publish Changes</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditModal;
