import { Popover } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { useState } from "react";
import { Fragment } from "react";
import LinkDropDownComponent from "../../../components/SocialLinksPage/LinksDropDown.component";
import CloseIcon from "@mui/icons-material/Close";
import { socialLinkIcons } from "../../../components/SocialLinksPage/options";
import { useEffect } from "react";
import url from "../../../config/axios";
import CircularProgress from "@mui/material/CircularProgress";

function SocialLinkPage({ invokeLogout }) {
  // {
  //   medium: "facebook",
  //   fontAwesomeRef: "fa-brands fa-facebook-f",
  //   themeColor: "#4267B2",
  //   dropdownColor: "white",
  //   starterLink: "https://www.facebook.com"
  // }
  const [addedLinks, setAddedLinks] = useState([
    {
      medium: "facebook",
      fontAwesomeRef: "fa-brands fa-facebook-f",
      themeColor: "#4267B2",
      dropdownColor: "white",
      starterLink: "https://www.facebook.com",
    },
    {
      medium: "twitch",
      fontAwesomeRef: "fa-brands fa-twitch",
      themeColor: "#6441a5",
      dropdownColor: "white",
      starterLink: "https://www.twitch.com",
    },
  ]);
  /**
   *
   * @param {index} index Mapped index of the links
   * @param {value} value Value parsed from HTML event
   */
  const handleLinkChange = (index, value) => {
    setAddedLinks([
      ...addedLinks.map((obj, i) => {
        if (i === index) {
          return {
            ...obj,
            starterLink: value,
          };
        }
        return obj;
      }),
    ]);
  };

  const handleRemove = (index) => {
    let toRemove = addedLinks[index]?.medium;
    let updatedErrorData = { ...linkErrors };
    delete updatedErrorData[toRemove];
    setLinkErrors({ ...updatedErrorData });
    setAddedLinks(addedLinks.filter((_, i) => i !== index));
  };

  /**
   *
   * @param {any} previousObject previous object
   * @param {any} toChange to be changed object
   */
  const handleChange = (previousObject, toChange) => {
    let toRemove = previousObject.medium;
    let updatedErrorData = { ...linkErrors };
    delete updatedErrorData[toRemove];
    setLinkErrors({ ...updatedErrorData });
    setAddedLinks(
      addedLinks.map((obj) => {
        if (obj?.medium === previousObject.medium) {
          return toChange;
        }
        return obj;
      })
    );
  };

  const addNewSlide = () => {
    let currentKeys = addedLinks.map((obj) => obj.medium);
    let filteredKeys = Object.keys(socialLinkIcons).filter(
      (el) => !currentKeys.includes(el)
    );
    if (filteredKeys.length !== 0 && addedLinks.length < 8) {
      setAddedLinks([
        ...addedLinks,
        {
          ...socialLinkIcons[filteredKeys[0]],
          medium: filteredKeys[0],
        },
      ]);
    }
  };
  const [linkErrors, setLinkErrors] = useState({});
  const validateLinks = () => {
    let data = { ...linkErrors };
    // console.log("Added: ", addedLinks);
    for (let i = 0; i < addedLinks.length; i++) {
      let key = addedLinks[i].medium;

      let el = socialLinkIcons[key] ?? {};
      if (!!el.validate) {
        let isValid = el.validate(addedLinks[i].starterLink);
        if (!isValid) {
          data = { ...data, [key]: `Enter a valid ${key} URL` };
        } else {
          delete data[key];
        }
      }
    }
    setLinkErrors({ ...data });
    // console.log("Data is", data);
    return data;
  };
  const [inProgress, setInProgress] = useState(true);
  const [triedBefore, setTriedBefore] = useState(false);
  const updateSocialLinks = () => {
    setTriedBefore(true);
    let errors = validateLinks();
    if (Object.keys(errors).length === 0) {
      setInProgress(true);
      const body = {
        links: addedLinks.map((obj) => ({ ...obj, link: obj.starterLink })),
      };
      url
        .patch(`/v1/general-settings/social-links`, body)
        .then((res) => {
          setInProgress(false);
        })
        .catch((e) => {
          if (+e?.response?.status === 401) {
            invokeLogout();
          }
          setInProgress(false);
        });
    }
  };
  const getAllSocialLinks = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    const outletChainID = adminID[0]?.outletChainID ?? null;
    url
      .get(`/v1/general-settings/social-links?outletChainID=${outletChainID}`)
      .then((response) => {
        const links = response?.data?.data?.links;
        if (Array.isArray(links)) {
          setAddedLinks(
            links.map((obj) => ({ ...obj, starterLink: obj.link }))
          );
        }
        setInProgress(false);
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  //fetch data
  useEffect(() => {
    getAllSocialLinks();
  }, []);
  useEffect(
    () => {
      if (triedBefore) {
        validateLinks();
      }
    },
    //eslint-disable-next-line
    [addedLinks, triedBefore]
  );
  return (
    <Fragment>
      {inProgress ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress />
        </div>
      ) : (
        <Fragment>
          <div className="row">
            {addedLinks.length === 0 && "No links added!"}
            <div className="col-md-6">
              <div
                className="col-md-12"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "3em",
                }}>
                {addedLinks.slice(0, 4).map((obj, i) => {
                  return (
                    <PopupState variant="popover" key={i}>
                      {(popupState) => (
                        <Fragment>
                          <div className="row col-md-12">
                            <div
                              className="col-md-3"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                padding: "1em",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.8em",
                                backgroundColor: obj.themeColor ?? "blue",
                                cursor: "pointer",
                              }}
                              {...bindTrigger(popupState)}>
                              <i
                                className={obj.fontAwesomeRef}
                                style={{
                                  fontStyle: "normal",
                                  color: "white",
                                  fontSize: "120%",
                                }}></i>
                              <i
                                class="fa-solid fa-caret-down"
                                style={{
                                  color: obj.dropdownColor ?? "white",
                                  fontStyle: "normal",
                                }}></i>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexGrow: 1,
                                padding: "1em",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                position: "relative",
                                boxShadow:
                                  "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                              }}>
                              <input
                                value={obj.starterLink}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  border: "none",
                                  outline: "none",
                                }}
                                type="text"
                                onChange={(e) =>
                                  handleLinkChange(i, e.target.value)
                                }
                                placeholder={`${obj.medium} link`}></input>
                              {linkErrors[obj.medium] && (
                                <span
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    transform: "translateY(70%)",
                                  }}>
                                  <br />

                                  {linkErrors[obj.medium]}
                                </span>
                              )}
                              <button
                                onClick={() => handleRemove(i)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  cursor: "pointer",
                                  backgroundColor: "#FF2259",
                                  transform: "translateY(-50%)",
                                  border: "none",
                                  oueline: "none",
                                  color: "white",
                                  height: "1em",
                                  width: "1em",
                                  borderRadius: "3px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <CloseIcon style={{ fontSize: "100%" }} />
                              </button>
                            </div>
                          </div>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}>
                            <LinkDropDownComponent
                              taken={addedLinks.map((obj) => obj.medium)}
                              handleChange={handleChange}
                              previousObject={obj}
                            />
                          </Popover>
                        </Fragment>
                      )}
                    </PopupState>
                  );
                })}
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="col-md-12"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "3em",
                }}>
                {addedLinks.slice(4).map((obj, i) => {
                  return (
                    <PopupState variant="popover" key={i}>
                      {(popupState) => (
                        <Fragment>
                          <div className="row col-md-12">
                            <div
                              className="col-md-3"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                padding: "1em",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.8em",
                                backgroundColor: obj.themeColor ?? "blue",
                                cursor: "pointer",
                              }}
                              {...bindTrigger(popupState)}>
                              <i
                                className={obj.fontAwesomeRef}
                                style={{
                                  fontStyle: "normal",
                                  color: "white",
                                  fontSize: "120%",
                                }}></i>
                              <i
                                class="fa-solid fa-caret-down"
                                style={{
                                  color: obj.dropdownColor ?? "white",
                                  fontStyle: "normal",
                                }}></i>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexGrow: 1,
                                padding: "1em",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                position: "relative",
                                boxShadow:
                                  "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                              }}>
                              <input
                                value={obj.starterLink}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  border: "none",
                                  outline: "none",
                                }}
                                type="text"
                                onChange={(e) =>
                                  handleLinkChange(4 + i, e.target.value)
                                }
                                placeholder={`${obj.medium} link`}></input>
                              {linkErrors[obj.medium] && (
                                <span
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    transform: "translateY(70%)",
                                  }}>
                                  <br />

                                  {linkErrors[obj.medium]}
                                </span>
                              )}
                              <button
                                onClick={() => handleRemove(4 + i)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  cursor: "pointer",
                                  backgroundColor: "#FF2259",
                                  transform: "translateY(-50%)",
                                  border: "none",
                                  oueline: "none",
                                  color: "white",
                                  height: "1em",
                                  width: "1em",
                                  borderRadius: "3px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <CloseIcon style={{ fontSize: "100%" }} />
                              </button>
                            </div>
                          </div>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}>
                            <LinkDropDownComponent
                              taken={addedLinks.map((obj) => obj.medium)}
                              handleChange={handleChange}
                              previousObject={obj}
                            />
                          </Popover>
                        </Fragment>
                      )}
                    </PopupState>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2em",
              marginTop: "3em",
            }}>
            <button
              style={{
                padding: "0.8em 3em",
                borderRadius: "5px",
                fontWeight: "bold",
                color: "white",
                backgroundColor: "#008080",
                border: "none",
                cursor: "pointer",
                outline: "none",
                opacity:
                  addedLinks.length === Object.keys(socialLinkIcons).length ||
                  addedLinks.length === 8
                    ? 0.3
                    : 1,
              }}
              onClick={addNewSlide}>
              Add New Social Item
            </button>
            <button
              onClick={updateSocialLinks}
              style={{
                padding: "0.8em 3em",
                borderRadius: "5px",
                fontWeight: "bold",
                color: "white",
                backgroundColor: "#294d94",
                border: "none",
                cursor: "pointer",
              }}>
              Update Settings
            </button>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default SocialLinkPage;
