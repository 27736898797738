/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./order.css";
import url from "../../config/axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

export default function Index() {
  const history = useHistory();
  const [outlets, setOutlets] = useState([]);
  const [orders, setOrders] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [minPro, setminPro] = useState(0);
  const [page, setPage] = useState(1);
  // const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    GetAllOutlets();
    // GetAllDeals();
  }, []);

  const incrementPage = () => {
    if (totalPage < 50) {
    } else {
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    // console.log(adminID[0].adminID);
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetOrdersByOutlet = (outletId) => {
    // const adminID = JSON.parse(localStorage.getItem("Admin"));
    if (outletId !== "select") {
      url
        .get(`/order/outlet/?outletID=${outletId}`)
        .then(async (res) => {
          console.log("Order Outlet:", res);
          if (res.data.Message === "Success") {
            console.log(res.data.data, "ORDERS DATA");
            setOrders(res.data.data);
            setTotalPage(res.data.data.length);
            setminPro(page * 50 - 50 + 1);
          } else {
            alert(res.data.data);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="group-comb">
                    <div className="input-group">
                      <select
                        className="form-control"
                        onChange={(e) => GetOrdersByOutlet(e.target.value)}
                        id="exampleFormControlSelect1"
                      >
                        <option value="select">Select Outlet</option>
                        {outlets.map((outlet, i) => {
                          return (
                            <option value={outlet.outletChainID} key={i}>
                              {outlet.outletName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  {/* <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      placeholder="Search Product"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text table-search-icon" id="btnGroupAddon">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  <span>
                    {minPro}-{orders.length + minPro - 1} of{" "}
                    {totalPage ? totalPage : 0}
                  </span>
                  <div
                    className="btn cannaby-light"
                    onClick={() => decrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-left"></i>
                  </div>
                  <div
                    className="btn cannaby-light"
                    onClick={() => incrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    {/* <th scope="col"></th> */}
                    <th scope="col">Customer</th>
                    <th scope="col">Prices</th>
                    <th scope="col">Purchased On</th>
                    {/* <th scope="col">Usage</th> */}
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, i) => {
                    //   console.log(coupon, 'coupon:');
                    return (
                      <tr key={i}>
                        <td colSpan="4">{order.orderID}</td>
                        <td>
                          {order.customer.name}
                          <br />
                          <span>{order.customer.email}</span>
                        </td>
                        <td>$ {order.finalPrice}</td>
                        <td>
                          {moment(order.createdAt).format(
                            "MMM. Do YYYY, h:mm A"
                          )}
                        </td>
                        {/* <td className="status">
                          <span>{deal.name}</span>
                        </td> */}
                        <td className="status">
                          <span>
                            {order.status ? "Published" : "Not Published"}{" "}
                          </span>
                        </td>
                        <td>
                          <button
                            style={{
                              backgroundColor: "white",
                              color: "#5D5FEF",
                              borderColor: " #5D5FEF",
                            }}
                            className="btn btn-primary btn-primary-hover"
                            onClick={() => {
                              localStorage.setItem(
                                "singleOrder",
                                JSON.stringify(order)
                              );
                              history.push(`/order-detail`);
                            }}
                          >
                            <i className="fas fa-eye"></i>View Details
                          </button>
                          {/* onClick={() => moveToEdit(product)} */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
