/* eslint-disable no-unused-vars */
import url from "../../config/axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./privacy.css";
import moment from "moment";

export default function Privacy() {
  const history = useHistory();
  const [privacyDate, setPrivacyDate] = useState("Not Created Yet");
  const [termsDate, setTermsDate] = useState("Not Created Yet");
  const [aboutDate, setAboutDate] = useState("Not Created Yet");

  // let page = ["terms", "privacy", "about"];

  const getPrivacyPage = () => {
    url
      .get(`/page/id?consumerId=${admin[0].consumerId}&page=privacy`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
          } else {
            setPrivacyDate(
              moment(res.data.data.updatedAt).format("MMMM Do YYYY h:mm A")
            );
          }
        }
      });
  };

  const getTermsPage = () => {
    url
      .get(`/page/id?consumerId=${admin[0].consumerId}&page=terms`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
          } else {
            setTermsDate(
              moment(res.data.data.updatedAt).format("MMMM Do YYYY h:mm A")
            );
          }
        }
      });
  };

  useEffect(() => {
    getPrivacyPage();
    getTermsPage();
  });

  const admin = JSON.parse(localStorage.getItem("Admin"));

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-pro">
            {/* <button
              className="btn btn-primary btn-pro mr-5"
              onClick={() => history.push("/add-coupon")}
            >
              <i className="fas fa-plus-circle"></i>
              Add Coupon
            </button> */}
            {/* onClick={() => {
    history.push("/addCatalog");
  }} */}

            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Last Activity</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={1}>
                    <td colSpan="4">Terms & Conditions </td>
                    <td></td>
                    <td>
                      {termsDate}
                      <br />
                    </td>
                    <td></td>
                    <td>
                      <i
                        onClick={() => history.push("add-page?id=terms")}
                        class="fa fa-pen"
                      ></i>
                    </td>
                  </tr>

                  <tr key={2}>
                    <td colSpan="4">Privacy Policy</td>
                    <td></td>
                    <td>
                      {privacyDate}
                      <br />
                    </td>
                    <td></td>
                    <td>
                      <i
                        onClick={() => history.push("add-page?id=privacy")}
                        class="fa fa-pen"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
