/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Popup from "reactjs-popup";
// import useMediaQuery from '@mui/material/useMediaQuery';
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import InputLabel from "@mui/material/InputLabel";
import { addDays, subDays } from "date-fns";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddCoupon() {
  const history = useHistory();
  const [activeStatus, setActiveStatus] = useState(false);
  const [applyToAll, setApplyToAll] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponType, setCouponType] = useState("Percentage");
  // const [memberType, setMemberType] = useState("All Users");
  const [discountValue, setDiscountValue] = useState("");
  const [outlets, setOutlets] = useState([]);
  const [selOutlet, setSelOutlet] = useState([]);
  const [selProduct, setSelProduct] = useState([]);
  const [selCategory, setSelCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [appliesTo, setAppliesTo] = useState("Entire Cart");
  const [neverExpires, setNeverExpires] = useState(false);
  const [specificDates, setSpecificDates] = useState(true);
  const [stateRules, setStateRules] = useState({
    MinOrderValue: "",
    TotalUsageLimit: "",
    LimitUsesPerCustomer: "",
  });

  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    if (message.text === "Success") {
      history.goBack();
    } else {
      ref.current.close();
    }
  };
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));

  const theme = useTheme();
  const [outName, setOutName] = useState([]);
  const [prodName, setProdName] = useState([]);
  const [catName, setCatName] = useState([]);

  const adminID = JSON.parse(localStorage.getItem("Admin"));

  useEffect(() => {
    GetAllOutlets();
    GetAllProducts();
    GetAllCategories();
  }, []);

  const handleNextOne = (e) => {
    const { name, value } = e.target;
    setStateRules((prevState) => ({
      ...prevState,
      [name]: value ? Math.abs(value) + "" : "",
    }));
  };

  const handleChange = async (newValue) => {
    setStartDate(newValue);
  };

  const handleChange1 = (newValue) => {
    setEndDate(newValue);
  };
  const [triedBefore, setTriedBefore] = useState(false);
  const [inProgress, setInProgres] = useState(false);
  const [dateRelatedErrors, setDateRelatedErrors] = useState({});
  useEffect(() => {
    // console.log("**********Incoming", { startDate, endDate });
    let data = { ...dateRelatedErrors };
    //manipualte start date
    if (new Date(startDate).toString() === "Invalid Date") {
      data = { ...data, start: "Invalid date" };
    } else if (new Date(startDate) > new Date(endDate)) {
      data = { ...data, start: "Happening after the date of being end" };
    } else if (new Date(startDate) < subDays(new Date(), 1)) {
      data = { ...data, start: "Must be a date today or in the future" };
    } else {
      const { start, ...others } = data;
      data = { ...others };
    }
    //manipulate end date
    if (new Date(endDate).toString() === "Invalid Date") {
      data = { ...data, end: "Invalid date" };
    } else if (new Date(startDate) > new Date(endDate)) {
      data = { ...data, end: "Happening before the date of being started" };
    } else {
      let { end, ...others } = data;
      data = { ...others };
    }
    // console.log("*************Manipulated date is: ", data);
    setDateRelatedErrors({ ...data });
  }, [startDate, endDate]);

  const [formValidationErrors, setFormValidationErrors] = useState({});
  useEffect(() => {
    setDiscountValue("");
  }, [couponType]);
  const validateForm = () => {
    let data = { ...formValidationErrors };
    //validate deal name: dealError
    if (couponCode.trim().length === 0) {
      data = { ...data, couponCodeError: "Code is required" };
    } else if (couponCode.trim().length < 4) {
      data = { ...data, couponCodeError: "Must of at leat 4 characters long" };
    } else {
      let { couponCodeError, ...others } = data;
      data = { ...others };
    }
    //validate discount value: discountError
    if (couponType === "Percentage") {
      if (discountValue.trim().length === 0) {
        data = { ...data, discountError: "Discount value is required" };
      } else if (discountValue <= 0 || discountValue >= 100) {
        data = {
          ...data,
          discountError: "Please a choose a postive value within 100",
        };
      } else {
        let { discountError, ...others } = data;
        data = { ...others };
      }
    } else if (couponType === "Amount") {
      if (discountValue.trim().length === 0) {
        data = { ...data, discountError: "Discount value is required" };
      } else if (discountValue <= 0) {
        data = {
          ...data,
          discountError: "Positive discount value is required",
        };
      } else {
        let { discountError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { discountError, ...others } = data;
      data = { ...others };
    }
    if (appliesTo === "Selected Products") {
      if (selProduct.length === 0) {
        data = { ...data, selectError: "Select at least one product" };
      } else {
        let { selectError, ...others } = data;
        data = { ...others };
      }
    } else if (appliesTo === "Selected Categories") {
      if (selCategory.length === 0) {
        data = { ...data, selectError: "Select at least one category" };
      } else {
        let { selectError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { selectError, ...others } = data;
      data = { ...others };
    }

    //manipulate rules
    if (!!stateRules?.MinOrderValue) {
      if (+stateRules?.MinOrderValue <= 0) {
        data = { ...data, minOrderValueError: "Must be a positive number" };
      } else {
        let { minOrderValueError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { minOrderValueError, ...others } = data;
      data = { ...others };
    }

    if (!!stateRules?.TotalUsageLimit) {
      if (+stateRules?.TotalUsageLimit <= 0) {
        data = { ...data, totalUsageError: "Must be a positive number" };
      } else {
        let { totalUsageError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { totalUsageError, ...others } = data;
      data = { ...others };
    }

    if (!!stateRules?.LimitUsesPerCustomer) {
      if (+stateRules?.LimitUsesPerCustomer > 0) {
        if (!!stateRules?.TotalUsageLimit) {
          let roundResult =
            +stateRules?.TotalUsageLimit / stateRules?.LimitUsesPerCustomer;
          if (roundResult !== parseInt(roundResult)) {
            data = {
              ...data,
              limitUsageError: "Must be multiple of total usage",
            };
          } else {
            let { limitUsageError, ...others } = data;
            data = { ...others };
          }
        } else {
          let { limitUsageError, ...others } = data;
          data = { ...others };
        }
      } else if (+stateRules?.LimitUsesPerCustomer <= 0) {
        data = { ...data, limitUsageError: "Must be a positive number" };
      } else {
        let { limitUsageError, ...others } = data;
        data = { ...others };
      }
    } else {
      let { limitUsageError, ...others } = data;
      data = { ...others };
    }

    setFormValidationErrors({ ...data });
    return data;
  };

  useEffect(() => {
    if (triedBefore) {
      validateForm();
    }
  }, [
    discountValue,
    couponType,
    couponCode,
    selProduct,
    selCategory,
    stateRules,
    triedBefore,
    appliesTo,
  ]);

  const GetAllOutlets = () => {
    // console.log(adminID, "admin");
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        console.log("All Outlets", res.data.data);
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          // alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };
  const GetAllProducts = () => {
    url
      .get(`/inventory/?outletChainID=${adminID[0].outletChainID}`)
      .then(async (res) => {
        console.log("Products", res.data.data);
        if (res.data.Message === "Success") {
          setProducts(res.data.data.slice(0, 100));
        } else {
          alert(res.data.data);
          setProducts([]);
        }
      });
  };

  const GetAllCategories = () => {
    url
      .get(`/category/`)
      .then(async (res) => {
        console.log("Categories", res.data.data);
        if (res.data.Message === "Success") {
          setCategories(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const selectedOutlets = async (outl) => {
    setOutName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? outl.split("split") : outl
    );
    //   console.log(outl, 'selected One:');
    let obj = [];
    for (let i = 0; i < outl.length; i++) {
      var splitted = outl[i].split("split", 3);
      obj.push({ outletChainID: splitted[0], name: splitted[1] });
      // await console.log(obj, "new array");
      await setSelOutlet(obj);
    }
    // console.log(effect, 'effects');
  };

  const selectedProducts = async (prod) => {
    setProdName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? prod.split("split") : prod
    );
    //   console.log(outl, 'selected One:');
    let obj = [];
    for (let i = 0; i < prod.length; i++) {
      var splitted = prod[i].split("split", 3);
      obj.push({ productID: splitted[0], name: splitted[1] });
    }
    await setSelProduct(obj);
  };

  const selectedCategories = async (cate) => {
    setCatName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? cate.split("split") : cate
    );
    //   console.log(outl, 'selected One:');
    let obj = [];
    for (let i = 0; i < cate.length; i++) {
      var splitted = cate[i].split("split", 3);
      obj.push({ categoryID: splitted[0], name: splitted[1] });
    }
    await setSelCategory(obj);
  };

  const setSelectedOne = (value) => {
    setAppliesTo(value);
  };

  const addCoupon = () => {
    setTriedBefore(true);
    let formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setInProgres(true);
      const admin = JSON.parse(localStorage.getItem("Admin"));
      const selectedOne = adminID[0].isSubOutlet
        ? [
            {
              outletChainID: adminID[0].outletChainID,
              name: adminID[0].outletName,
            },
          ]
        : selOutlet;
      const body = {
        consumerId: admin[0].consumerId,
        couponCode: couponCode,
        couponType: couponType,
        discountValue: Number.isNaN(+discountValue) ? "0" : discountValue,
        selectedOutlets: selectedOne,
        selectedProducts: appliesTo === "Selected Products" ? selProduct : [],
        selectedCategories:
          appliesTo === "Selected Categories" ? selCategory : [],
        applyToAllOutlets: applyToAll,
        entireCart: appliesTo === "Entire Cart" ? true : false,
        memberType: "All Users",
        startDate: startDate,
        endDate: endDate,
        neverExpires: neverExpires,
        rules: stateRules,
        status: activeStatus,
      };

      url.post(`/coupon/`, body).then(async (res) => {
        setInProgres(false);
        if (res.data.Message === "Success") {
          // alert("Coupon Added");
          setMessage({
            text: "Success",
            status: true,
            display: true,
          });
          openTooltip();
          // history.push('/discounts');
          // adminID[0].isSubOutlet
          //   ? history.push("/discounts-outlet")
          //   : history.push("/discounts");
        } else {
          setMessage({
            text: "Failed",
            status: true,
            display: true,
          });
          openTooltip();
        }
      });
    }
  };

  // const handleChangeRules = event => {
  //     setRules((prevState) => ({
  //         ...prevState,
  //         [event.target.id]: event.target.value
  //      }));
  // };
  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <div className="card">
            <div>
              {!adminID[0].isSubOutlet && (
                <div>
                  <h5>Applies to all outlets?</h5>
                  <br></br>
                </div>
              )}
              <form>
                <div className="row">
                  {!adminID[0].isSubOutlet && (
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3 switch-col">
                          <label className="switch  switch-demo">
                            <input
                              type="checkbox"
                              checked={applyToAll}
                              onChange={() => setApplyToAll(!applyToAll)}
                            />
                            <span className="slider round"></span>
                            <span style={{ paddingLeft: "81px" }}>
                              {applyToAll ? "Yes" : "No"}
                            </span>
                          </label>
                        </div>
                        <div className="col-md-9 group-comb">
                          <div className="form-group">
                            <label>Select Outlet (s)</label>
                            <FormControl sx={{ m: 1, width: 490 }}>
                              <InputLabel id="demo-multiple-chip-label">
                                Select Outlet(s)
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={outName}
                                onChange={(e) =>
                                  selectedOutlets(e.target.value)
                                }
                                input={
                                  <OutlinedInput
                                    id="select-multiple-chip"
                                    label="Select Outlet(s)"
                                  />
                                }
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}>
                                    {selected.map((value) => (
                                      <Chip
                                        key={value}
                                        label={`${value.split("split")[1]}`}
                                      />
                                    ))}
                                  </Box>
                                )}
                                IconComponent={() => <KeyboardArrowDownIcon />}
                                MenuProps={MenuProps}>
                                {outlets.map((outlet, i) => (
                                  <MenuItem
                                    key={i}
                                    value={
                                      outlet.outletChainID +
                                      "split" +
                                      outlet.outletName
                                    }
                                    style={getStyles(outlet, outName, theme)}>
                                    {outlet.outletName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {/* <input type="text" value={outletName} onChange={(e) => setOutletName(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="name" /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-md-12">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <h5>Coupon Details</h5>
                      <HighlightOffRoundedIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => history.goBack()}
                      />
                    </div>
                    <br></br>
                  </div>

                  <div className="col-md-12">
                    <div className="group-comb">
                      <div className="form-group">
                        <label>Coupon Code*</label>
                        <input
                          type="text"
                          value={couponCode}
                          onChange={(e) => setCouponCode(e.target.value)}
                          className="form-control"
                          // id="exampleFormControlInput1"
                          placeholder="Coupon code"
                        />
                        {formValidationErrors.couponCodeError && (
                          <span className="emsg">
                            {formValidationErrors.couponCodeError}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="group-comb">
                      <div className="form-group">
                        <label style={{ paddingLeft: "0.4em" }}>
                          Coupon Type
                        </label>

                        <select
                          onChange={(e) => setCouponType(e.target.value)}
                          className="form-control"
                          id="exampleFormControlSelect1">
                          <option value="Percentage">Percentage</option>
                          <option value="Amount">Amount</option>
                          <option value="Free Shiping">Free Shipping</option>
                        </select>

                        {/* <input type="text" value={couponType} onChange={(e) => setCouponType(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="discount value" /> */}
                      </div>
                    </div>
                  </div>
                  {couponType !== "Free Shiping" && (
                    <div className="col-md-12">
                      <div className="group-comb">
                        <div className=" form-group">
                          <label>
                            {couponType === "Percentage"
                              ? "Discount value %"
                              : "Discount value"}
                          </label>
                          <input
                            type="number"
                            value={discountValue}
                            onKeyPress={(e) => {
                              var charCode =
                                typeof e.which == "undefined"
                                  ? e.keyCode
                                  : e.which;
                              var charStr = String.fromCharCode(charCode);

                              if (!charStr.match(/^[0-9]+$/))
                                e.preventDefault();
                            }}
                            onChange={(e) => {
                              e.preventDefault();
                              if (e.target.value.match(/^[0-9]*$/)) {
                                if (couponType === "Percentage") {
                                  if (Math.abs(e.target.value) <= 99) {
                                    setDiscountValue(
                                      parseInt(Math.abs(e.target.value)) + ""
                                    );
                                  } else {
                                    setDiscountValue("");
                                  }
                                } else {
                                  setDiscountValue(
                                    Math.abs(e.target.value) + ""
                                  );
                                }
                              }
                            }}
                            className="form-control"
                            id="exampleFormControlInpu1"
                            placeholder={
                              couponType === "Percentage" ? "20 %" : "$ 1000"
                              // "Discount value"
                            }
                            style={
                              couponType !== "Percentage" &&
                              discountValue !== ""
                                ? { paddingLeft: "10px" }
                                : {}
                            }
                            min={0}
                            max={couponType === "Percentage" ? 99 : 999999999}
                          />
                          {discountValue !== "" ? (
                            couponType === "Percentage" ? (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "31px",
                                  left: `${
                                    Math.pow((discountValue + "").length, 3) +
                                    40
                                  }px`,
                                }}>
                                %
                              </span>
                            ) : (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "31px",
                                  left: "30px",
                                }}>
                                $
                              </span>
                            )
                          ) : (
                            ""
                          )}
                          {formValidationErrors.discountError && (
                            <span className="emsg">
                              {formValidationErrors.discountError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12">
                    <div class="alert alert-info" role="alert">
                      Coupon will be applicable for <b>Entire cart</b>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h5>Validity</h5>
                    <br />
                  </div>
                  <div className="col-md-12 validity-form">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        checked={neverExpires}
                        onChange={(e) => {
                          setNeverExpires(!neverExpires);
                          setSpecificDates(neverExpires);
                        }}
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label className="form-check-label" for="exampleCheck1">
                        Never Expires
                      </label>
                    </div>
                    <div className="form-check sds">
                      <input
                        type="checkbox"
                        checked={specificDates}
                        onChange={(e) => {
                          setSpecificDates(!specificDates);
                          setNeverExpires(specificDates);
                        }}
                        className="form-check-input"
                        id="exampleCheck2"
                      />
                      <label className="form-check-label" for="exampleCheck2">
                        Specific Dates
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 date-start-end">
                    {!neverExpires ? (
                      <div className="row data-time-parent">
                        <div className="col-md-6">
                          <div className="group-comb">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>Start Date</label>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                      onChangeRaw={(e) => e.preventDefault()}
                                      style={{ width: "100%" }}
                                      value={startDate}
                                      onChange={handleChange}
                                      minDate={new Date()}
                                      // maxDate={subDays(new Date(endDate), 1)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          value={startDate}
                                          onChange={(e) =>
                                            handleChange(
                                              new Date(e.target.value)
                                            )
                                          }
                                          error={!!dateRelatedErrors.start}
                                          helperText={dateRelatedErrors.start}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Renewal Date" /> */}
                                </div>

                                {/* <div className="col-md-2 icon-input" >
                                                        <i className="far fa-calendar"></i>
                                                        </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="group-comb">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>End Date</label>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                      style={{ width: "100%" }}
                                      value={endDate}
                                      disableMaskedInput={true}
                                      onChange={handleChange1}
                                      minDate={addDays(startDate, 1)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          value={endDate}
                                          onChange={(e) =>
                                            handleChange1(e.target.value)
                                          }
                                          error={!!dateRelatedErrors.end}
                                          helperText={dateRelatedErrors.end}
                                        />
                                      )}
                                      InputProps={{ readOnly: true }}
                                    />
                                  </LocalizationProvider>
                                  {/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Renewal Date" /> */}
                                </div>

                                {/* <div className="col-md-2 icon-input" >
                                                        <i className="far fa-calendar"></i>
                                                        </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="row hidden">
                      <div className="col-md-12">
                        <h5>Applies to</h5>
                        <br />
                      </div>
                      <div className="col-md-4 ">
                        <div className="group-comb ">
                          <div
                            className={`form-group ${
                              appliesTo === "Entire Cart" ? "" : "spec-hei"
                            }`}>
                            <label style={{ paddingLeft: "0.5em" }}>
                              Applies to
                            </label>

                            <select
                              onChange={(e) => setSelectedOne(e.target.value)}
                              className="form-control"
                              value={appliesTo}
                              id="exampleFormControlSelect1">
                              <option value="Selected Products">
                                Selected Products
                              </option>
                              <option value="Selected Categories">
                                Selected Categories
                              </option>
                              <option value="Entire Cart">Entire Cart</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        {appliesTo !== "Entire Cart" && (
                          <div className="group-comb">
                            <div className="form-group">
                              <label>
                                {appliesTo === "Selected Products"
                                  ? "Pick Products"
                                  : "Pick Categories"}
                              </label>
                              {appliesTo === "Selected Products" && (
                                <FormControl sx={{ m: 1, width: 650 }}>
                                  {/* {selProduct?.length === 0 && (
                                    <InputLabel
                                      id="demo-multiple-chip-label"
                                      style={{ transform: "translate(0em)" }}>
                                      Select Products
                                    </InputLabel>
                                  )} */}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}>
                                    {prodName.map((value, i) => {
                                      return (
                                        <Chip
                                          key={value}
                                          label={`${value.split("split")[1]}`}
                                          onDelete={() =>
                                            selectedProducts(
                                              prodName.filter(
                                                (el) =>
                                                  el.split("split")[0] !==
                                                  value.split("split")[0]
                                              )
                                            )
                                          }
                                        />
                                      );
                                    })}
                                  </Box>
                                  <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={prodName}
                                    onChange={(e) =>
                                      selectedProducts(e.target.value)
                                    }
                                    input={
                                      <OutlinedInput
                                        id="select-multiple-chip"
                                        label="Select Products"
                                      />
                                    }
                                    displayEmpty
                                    renderValue={(selected) =>
                                      selected?.length ? "" : "Choose Products"
                                    }
                                    // renderValue={(selected) => (
                                    //   <Box
                                    //     sx={{
                                    //       display: "flex",
                                    //       flexWrap: "wrap",
                                    //       gap: 0.5,
                                    //     }}>
                                    //     {selected.map((value) => (
                                    //       <Chip
                                    //         key={value}
                                    //         label={`${value.split("split")[1]}`}
                                    //       />
                                    //     ))}
                                    //   </Box>
                                    // )}
                                    IconComponent={() => (
                                      <KeyboardArrowDownIcon />
                                    )}
                                    MenuProps={MenuProps}>
                                    {products.map((element, i) => {
                                      const prod = element.product;
                                      return (
                                        <MenuItem
                                          key={i}
                                          value={
                                            prod?.productID +
                                            "split" +
                                            prod.name
                                          }
                                          style={getStyles(
                                            prod,
                                            outName,
                                            theme
                                          )}>
                                          {prod.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              )}

                              {appliesTo === "Selected Categories" && (
                                <FormControl sx={{ m: 1, width: 625 }}>
                                  {/* {selCategory.length === 0 && (
                                    <InputLabel
                                      id="demo-multiple-chip-label"
                                      style={{ transform: "translate(0em)" }}>
                                      Select Categories
                                    </InputLabel>
                                  )} */}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}>
                                    {catName.map((value, i) => {
                                      return (
                                        <Chip
                                          key={value}
                                          label={`${value.split("split")[1]}`}
                                          onDelete={() =>
                                            selectedCategories(
                                              catName.filter(
                                                (el) =>
                                                  el.split("split")[0] !==
                                                  value.split("split")[0]
                                              )
                                            )
                                          }
                                        />
                                      );
                                    })}
                                  </Box>
                                  <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={catName}
                                    onChange={(e) =>
                                      selectedCategories(e.target.value)
                                    }
                                    input={
                                      <OutlinedInput
                                        id="select-multiple-chip"
                                        label="Select Categories"
                                      />
                                    }
                                    displayEmpty
                                    renderValue={(selected) =>
                                      selected?.length
                                        ? ""
                                        : "Choose categories"
                                    }
                                    // renderValue={(selected) => (
                                    //   <Box
                                    //     sx={{
                                    //       display: "flex",
                                    //       flexWrap: "wrap",
                                    //       gap: 0.5,
                                    //     }}>
                                    //     {selected.map((value) => (
                                    //       <Chip
                                    //         key={value}
                                    //         label={`${value.split("split")[1]}`}
                                    //       />
                                    //     ))}
                                    //   </Box>
                                    // )}
                                    IconComponent={() => (
                                      <KeyboardArrowDownIcon />
                                    )}
                                    MenuProps={MenuProps}>
                                    {categories.map((cate, i) => (
                                      <MenuItem
                                        key={i}
                                        value={
                                          cate.categoryID + "split" + cate.name
                                        }
                                        style={getStyles(cate, outName, theme)}>
                                        {cate.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                              {/* <input type="text" value={outletName} onChange={(e) => setOutletName(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="name" /> */}
                            </div>
                            {formValidationErrors.selectError && (
                              <span
                                className="emsg"
                                style={{ paddingLeft: "2em" }}>
                                {formValidationErrors.selectError}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                      {/* <div className="col-md-4">
                        <div className="group-comb">
                          <div className=" form-group">
                            <label>Member Type</label>
                            <select
                              onChange={(e) => setMemberType(e.target.value)}
                              className="form-control"
                              id="exampleFormControlSelect1">
                              <option value="All Users">All Users</option>
                            </select>
                            <input type="text" value={couponType} onChange={(e) => setCouponType(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="discount value" />
                          </div>
                        </div>
                      </div> */}

                      <div className="col-md-12">
                        <h5>Rules</h5>
                        <br />
                      </div>

                      <div className="col-md-4">
                        <div className="group-comb">
                          <div className=" form-group">
                            <label>Minimum Order Value</label>

                            <input
                              value={stateRules.MinOrderValue}
                              type="number"
                              onChange={handleNextOne}
                              onKeyPress={(e) => {
                                var charCode =
                                  typeof e.which == "undefined"
                                    ? e.keyCode
                                    : e.which;
                                var charStr = String.fromCharCode(charCode);

                                if (!charStr.match(/^[0-9]+$/))
                                  e.preventDefault();
                              }}
                              name="MinOrderValue"
                              placeholder="Minimum order value"
                              className="form-control"
                            />
                            {formValidationErrors.minOrderValueError && (
                              <span className="emsg">
                                {formValidationErrors.minOrderValueError}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="group-comb">
                          <div className=" form-group">
                            <label>Total Usage Limit</label>

                            <input
                              value={stateRules.TotalUsageLimit}
                              type="number"
                              onKeyPress={(e) => {
                                var charCode =
                                  typeof e.which == "undefined"
                                    ? e.keyCode
                                    : e.which;
                                var charStr = String.fromCharCode(charCode);

                                if (!charStr.match(/^[0-9]+$/))
                                  e.preventDefault();
                              }}
                              onChange={handleNextOne}
                              name="TotalUsageLimit"
                              placeholder="Total usage limit"
                              className="form-control"
                            />
                            {formValidationErrors.totalUsageError && (
                              <span className="emsg">
                                {formValidationErrors.totalUsageError}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="group-comb">
                          <div className=" form-group">
                            <label>Limit Usage Per Customer</label>

                            <input
                              type="number"
                              onKeyPress={(e) => {
                                var charCode =
                                  typeof e.which == "undefined"
                                    ? e.keyCode
                                    : e.which;
                                var charStr = String.fromCharCode(charCode);

                                if (!charStr.match(/^[0-9]+$/))
                                  e.preventDefault();
                              }}
                              value={stateRules.LimitUsesPerCustomer}
                              onChange={handleNextOne}
                              name="LimitUsesPerCustomer"
                              placeholder="Limit usage per customer"
                              className="form-control"
                            />
                            {formValidationErrors.limitUsageError && (
                              <span className="emsg">
                                {formValidationErrors.limitUsageError}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <h5>Status</h5>
                      </div>

                      <div className="col-md-6 switch-col">
                        <label className="switch switch-demo">
                          <input
                            type="checkbox"
                            checked={activeStatus}
                            onChange={() => setActiveStatus(!activeStatus)}
                          />
                          <span className="slider round"></span>
                          <span
                            className="slider-text"
                            style={{ paddingLeft: "81px" }}>
                            {activeStatus ? "Enabled" : "Disabled"}
                          </span>
                        </label>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => addCoupon()}
                      className="btn btn-cannaby"
                      disabled={
                        (Object.keys(dateRelatedErrors).length !== 0 &&
                          !neverExpires) ||
                        inProgress
                      }>
                      {inProgress ? "Creating..." : "Create Coupon"}
                    </button>
                    <button
                      style={{ marginLeft: "20px", marginTop: "30px" }}
                      type="button"
                      onClick={() => history.push("/discounts-outlet")}
                      className="btn btn-rnav">
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        {message.status ? (
          <div style={{ textAlign: "center" }}>
            <i
              // style={{
              //   color: "green",
              //   fontSize: "60px",
              //   border: "10px solid green",
              //   borderRadius: "50px",
              //   padding: "10px",
              // }}
              class="fa fa-check"></i>
            <h2 className="popup-heading" style={{ marginTop: "20px" }}>
              Success
            </h2>
            <p>Coupon added successfully</p>
            <button
              className="btn btn-cannaby"
              style={{ marginTop: "0" }}
              onClick={() => {
                closeTooltip();
              }}>
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <i
              style={{
                color: "red",
                fontSize: "70px",
              }}
              className="fa fa-ban"></i>
            <h2 style={{ marginTop: "20px" }}>Failed</h2>
            <button
              style={{ marginTop: "10px" }}
              type="button"
              onClick={closeTooltip}
              className="btn btn-primary">
              Try Again
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}
