/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import url from "../../config/axios";
import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import "./appliedLicense.css";
import moment from "moment";

export default function AppliedLicense() {
  // const history = useHistory();
  const [License, setLicense] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const [minPro, setminPro] = useState("");
  const [page, setPage] = useState(1);
  const [outlets, setOutlets] = useState([]);
  // const [color, setColor] = useState();

  function checkColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "color-red";
        break;
      case "Assign To Doctor":
        result = "color-blue";
        break;
      case "Cancelled":
        result = "color-brown";
        break;
      case "In progress":
        result = "color-purple";
        break;
      case "Completed":
        result = "color-green";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }

  const admin = JSON.parse(localStorage.getItem("Admin"));

  const incrementPage = () => {
    if (totalPage < 50) {
    } else {
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (admin[0].isSubOutlet) {
      GetAllLicense();
    } else {
      GetAllOutlets();
    }
  }, []);

  const GetAllLicense = () => {
    // console.log(adminID[0].adminID);
    url
      .get(`/verification/id/?outletId=${admin[0].outletChainID}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          setLicense(res.data.data);
          setTotalPage(res.data.data.length);
          setminPro(page * 50 - 50 + 1);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  // const updateBanner = (verificationId) => {
  //   url
  //     .put(`/verification/?id=${verificationId}`)
  //     .then(async (res) => {
  //       // console.log('res', res)
  //       if (res.data.Message === "Success") {
  //         alert("Banner Deleted");
  //         GetAllLicense();
  //       } else {
  //         alert(res.data.data);
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  const GetAllOutlets = () => {
    url
      .get(`/oc/?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  // const GetLicenseByOutlet = (id) => {
  //   url
  //     .get(`/verification/?outletChainID=${id}`)
  //     .then(async (res) => {
  //       console.log("resi", res);
  //       if (res.data.Message === "Success") {
  //         if (res.data.data.length === 0) {
  //           alert("No banner found");
  //         } else {
  //           setLicense(res.data.data);
  //           setTotalPage(res.data.data.length);
  //           setminPro(page * 50 - 50 + 1);
  //         }
  //       } else {
  //         alert(res.data.data);
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  {!admin[0].isSubOutlet && (
                    <div className="group-comb">
                      <div className="input-group">
                        <select
                          className="form-control"
                          // onChange={(e) => GetLicenseByOutlet(e.target.value)}
                          id="exampleFormControlSelect1"
                        >
                          <option value="select">Select Outlet</option>
                          {outlets.map((outlet, i) => {
                            return (
                              <option value={outlet.outletChainID} key={i}>
                                {outlet.outletName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  )}

                  {/* {admin[0].isSubOutlet && (
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control table-search"
                        placeholder="Search Product"
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                      />
                      <div className="input-group-prepend">
                        <div
                          className="input-group-text table-search-icon"
                          id="btnGroupAddon"
                        >
                          <i className="fas fa-search"></i>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="col-md-3">
                  {/* <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      placeholder="Search Product"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text table-search-icon" id="btnGroupAddon">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  <span>
                    {minPro}-{License.length + minPro - 1} of{" "}
                    {totalPage ? totalPage : 0}
                  </span>
                  <div
                    className="btn cannaby-light"
                    onClick={() => decrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-left"></i>
                  </div>
                  <div
                    className="btn cannaby-light"
                    onClick={() => incrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Date of Birth</th>

                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">State ID Card / Drivers License</th>
                    <th scope="col">Submission Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {License.map((license, i) => {
                    //   console.log(coupon, 'coupon:');
                    return (
                      <tr key={i}>
                        <td>{license.firstName}</td>
                        <td>
                          {moment(license.patientBirthdate).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                        <td>{license.email}</td>
                        <td>{license.phoneNumber}</td>
                        <td>
                          <img
                            src={license.image}
                            style={{ width: "220px", height: "120px" }}
                            alt="banner"
                          />
                        </td>
                        <td>
                          {moment(license.createdAt).format("MM/DD/YYYY")}
                        </td>
                        <td>
                          <span className={checkColor(license.status)}>
                            {license.status}
                          </span>
                        </td>
                        <td>
                          <a
                            href={
                              "/applied-license-detail?id=" +
                              license.verificationId
                            }
                          >
                            {" "}
                            <i className="fa fa-eye color-blue"></i>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
