import "./Login.v1.css";
import "./Login.css"
import Logo from "../../../src/assets/brandImage.png";
import url from "../../config/axios";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { Fragment } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as crypt from "crypto-js";

const LoginPage = () => {
  const SECRET = "0b448dea-0ea2-4d12-821c-af1559b604a3";
  const history = useHistory();
  const [initialDataParsed, setInitialDatParsed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    loading: false,
    success: false,
    message: "",
  });
  const handleChange = (e) => {
    if (!formData.loading && !formData.success) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const [validationMessages, setvalidationMessages] = useState({});

  const [triedBefore, setTriedBefore] = useState(false);

  const validateForm = async () => {
    let data = {};
    const { email, password } = formData;
    //validate email
    if (email.trim().length === 0) {
      data = { email: "Email is required" };
      setvalidationMessages({ ...data });
      return data;
    } else if (
      !(email + "")
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      data = { email: "Email is not valid" };
      setvalidationMessages({ ...data });
      return data;
    }

    if (Object.keys(data).length === 0) {
      //validate password
      if (password.trim().length === 0) {
        data = { password: "Password is required" };
        setvalidationMessages({ ...data });
        return data;
      } else if (password.trim().length < 6) {
        data = {
          password: "Password must of at least 6 characters long",
        };
        setvalidationMessages({ ...data });
        return data;
      }
    }
    setvalidationMessages({ ...data });
    return data;
  };
  const parseInitialData = async () => {
    //check if previously logged in or not
    try {
      const adminID = await JSON.parse(localStorage.getItem("Admin"));
      if (adminID && Array.isArray(adminID) && adminID[0]?.adminID) {
        history.push("/dashboard");
      }
    } catch (e) {
      //do nothing
    }

    //handle remember me
    try {
      const tempRemeberMeState = await JSON.parse(
        localStorage.getItem("remeberMeState") ?? "{}"
      );
      // console.log(`Got cached : `, tempRemeberMeState);
      if (tempRemeberMeState) {
        let pass = "";
        if (tempRemeberMeState.password) {
          try {
            pass = crypt.AES.decrypt(
              tempRemeberMeState.password,
              SECRET
            ).toString(crypt.enc.Utf8);
          } catch (e) {
            console.error(e);
          }
        }
        setFormData({
          ...formData,
          email: tempRemeberMeState?.email ?? "",
          password: pass,
        });
        if (tempRemeberMeState?.email) {
          setRememberMeState(true);
        }
      } else {
        setRememberMeState(false);
      }
    } catch (e) {
      //do nothing
    }
    setInitialDatParsed(true);
  };
  useEffect(
    () => {
      //   console.log("Here...");
      if (!initialDataParsed) {
        parseInitialData();
      }
      if (triedBefore) {
        validateForm();
      }
    },
    //eslint-disable-next-line
    [triedBefore, formData, initialDataParsed]
  );

  const [rememberMeState, setRememberMeState] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginUser = async () => {
    setTriedBefore(true);
    let data = await validateForm();
    const { email, password } = formData;
    if (Object.keys(data).length === 0) {
      setFormData({
        ...formData,
        loading: true,
      });
      url
        .post(`/oca/login/`, {
          email: email,
          password: password,
          //   userID: "12345",
          // Token: token,
        })
        .then(async (res) => {
          //manipulate remember me
          try {
            if (rememberMeState) {
              await localStorage.setItem(
                "remeberMeState",
                JSON.stringify({
                  email: formData.email,
                  password: crypt.AES.encrypt(
                    formData.password,
                    SECRET
                  ).toString(),
                })
              );
            } else {
              await localStorage.removeItem("remeberMeState");
            }
          } catch (e) {
            console.error(e);
          }

          //   console.log(res.data.data);
          if (res.data.Message === "Success") {
            await localStorage.setItem("Admin", JSON.stringify(res.data.data));
            // if (res.data.data[0].isSubOutlet) {
            //   setTimeout(async () => {
            //     window.location.reload();
            //   }, 1000);
            // } else {
            //   //for reloading favicon
            //   window.location.reload();
            //   // await history.push("/dashboard");
            // }
            setFormData({
              ...formData,
              loading: false,
              success: true,
              message: "",
            });
            window.location.reload();
          } else {
            setFormData({
              ...formData,
              loading: false,
              success: false,
              message: "Password or email is not correct",
            });
          }
        })
        .catch((e) => {
          //   console.error(e);
          setFormData({
            ...formData,
            loading: false,
            success: false,
            message: "Unable to login",
          });
        });
    }
  };

  return (
    <div className="login-page-container">
      {initialDataParsed ? (
        <Fragment>
          <div className="login-page-form-container">
            {/* <img src={Logo} alt="bleaum-logo" height={150} width={150} /> */}
            <form
              className="login-page-form"
              onSubmit={(e) => {
                e.preventDefault();
                loginUser();
              }}>
              <div className="login-page-input-container">
                <h2 className="login-page-welcome">Welcome Back! Sign in...</h2>
                <label className="login-page-input-label">Email</label>
                <input
                  type={"email"}
                  name="email"
                  onChange={handleChange}
                  value={formData.email}
                  //   disabled={formData.loading}
                  //   required={true}
                  className="login-page-input"
                  placeholder="Enter email"
                />
                {validationMessages.email && (
                  <p className="login-page-input-error">
                    {validationMessages.email}
                  </p>
                )}
              </div>
              <div className="login-page-input-container">
                <label className="login-page-input-label">Password</label>
                <div className="login-page-input-password-container ">
                  <input
                    type={showPassword ? "text" : "password"}
                    // required={true}
                    name="password"
                    // disabled={formData.loading}
                    onChange={handleChange}
                    value={formData.password}
                    className="login-page-input-password"
                    placeholder="Enter password"
                  />
                  <div className="login-page-input-icon-holder">
                    <i
                      className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                      style={{
                        color: "black",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPassword(!showPassword)}></i>
                  </div>
                </div>
                {validationMessages.password && (
                  <p className="login-page-input-error">
                    {validationMessages.password}
                  </p>
                )}
              </div>
              <div className="login-page-input-container">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberMeState}
                      onChange={() => setRememberMeState(!rememberMeState)}
                      name="remember-me"
                      sx={{
                        color: "#ffff",
                        "&.Mui-checked": {
                          color: "#ffff",
                        },
                      }}
                    />
                  }
                  label="Remember me"
                />
              </div>
              {(formData.success || !!formData.message) && (
                <Box sx={{ width: "100%" }}>
                  <Alert
                    severity={formData.success ? "success" : "error"}
                    action={
                      <IconButton
                        aria-label="close"
                        color={"default"}
                        size="small"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            success: false,
                            message: "",
                          });
                        }}>
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}>
                    {formData?.success
                      ? "Successfully logged in"
                      : formData.message}
                  </Alert>
                </Box>
              )}
              <button
                type="submit"
                className="login-page-submit-button"
                disabled={formData.loading || formData.success}
                style={{
                  opacity: formData.loading || formData.success ? 0.5 : 1,
                }}>
                {formData.loading
                  ? "Logging in..."
                  : formData.success
                  ? "Redirecting..."
                  : "Login"}
              </button>
            </form>
          </div>
          <div className="login-page-logo-container">
            <img src={Logo} alt="bleaum-logo" height={300} width={300} />
          </div>
        </Fragment>
      ) : (
        <div className="d-flex justify-content-center w-100 p-5">
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};
export default LoginPage;
