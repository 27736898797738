import { Dialog } from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import url from "../../../config/axios";
import EditNotificationSettingsPopUp from "../../../components/NotificationSettingsPage/EditNotificationSettings";
import CircularProgress from "@mui/material/CircularProgress";
const initialCurrentData = {
  others: {
    displayName: "Others",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "",
      adminSite: "",
    },
    disabled: true,
  },
  register: {
    displayName: "Register",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Welcome to Bloom",
      adminSite: "Welcome to the system",
    },
  },
  orderConfirmation: {
    displayName: "Order Confirmation",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order has been confirmed",
      adminSite: "Order Confirmed",
    },
  },
  orderPending: {
    displayName: "Order Pending",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order is pending",
      adminSite: "Order Pending",
    },
  },
  orderProcessing: {
    displayName: "Order Processing",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order is being processed",
      adminSite: "Order Processing",
    },
  },
  orderReceived: {
    displayName: "Order Received",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order has been received",
      adminSite: "Order Received",
    },
  },
  newOrder: {
    displayName: "New Order",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Your order has been placed",
      adminSite: "Order placed",
    },
  },
  dealAdded: {
    displayName: "New Deal",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "A new deal placed",
      adminSite: "You placed a new deal",
    },
  },
  dealUpdated: {
    displayName: "Deal Updated",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Deal updated",
      adminSite: "Deal has been updated",
    },
  },
  couponAdded: {
    displayName: "New Coupon",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Coupon added",
      adminSite: "You added a coupon",
    },
  },
  couponUpdated: {
    displayName: "Coupon Updated",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Coupon updated",
      adminSite: "You updated a coupon",
    },
  },
  loyaltyPointsEarned: {
    displayName: "Added Loyalty points",
    mediums: {
      email: false,
      sms: false,
      system: false,
      mobile: false,
    },
    messages: {
      particularUser: "Loyalty points updated",
      adminSite: "You updated loyalty points",
    },
  },
};

function NotificationSettingsPage({ invokeLogout }) {
  // eslint-disable-next-line
  const [currentData, setCurrentData] = useState({ ...initialCurrentData });
  const [toEdit, setToEdit] = useState(null);

  const openEditPopup = (data) => {
    setToEdit(data);
  };
  /**
   * 
   * @param {any } modified modified object Ex: {
    displayName: "Register",
    type: "register",
    mediums: {
      email: true,
      system: false,
      mobile: true,
      sms: false,
    },
    messages: {
      particularUser: "Welcome to Bloom",
      adminSite: "Welcome to the system",
    },
  }
   */
  const closeEditPopup = (modified) => {
    setToEdit(null);
    let toSet = { ...currentData };
    let { type, ...others } = modified ?? {};
    if (!!type) {
      console.log("toSet is", modified);
      toSet[type] = {
        ...others,
      };
    }
    setCurrentData({ ...toSet });
  };
  const [inProgress, setInProgress] = useState(true);
  const getAllSocialLinks = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    const outletChainID = adminID[0]?.outletChainID ?? null;
    url
      .get(
        `/v1/general-settings/notification-settings?outletChainID=${outletChainID}`
      )
      .then((response) => {
        const preferences = response?.data?.data?.preferences ?? {};
        setCurrentData({ ...preferences });
        setInProgress(false);
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  //fetch data
  useEffect(() => {
    getAllSocialLinks();
  }, []);

  return (
    <div className="col-md-12">
      {inProgress ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress />
        </div>
      ) : (
        <Fragment>
          <table className="table table-striped-cust">
            <thead>
              <tr>
                <th scope="col">Event</th>
                <th scope="col"></th>

                <th scope="col">Type</th>
                <th scope="col"></th>

                <th scope="col">Notification message</th>
                <th scope="col"></th>

                <th scope="col">Action</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(currentData).map((obj, i) => {
                let data = currentData[obj];
                let notificationTypes = data?.mediums ?? {};
                let allowedNotificationMediums = Object.keys(notificationTypes)
                  .filter((el) => !!notificationTypes[el])
                  .map((obj) => obj[0].toUpperCase() + obj.substring(1));

                if (data?.disabled) {
                  return null;
                }
                return (
                  <tr key={i}>
                    <td colSpan={2}>{data.displayName}</td>
                    <td colSpan={2}>
                      {!!allowedNotificationMediums.length
                        ? allowedNotificationMediums.join(", ")
                        : "--"}
                    </td>
                    <td colSpan={2}>{data.messages?.adminSite}</td>
                    <td>
                      <i
                        className="fa fa-pen"
                        style={{ color: "green" }}
                        onClick={() =>
                          openEditPopup({ ...data, type: obj })
                        }></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!!toEdit && (
            <Dialog open={true} onClose={closeEditPopup} fullWidth={true}>
              <EditNotificationSettingsPopUp
                onClose={closeEditPopup}
                toEdit={toEdit}
                invokeLogout={invokeLogout}
              />
            </Dialog>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default NotificationSettingsPage;
