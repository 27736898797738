import React, { useState } from 'react';
import XLSX from 'xlsx';

export default function ExcelSample() {

    const [convertedJson, setConvertedJson] = useState([]);

    const fileUpload = (event) => {
        // console.log(event)
        let selected = event.target.files[0];
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selected);
        fileReader.onload = (event) => {
            console.log(event);
            let binaryData = event.target.result;
            let workbook = XLSX.read(binaryData, { type: 'binary' });
            console.log(workbook);
            workbook.SheetNames.forEach(sheet => {
                const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
                console.log(data);
                setConvertedJson(JSON.stringify(data))
            })
        }
    }

    return <div>
        <form>
            <label>
                <input
                    type="file"
                    id="excel"
                    name="excel"
                    onChange={fileUpload}
                    accept=".xls, .xlsx"
                />
                <i className="fas fa-pen"></i>
            </label>
        </form>
        <div>{convertedJson}</div>
    </div>
}
