import CloseIcon from "@mui/icons-material/Close";
import { Checkbox, CircularProgress, Dialog } from "@mui/material";
import { useState, useEffect } from "react";
import url from "../../config/axios";
const initialFormData = {
  title: "",
  viaEmail: false,
  viaSms: false,
  viaMobile: false,
  viaSystem: false,
  highlights: "",
};

function SendNotificationModal({
  onClose,
  refData = { ...initialFormData },
  invokeLogout,
  headerTitle,
  notificationId,
}) {
  const handleClose = (isUpdated = false, data = null) => {
    onClose(isUpdated, data);
  };
  const [formData, setFormData] = useState({ ...initialFormData });
  const [inProgress, setInProgress] = useState(false);

  useEffect(
    () => {
      setFormData({
        ...initialFormData,
        ...refData,
      });
    },
    //eslint-disable-next-line
    []
  );

  const handleChangeFromEvent = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  /**
   *
   * @param {string} propName property to change
   * @param { any } value corresponding value
   */
  const handleChange = (propName, value) => {
    setFormData({ ...formData, [propName]: value });
  };

  const [formValidationErrors, setFormValidationsErrors] = useState({});
  const [triedBefore, setTriedBefore] = useState(false);
  const validateForm = () => {
    let data = { ...formValidationErrors };

    //manipulate title
    if (formData.title?.trim().length === 0) {
      data = {
        ...data,
        titleError: "Title of the notification event is required",
      };
    } else {
      let { titleError, ...others } = data;
      data = { ...others };
    }

    //manipulate highlights
    if (formData.highlights?.trim().length === 0) {
      data = {
        ...data,
        hError: "Highlights of the notification event is required",
      };
    } else {
      let { hError, ...others } = data;
      data = { ...others };
    }

    setFormValidationsErrors({ ...data });
    return data;
  };

  useEffect(
    () => {
      if (triedBefore) {
        validateForm();
      }
    },
    //eslint-disable-next-line
    [formData, triedBefore]
  );

  const updateNotficationModule = () => {
    setTriedBefore(true);
    let errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setInProgress(true);
      const body = {
        ...formData,
      };
      console.log("Body is : ", body);
      url
        .put(
          `/v1/notifications/upsert-admin-notification${
            notificationId ? "?notificationId=" + notificationId : ""
          }`,
          body
        )
        .then((res) => {
          setInProgress(false);
          console.log("Res is: ", res?.data?.data?.data);
          handleClose(true, res?.data?.data?.data ?? {});
        })
        .catch((e) => {
          if (+e?.response?.status === 401) {
            invokeLogout();
          }
          console.error(e?.response);
          setInProgress(false);
        });
    }
  };

  return (
    <Dialog
      open={true}
      fullWidth={true}
      onClose={() => {
        // if (!inProgress) {
        //   handleClose();
        // }
      }}>
      <div
        style={{
          height: "80vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1em 1.5em",
            height: "auto",
            backgroundColor: "green",
            alignItems: "center",
          }}>
          <h3 style={{ color: "white" }}>
            {!notificationId ? "Send" : "Edit & Resend"}
          </h3>
          <CloseIcon
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => {
              if (!inProgress) {
                handleClose(false);
              }
            }}
          />
        </div>
        <div
          style={{
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "auto",
          }}>
          <div className="group-comb">
            <div className="form-group">
              <label style={{ fontWeight: "bold" }}>Title*</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChangeFromEvent}
                className="form-control"
                placeholder="Title"
              />
            </div>
            {formValidationErrors.titleError ? (
              <span className="validation-help">
                {formValidationErrors.titleError}
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="group-comb">
            <div className="form-group">
              <label style={{ fontWeight: "bold" }}>Highlights*</label>
              <textarea
                type="text"
                name="highlights"
                rows={4}
                style={{
                  padding: 0,
                  outline: "none",
                  borderStyle: "none",
                  overflow: "auto",
                  resize: "none",
                  boxShadow: "none",
                }}
                value={formData.highlights}
                onChange={handleChangeFromEvent}
                className="form-control removeOutline"
                placeholder="Something about the notification"
              />
            </div>
            {formValidationErrors.hError ? (
              <span className="validation-help">
                {formValidationErrors.hError}
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="group-comb">
            <div className="form-group">
              <label style={{ fontWeight: "bold" }}>NOTIFICATION MEDIUM*</label>

              <div
                className="row"
                style={{ alignItems: "center", marginTop: "0.5em" }}>
                <Checkbox
                  checked={formData.viaMobile}
                  value={formData.viaMobile}
                  onChange={() =>
                    handleChange("viaMobile", !formData.viaMobile)
                  }
                />
                <span>Mobile</span>
              </div>

              <div
                className="row"
                style={{ alignItems: "center", marginTop: "0.5em" }}>
                <Checkbox
                  checked={formData.viaSystem}
                  value={formData.viaSystem}
                  onChange={() =>
                    handleChange("viaSystem", !formData.viaSystem)
                  }
                />
                <span>System</span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "1em 1.5em",
            height: "auto",
            alignItems: "center",
          }}>
          <button
            style={{
              backgroundColor: "#294d94",
              padding: "1em 4em",
              cursor: "pointer",
              border: "none",
              fontWeight: "bold",
              borderRadius: "10px",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1em",
            }}
            onClick={updateNotficationModule}>
            {inProgress && (
              <CircularProgress style={{ color: "white" }} size={20} />
            )}
            {inProgress
              ? "In progress..."
              : `${!notificationId ? "Send" : "Update and Resend"}`}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default SendNotificationModal;
