import { useState } from "react";

const dummyData = {
  title:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit Accusantium quos rem totam repellendus animi odio reiciendis dolor illo dicta. Aearum deleniti quae corporis tempora quis. Cupiditate, in. Voluptatem,eveniet.",
};
const ConversationInfoPage = () => {
  const [reply, setReply] = useState("");
  return (
    <div className="d-flex flex-column" style={{ gap: "2em" }}>
      <div
        className="container-fluid"
        style={{
          backgroundColor: "white",
          overflow: "auto",
          padding: "1em",
          borderRadius: "20px",
        }}>
        <h5
          style={{
            color: "black",
            fontWeight: "bolder",
          }}>
          # {dummyData?.title}
        </h5>
      </div>

      <div
        className="container-fluid d-flex flex-column"
        style={{
          backgroundColor: "white",
          overflow: "auto",
          padding: "1em 3em",
          borderRadius: "20px",
          height: "auto",
          gap: "0.5em",
        }}>
        {/* Info */}
        <div
          className="d-flex flex-row"
          style={{
            gap: "1em",
          }}>
          {/* Avatar container */}
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYWDbQcdhuPaF9J8RoGNGChMXvFoSr399QJHjYWpnWg-JeloILq52-wv95wuZvBgGvSy4&usqp=CAU"
            alt="Avatar"
            className="rounded-circle"
            style={{
              width: "50px",
              height: "50px",
            }}
          />
          <div className="d-flex flex-column">
            <b>Mr. Customer</b>
            <span>{`${new Date()}`}</span>
          </div>
        </div>
        {/* Messages */}
        Hello
        {/* Text Box */}
        <div className="d-flex flex-column" style={{ gap: "1em" }}>
          <textarea
            placeholder="Type your reply"
            rows={8}
            value={reply}
            style={{
              padding: "0.5em",
              outlineColor: "gray",
              borderRadius: "5px",
              marginTop: "2em",
            }}
            onChange={(e) => setReply(e.target.value)}></textarea>
          <div class="d-flex">
            <button
              type="button"
              class="btn"
              style={{
                marginLeft: "auto",
                color: "white",
                backgroundColor: "rgb(37,188,241)",
              }}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationInfoPage;
