import "./AddInventory.css";
import React from 'react'

export default function ViewProduct({ setViewModal, selectedProduct }) {
    console.log(selectedProduct);
    const styles = {
        display: "block",
        // backgroundColor: "rgb(0,0,0)" /* Fallback color */,
        backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */,
        fontFamily: "Roboto",
    };

    return (
        <div className="modal" style={styles}>
            <div className="mdl modal-dialog-centered" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Product Details</h5>
                        <button className="close" onClick={() => setViewModal(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-3">
                                <div >
                                    <img style={{ heigth: '50%', width: '80%' }} alt="" src={selectedProduct.image} />
                                </div>
                                <div className="d-flex">
                                    <img style={{ heigth: '150px', width: '40%' }} alt="" src={selectedProduct.imageGallery[0]} />
                                    <img style={{ heigth: '50%', width: '40%' }} alt="" src={selectedProduct.imageGallery[1]} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h3>{selectedProduct.name}</h3>
                                        <div>
                                            <p>Brand: <span>{selectedProduct.brandName}</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary">Add to Inventory</button>
                                    </div>
                                </div>

                                <div>
                                    <p>
                                        <button className="btn btn-primary my-btn d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#basicDetails" aria-expanded="false" aria-controls="collapseExample">
                                            Basic Details 
                                            <i class="fa fa-arrow-down"></i>
                                        </button>
                                    </p>
                                    <div className="collapse" id="basicDetails">
                                        <div className="card card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                        </div>
                                    </div>

                                    <p>
                                        <button className="btn btn-primary my-btn d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#shortDescription" aria-expanded="false" aria-controls="collapseExample">
                                            Short Description
                                            <i class="fa fa-arrow-down"></i>
                                        </button>
                                    </p>
                                    <div className="collapse" id="shortDescription">
                                        <div className="card card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                        </div>
                                    </div>

                                    <p>
                                        <button className="btn btn-primary my-btn d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#longDescription" aria-expanded="false" aria-controls="collapseExample">
                                            Long Description
                                            <i class="fa fa-arrow-down"></i>
                                        </button>
                                    </p>
                                    <div className="collapse" id="longDescription">
                                        <div className="card card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                        </div>
                                    </div>

                                    <p>
                                        <button className="btn btn-primary my-btn d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#pack" aria-expanded="false" aria-controls="collapseExample">
                                            Packaging Details
                                            <i class="fa fa-arrow-down"></i>
                                        </button>
                                    </p>
                                    <div className="collapse" id="pack">
                                        <div className="card card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                        </div>
                                    </div>

                                    <p>
                                        <button className="btn btn-primary my-btn d-flex justify-content-between" type="button" data-toggle="collapse" data-target="#effects" aria-expanded="false" aria-controls="collapseExample">
                                            Effects
                                            <i class="fa fa-arrow-down"></i>
                                        </button>
                                    </p>
                                    <div className="collapse" id="effects">
                                        <div className="card card-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
