import React, { useState, useEffect, useRef } from "react";
import url from "../../../config/axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Popup from "reactjs-popup";
export default function Index() {
  const [orders, setOrders] = useState([]);
  const history = useHistory();
  const [totalPage, setTotalPage] = useState("");
  // const [minPro, setminPro] = useState("");
  // const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  var perPage = 30;
  const admin = JSON.parse(localStorage.getItem("Admin"));

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    ref.current.close();
    setSearchTerm("");
  };

  const incrementPage = () => {
    // if (totalPage < 5) {
    // } else {
    //   setPage(page + 1);
    // }
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const decrementPage = () => {
    // if (page > 1) {
    //   setPage(page - 1);
    // }
    if (currentPage >= 2) {
      setCurrentPage(currentPage - 1);
    }
  };

  //search
  useEffect(
    () => {
      if (searchTerm.trim().length !== 0) {
        let filtered = unfiltered
          .filter((el) => {
            return (
              (el.customer?.name + "")
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              el.orderID.includes(searchTerm)
            );
          })
          .sort((obj1, obj2) =>
            new Date(obj1?.createdAt) > new Date(obj2?.createdAt) ? -1 : 1
          );
        setOrders(filtered);
        setTotalPage(Math.ceil(filtered.length / perPage));
        if (filtered.length === 0) {
          openTooltip();
        }
        setCurrentPage(1);
      } else {
        setOrders(unfiltered);
        setTotalPage(Math.ceil(unfiltered.length / perPage));
        setCurrentPage(1);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );

  useEffect(() => {
    console.log(admin);
    getAllOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllOrders = () => {
    url
      .get(`order/outlet/?outletID=${admin[0].outletChainID}`)
      .then(async (res) => {
        console.log(res.data, "res:");
        if (res.data.Message === "Success") {
          //excklude orders without name of the customer
          if (res.data.data.length === 0) {
            openTooltip();
          }
          const filtered = res.data.data
            .filter((order) => !!order?.customer?.name)
            .sort((obj1, obj2) =>
              new Date(obj1?.createdAt) > new Date(obj2?.createdAt) ? -1 : 1
            );
          setOrders(filtered);
          setUnfiltered(filtered);
          setTotalPage(Math.ceil(filtered.length / perPage));
          // setminPro(page * 50 - 50 + 1);
        }
      });
  };

  // const SearchByOrderID = (value) => {
  //   if (!value) {
  //     getAllOrders();
  //   } else {
  //     url
  //       .get(`/order/search/?outletID=${admin[0].outletChainID}&name=${value}`)
  //       .then(async (res) => {
  //         // console.log(res, 'response:')
  //         if (res.data.Message === "Success") {
  //           setOrders(res.data.data);
  //           setTotalPage(Math.ceil(res.data?.data.length / perPage));
  //           setCurrentPage(1);
  //         } else {
  //           openTooltip();
  //         }
  //       });
  //   }
  // };

  function checkColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "color-red";
        break;
      case "New":
        result = "color-blue";
        break;
      case "Cancelled":
        result = "color-brown";
        break;
      case "Delivered":
        result = "color-purple";
        break;
      case "Dispatched":
        result = "color-green";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Search order"
                      value={searchTerm}
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      <i className="fas fa-filter"></i>
                    </a> */}

                    {/* <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div> */}
                  </div>
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                {!!orders.length && (
                  <div className="col-md-5 pagination">
                    <span>
                      {`${perPage * (currentPage - 1) + 1} - ${
                        perPage * (currentPage - 1) +
                        orders.slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        ).length
                      } of ${orders?.length ?? 0}`}
                    </span>
                    <div
                      className="btn cannaby-light"
                      onClick={() => decrementPage()}
                      role="button"
                    >
                      <i className="fas fa-chevron-left"></i>
                    </div>
                    <div
                      className="btn cannaby-light"
                      onClick={() => incrementPage()}
                      role="button"
                    >
                      <i className="fas fa-chevron-right"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col" style={{ color: "black" }}>
                      Order ID
                    </th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col" style={{ color: "black" }}>
                      Customer
                    </th>
                    <th scope="col" style={{ color: "black" }}>
                      Price
                    </th>
                    <th scope="col" style={{ color: "black" }}>
                      Purchased On
                    </th>
                    <th scope="col" style={{ color: "black" }}>
                      Status
                    </th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {orders
                    .slice((currentPage - 1) * perPage, currentPage * perPage)
                    .map((inv, i) => {
                      return (
                        <tr key={i}>
                          <td colSpan="6">{inv.orderID}</td>
                          <td className="adminName">
                            {inv.customer ? inv.customer.name : ""} <br />
                            <span style={{ fontSize: "80%" }}>
                              {inv.customer?.email}
                            </span>
                            <span></span>
                          </td>
                          <td>$ {inv.totalPrice}</td>
                          <td>
                            {moment(new Date(inv.createdAt)).format(
                              "MMM Do YYYY, h:mm A"
                            )}
                          </td>
                          <td className={checkColor(inv.status)}>
                            {inv.status}
                          </td>
                          <td>
                            <button
                              style={{
                                backgroundColor: "white",
                                color: "#5D5FEF",
                                borderColor: " #5D5FEF",
                              }}
                              className="btn btn-outline-primary btn-primary-hover"
                              onClick={() => {
                                console.log(inv, "order selected");
                                localStorage.setItem(
                                  "singleOrder",
                                  JSON.stringify(inv)
                                );
                                window.oID = inv?.orderID;
                                history.push(`/order-detail`);
                              }}
                            >
                              <i className="fas fa-eye"></i>View Details
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center" onClose={closeTooltip}>
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Order does not exist</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={() => {
              closeTooltip();
            }}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
