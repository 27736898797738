/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import url from "../../config/axios";
import { useEffect, useState } from "react";
import moment from "moment";
// import { useHistory } from "react-router-dom";

export default function License() {
  // const history = useHistory();
  const [license, setLicense] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const [minPro, setminPro] = useState("");
  const [page, setPage] = useState(1);
  const [outlets, setOutlets] = useState([]);
  // const [status, setStatus] = useState("");

  const admin = JSON.parse(localStorage.getItem("Admin"));

  const incrementPage = () => {
    if (totalPage < 50) {
    } else {
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (admin[0].isSubOutlet) {
      GetAllLicences();
    } else {
      GetAllOutlets();
    }
  }, []);

  const GetAllLicences = () => {
    // console.log(adminID[0].adminID);
    url
      .get(`/cannabisid/outletId?id=${admin[0].outletChainID}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          console.log(res.data.data, "success");
          setLicense(res.data.data);
          setTotalPage(res.data.data.length);
          setminPro(page * 50 - 50 + 1);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const updateLicense = (licenseId, status) => {
    let value;
    if (status === "Approved") {
      value = true;
    } else {
      value = false;
    }

    url
      .put(`/cannabisid/?cannabisId=${licenseId}`, { status: value })
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          alert("License Status Updated");
          GetAllLicences();
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetAllOutlets = () => {
    url
      .get(`/oc/?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const checkStatus = (value) => {
    console.log(value);
    let result;
    if (value === true) {
      result = "Approved";
    } else {
      result = "Expired";
    }

    return result;
  };

  // const GetCannabisIdByOutlet = (id) => {
  //   url
  //     .get(`/cannabisid/?outletChainID=${id}`)
  //     .then(async (res) => {
  //       console.log("resi", res);
  //       if (res.data.Message === "Success") {
  //         if (res.data.data.length === 0) {
  //           alert("No banner found");
  //         } else {
  //           setBanners(res.data.data);
  //           setTotalPage(res.data.data.length);
  //           setminPro(page * 50 - 50 + 1);
  //         }
  //       } else {
  //         alert(res.data.data);
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  {!admin[0].isSubOutlet && (
                    <div className="group-comb">
                      <div className="input-group">
                        <select
                          className="form-control"
                          // onChange={(e) => GetBannersByOutlet(e.target.value)}
                          id="exampleFormControlSelect1"
                        >
                          <option value="select">Select Outlet</option>
                          {outlets.map((outlet, i) => {
                            return (
                              <option value={outlet.outletChainID} key={i}>
                                {outlet.outletName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  )}

                  {/* {admin[0].isSubOutlet && (
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control table-search"
                        placeholder="Search Product"
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                      />
                      <div className="input-group-prepend">
                        <div
                          className="input-group-text table-search-icon"
                          id="btnGroupAddon"
                        >
                          <i className="fas fa-search"></i>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="col-md-3">
                  {/* <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      placeholder="Search Product"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text table-search-icon" id="btnGroupAddon">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  <span>
                    {minPro}-{License.length + minPro - 1} of{" "}
                    {totalPage ? totalPage : 0}
                  </span>
                  <div
                    className="btn cannaby-light"
                    onClick={() => decrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-left"></i>
                  </div>
                  <div
                    className="btn cannaby-light"
                    onClick={() => incrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th colSpan={3} scope="col">
                      Photo ID
                    </th>
                    <th scope="col">Name</th>

                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Medical Recommendation</th>
                    <th scope="col">Date of Birth</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {license.map((license, i) => {
                    //   console.log(coupon, 'coupon:');
                    return (
                      <tr key={i}>
                        <td colSpan={3}>
                          <img
                            src={
                              license.userData[0].image
                                ? license.userData[0].image
                                : ""
                            }
                            alt="banner"
                          />
                        </td>
                        <td>
                          {license.userData[0].name
                            ? license.userData[0].name
                            : ""}
                        </td>
                        <td>
                          {license.userData[0].email
                            ? license.userData[0].email
                            : ""}
                        </td>
                        <td>
                          {license.userData[0].mobileNo
                            ? license.userData[0].mobileNo
                            : ""}
                        </td>
                        <td>{license.medicalRecommendation}</td>
                        <td>
                          {moment(license.patientBirthdate).format(
                            "MMMM Do YYYY h:mm A"
                          )}
                        </td>

                        <td>
                          {/* <button
                            type="button"
                            onClick={() => updateLicense(banner.cannabisId)}
                            className="btn"
                          ></button> */}
                          <div className="" style={{ display: "flex" }}>
                            <select
                              defaultValue={checkStatus(license.status)}
                              className="form-control"
                              style={{ width: "100%" }}
                              id="exampleFormControlSelect1"
                              name="status"
                              onChange={(e) =>
                                updateLicense(
                                  license.cannabisId,
                                  e.target.value
                                )
                              }
                            >
                              <option value="Approved">Approved</option>
                              <option value="Expired">Expired</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
