import CloseIcon from "@mui/icons-material/Close";
import { Checkbox, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import url from "../../config/axios";
/**
 *
 * @param {() => void} onClose close trigger function
 * @param {any} toEdit object to edit  {
    displayName: "Register",
    type: "register",
    mediums: {
      email: true,
      system: false,
      mobile: true,
      sms: false,
    },
    messages: {
      particularUser: "Welcome to Bloom",
      adminSite: "Welcome to the system",
    },
  }
 */
const initialFormData = {
  displayName: "",
  viaEmail: true,
  viaSms: true,
  viaMobile: true,
  viaSystem: true,
  particularUserMessage: "",
  adminSiteMessage: "",
};

function EditNotificationSettingsPopUp({ onClose, toEdit = {}, invokeLogout }) {
  const handleClose = (isUpdated = false) => {
    onClose({
      type: toEdit.type,
      mediums: {
        email: isUpdated ? formData.viaEmail : toEdit.mediums?.email,
        sms: isUpdated ? formData.viaSms : toEdit.mediums?.sms,
        mobile: isUpdated ? formData.viaMobile : toEdit.mediums?.mobile,
        system: isUpdated ? formData.viaSystem : toEdit.mediums?.system,
      },
      displayName: isUpdated ? formData.displayName : toEdit.displayName,
      messages: {
        particularUser: isUpdated
          ? formData.particularUserMessage
          : toEdit.messages?.particularUser,
        adminSite: isUpdated
          ? formData.adminSiteMessage
          : toEdit.messages?.adminSite,
      },
    });
  };
  const [formData, setFormData] = useState({ ...initialFormData });
  const [inProgress, setInProgress] = useState(false);

  useEffect(
    () => {
      setFormData({
        displayName: toEdit.displayName,
        viaEmail: !!toEdit.mediums?.email,
        viaSms: !!toEdit.mediums?.sms,
        viaMobile: !!toEdit.mediums?.mobile,
        viaSystem: !!toEdit.mediums?.system,
        particularUserMessage: toEdit.messages?.particularUser ?? "",
        adminSiteMessage: toEdit.messages?.adminSite ?? "",
      });
    },
    //eslint-disable-next-line
    []
  );

  const handleChangeFromEvent = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  /**
   *
   * @param {string} propName property to change
   * @param { any } value corresponding value
   */
  const handleChange = (propName, value) => {
    setFormData({ ...formData, [propName]: value });
  };

  const [formValidationErrors, setFormValidationsErrors] = useState({});
  const [triedBefore, setTriedBefore] = useState(false);
  const validateForm = () => {
    let data = { ...formValidationErrors };

    //manipulate display name
    if (formData.displayName?.trim().length === 0) {
      data = { ...data, dnError: "Name of the notification event is required" };
    } else {
      let { dnError, ...others } = data;
      data = { ...others };
    }

    //manipulate message for particular user
    if (formData.particularUserMessage?.trim().length === 0) {
      data = { ...data, pmError: "Message for particular user is required" };
    } else {
      let { pmError, ...others } = data;
      data = { ...others };
    }

    //manipulate admin site message
    if (formData.adminSiteMessage?.trim().length === 0) {
      data = { ...data, amError: "Message for admin site is required" };
    } else {
      let { amError, ...others } = data;
      data = { ...others };
    }

    setFormValidationsErrors({ ...data });
    return data;
  };

  useEffect(
    () => {
      if (triedBefore) {
        validateForm();
      }
    },
    //eslint-disable-next-line
    [formData, triedBefore]
  );

  const updateNotficationModule = () => {
    setTriedBefore(true);
    let errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setInProgress(true);
      const body = {
        preferences: {
          [toEdit.type]: {
            mediums: {
              email: formData.viaEmail,
              sms: formData.viaSms,
              mobile: formData.viaMobile,
              system: formData.viaSystem,
            },
            displayName: formData.displayName,
            messages: {
              particularUser: formData.particularUserMessage,
              adminSite: formData.adminSiteMessage,
            },
          },
        },
      };
      console.log("Body is : ", body);
      url
        .patch(`/v1/general-settings/notification-settings`, body)
        .then(() => {
          setInProgress(false);
          handleClose(true);
        })
        .catch((e) => {
          if (+e?.response?.status === 401) {
            invokeLogout();
          }
          setInProgress(false);
        });
    }
  };

  return (
    <div
      style={{
        height: "80vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1em 1.5em",
          height: "auto",
          backgroundColor: "green",
          alignItems: "center",
        }}>
        <h3 style={{ color: "white" }}>Edit</h3>
        <CloseIcon
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => {
            if (!inProgress) {
              handleClose(false);
            }
          }}
        />
      </div>
      <div
        style={{
          padding: "1em",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
        }}>
        <div className="group-comb">
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>NOTIFICATION EVENT*</label>
            <input
              type="text"
              name="displayName"
              disabled
              value={formData.displayName}
              onChange={handleChangeFromEvent}
              className="form-control"
              placeholder="Notification Event"
            />
          </div>
          {formValidationErrors.dnError ? (
            <span className="validation-help">
              {formValidationErrors.dnError}
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="group-comb">
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>NOTIFICATION MEDIUM*</label>
            <div
              className="row"
              style={{ alignItems: "center", marginTop: "0.5em" }}>
              <Checkbox
                disabled={true}
                checked={false}
                value={false}
                // checked={formData.viaEmail}
                // value={formData.viaEmail}
                onChange={() => handleChange("viaEmail", !formData.viaEmail)}
              />
              <span>Email</span>
            </div>

            <div
              className="row"
              style={{ alignItems: "center", marginTop: "0.5em" }}>
              <Checkbox
                checked={formData.viaMobile}
                value={formData.viaMobile}
                onChange={() => handleChange("viaMobile", !formData.viaMobile)}
              />
              <span>Mobile</span>
            </div>

            <div
              className="row"
              style={{ alignItems: "center", marginTop: "0.5em" }}>
              <Checkbox
                checked={formData.viaSystem}
                value={formData.viaSystem}
                onChange={() => handleChange("viaSystem", !formData.viaSystem)}
              />
              <span>System</span>
            </div>

            <div
              className="row"
              style={{ alignItems: "center", marginTop: "0.5em" }}>
              <Checkbox
                disabled={true}
                checked={false}
                value={false}
                // checked={formData.viaSms}
                // value={formData.viaSms}
                onChange={() => handleChange("viaSms", !formData.viaSms)}
              />
              <span>SMS</span>
            </div>
          </div>
        </div>

        <div className="group-comb">
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>
              MESSAGE FOR PARTICULAR USER*
            </label>
            <input
              type="text"
              name="particularUserMessage"
              value={formData.particularUserMessage}
              onChange={handleChangeFromEvent}
              className="form-control"
              placeholder="Particular User Message"
            />
          </div>
          {formValidationErrors.pmError ? (
            <span className="validation-help">
              {formValidationErrors.pmError}
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="group-comb">
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>
              MESSAGE FOR ADMIN SITE*
            </label>
            <input
              type="text"
              name="adminSiteMessage"
              value={formData.adminSiteMessage}
              onChange={handleChangeFromEvent}
              className="form-control"
              placeholder="Admin Site Message"
            />
          </div>
          {formValidationErrors.amError ? (
            <span className="validation-help">
              {formValidationErrors.amError}
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "1em 1.5em",
          height: "auto",
          alignItems: "center",
        }}>
        <button
          style={{
            backgroundColor: "#294d94",
            padding: "1em 4em",
            cursor: "pointer",
            border: "none",
            fontWeight: "bold",
            borderRadius: "10px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1em",
          }}
          onClick={updateNotficationModule}>
          {inProgress && (
            <CircularProgress style={{ color: "white" }} size={20} />
          )}
          {inProgress ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}

export default EditNotificationSettingsPopUp;
