import React, { useState, useEffect, useCallback } from "react";
import "./Header.css";
import HeaderLeft from "./HeaderLeft";
import { Route, useLocation } from "react-router-dom";
import storage from "../config/firebase";
import url from "../config/axios";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import getCroppedImg from "../helpers/cropImage";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import isEmail from "email-validator";
import data from "../helpers/countries.json";

function Header() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [showProfile, setShowProfile] = useState(false);
  const [editAdmin, setEditAdmin] = useState(false);

  const [image, setImage] = useState("");
  const [editImage, setEditImage] = useState("");
  const [file, setFile] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState(0);
  const [countryCode, setCountryCode] = useState(0);
  const [selectCountry, setSelectCountry] = useState([]);
  // const [isFormValid, setIsFormValid] = useState(false);
  const [validationMessages, setvalidationMessages] = React.useState({
    name: "",
    email: "",
    phone: "",
  });

  // const [value, setValue] = useState(0);

  const admin = JSON.parse(localStorage.getItem("Admin")) || null;

  useEffect(() => {
    getAdminDetails();
    getCountryCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validateData = async () => {
    try {
      var re = /^[0-9\b]+$/;
      let data = { ...validationMessages };
      //validate email
      if (email.trim().length === 0) {
        data = { ...data, email: "Email is required" };
      } else if (!isEmail.validate(email)) {
        // console.log(!isEmail.validate(email), !testEmail, "CAHA");
        data = { ...data, email: "Email is not valid" };
      } else {
        data = { ...data, email: "" };
      }

      //Validate Phone
      if (
        !re.test(phoneNo) ||
        phoneNo.toString().length < 4 ||
        phoneNo.toString().length > 11 ||
        phoneNo === null
      ) {
        console.log("FALSE");
        data = { ...data, phone: "Phone is not valid" };
      } else {
        data = { ...data, phone: "" };
      }

      //Validate Name
      if (name.split(" ")[0].length <= 3) {
        data = { ...data, name: "First name is not valid" };
      } else {
        data = { ...data, name: "" };
      }

      setvalidationMessages({ ...data });

      return data;
    } catch (e) {
      console.log(e, "ERRROR");
    }
  };

  const getCountryCode = () => {
    let x = selectCountry;
    data.map((e) => {
      return x.push({
        value: e.dial_code,
        label: e.flag + e.dial_code,
        flag: e.flag,
        code: e.code,
        dial_code: e.dial_code,
      });
    });
    setSelectCountry(x);
    // console.log(x)
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(
          editImage,
          croppedAreaPixels,
          0
        );
        console.log("donee", { croppedImage });
        localStorage.setItem("ImageSet", croppedImage);
        setEditImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, editImage]
  );

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setEditImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  const updateAdmin = (blob) => {
    try {
      if (
        validationMessages?.name === "" &&
        validationMessages?.email === "" &&
        validationMessages?.phone === ""
      ) {
        // console.log(data, "DARAWDAW");
        let body = {
          adminName: name,
          adminEmail: email,
          phone: phoneNo,
          adminImage: blob,
        };
        if (admin[0].isSubOutlet && admin[0].soleOutlet) {
          url
            .put(`/oca/?adminID=${admin[0].adminID}`, body)
            .then((res) => {
              console.log(res, "main admin Details");
              if (res.data.Message === "Success") {
                setName(res.data.data.adminName);
                setEmail(res.data.data.adminEmail);
                setPhoneNo(res.data.data.phone);
                setImage(res.data.data.adminImage);
                setEditAdmin(false);
                setShowProfile(false);
              }
            })
            .catch((e) => console.log(e));
        } else if (admin[0].isSubOutlet) {
          url
            .put(`/oc/?outletChainID=${admin[0].outletChainID}`, body)
            .then((res) => {
              // console.log(res, "Sole admin Details");
              if (res.data.Message === "Success") {
                setName(res.data.data.adminName);
                setEmail(res.data.data.adminEmail);
                setPhoneNo(res.data.data.phone);
                setImage(res.data.data.adminImage);
                setEditAdmin(false);
                setShowProfile(false);
              }
            })
            .catch((e) => console.log(e));
        } else {
        }
      }
    } catch (e) {
      console.log(e, "ERROR");
    }
  };

  const fileUpdate = () => {
    try {
      // const storageRef = ref(storage, `images/${file.name}`);
      const uploadTask = storage
        .ref(`images/${file.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");
      if (file !== "") {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // const progress = Math.round(
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // );
          },
          (error) => {
            console.log(error);
          },
          () => {
            storage
              .ref("images")
              .child(file.name)
              .getDownloadURL()
              .then(async (blob) => {
                console.log(blob);
                await updateAdmin(blob);
              });
          }
        );
      } else {
        validateData();
      }
    } catch (e) {
      validateData();
    }
  };

  const getAdminDetails = () => {
    if (admin !== null) {
      setCountryCode(admin[0]?.countryCode);
      setName(admin[0]?.outletName ?? "__");
      // setName(admin[0].adminName);
      setEmail(admin[0].adminEmail);
      setPhoneNo(admin[0].phone);
      setImage(admin[0].adminImage);
      setEditImage(admin[0].adminImage);
    }

    if (admin[0].isSubOutlet) {
      url
        .get(`/oc/id/?outletChainID=${admin[0].outletChainID}`)
        .then((res) => {
          // console.log(res, "Sole admin Details");
          if (res.data.Message === "Success") {
            setName(res.data.data[0].adminName);
            setEmail(res.data.data[0].adminEmail);
            setPhoneNo(res.data.data[0].phone);
            setImage(res.data.data[0].adminImage);
            setEditImage(res.data.data[0].adminImage);
          }
        })
        .catch((e) => console.log(e));
    } else {
      url
        .get(`/oca/?adminID=${admin[0].adminID}`)
        .then((res) => {
          console.log(res, "Multi admin Details");
          if (res.data.Message === "Success") {
            setName(res.data.data[0].adminName);
            setEmail(res.data.data[0].adminEmail);
            setPhoneNo(res.data.data[0].phone);
            setImage(res.data.data[0].adminImage);
            setEditImage(res.data.data[0].adminImage);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="collapse navbar-collapse" id="navbarText">
          <div className="row row-header">
            <Route path="/dashboard">
              {" "}
              <HeaderLeft name="Dashboard" />
            </Route>
            <Route path="/categories">
              {" "}
              <HeaderLeft name="Categories" />
            </Route>
            <Route path="/brands">
              {" "}
              <HeaderLeft name="Manufacturers" />
            </Route>
            <Route path="/outlets">
              {" "}
              <HeaderLeft name="Outlet Chain" />
            </Route>
            <Route path="/add-outlet">
              {" "}
              <HeaderLeft name="Add Outlet" />
            </Route>
            <Route path="/edit-outlet">
              {" "}
              <HeaderLeft name="Edit Outlet" />
            </Route>
            <Route path="/deals-outlet">
              {" "}
              <HeaderLeft name="Deals" />
            </Route>
            <Route path="/banner">
              {" "}
              <HeaderLeft name="Banners" />
            </Route>
            <Route path="/add-banner">
              {" "}
              <HeaderLeft name="Add New Banner" />
            </Route>
            <Route path="/edit-banner">
              {" "}
              <HeaderLeft name="Edit Banner" />
            </Route>
            <Route path="/deals">
              {" "}
              <HeaderLeft name="Deals" />
            </Route>
            <Route path="/add-deal">
              {" "}
              <HeaderLeft name="Add New Deal" />
            </Route>
            <Route path="/add-membership">
              {" "}
              <HeaderLeft name="Add Membership" />
            </Route>
            <Route path="/edit-membership">
              {" "}
              <HeaderLeft name="Edit Membership" />
            </Route>
            <Route path="/membership">
              {" "}
              <HeaderLeft name="Membership" />
            </Route>
            <Route path="/edit-deal">
              {" "}
              <HeaderLeft name="Edit Deal" />
            </Route>
            <Route path="/discounts">
              {" "}
              <HeaderLeft name="Discounts" />
            </Route>
            <Route path="/discounts-outlet">
              {" "}
              <HeaderLeft name="Coupons" />
            </Route>
            <Route path="/add-coupon">
              {" "}
              <HeaderLeft name="Add New Coupon" />
            </Route>
            <Route path="/edit-coupon">
              {" "}
              <HeaderLeft name="Edit Coupon" />
            </Route>
            <Route path="/users">
              {" "}
              <HeaderLeft name="Users" />
            </Route>
            <Route path="/user-detail">
              {" "}
              <HeaderLeft name="User Details" />
            </Route>
            <Route path="/add-missing">
              {" "}
              <HeaderLeft name="Add Missing Product" />
            </Route>
            <Route path="/inventory">
              {" "}
              <HeaderLeft name="Products" />
            </Route>
            <Route path="/inventory-outlet">
              {" "}
              <HeaderLeft name="Products" />
            </Route>
            <Route path="/catalog-outlet">
              {" "}
              <HeaderLeft name="Catalog" />
            </Route>
            <Route path="/order-outlet">
              {" "}
              <HeaderLeft name="Orders" />
            </Route>
            <Route path="/order">
              {" "}
              <HeaderLeft name="Orders" />
            </Route>
            <Route path="/order-detail">
              {" "}
              <HeaderLeft
                name={window?.oID ? `Order #${window.oID}` : "Order Details"}
              />
            </Route>
            <Route path="/addCatalog">
              {" "}
              <HeaderLeft name="Add Catalog" />
            </Route>
            <Route path="/editCatalog">
              {" "}
              <HeaderLeft name="Edit Catalog" />
            </Route>
            <Route path="/page">
              {" "}
              <HeaderLeft name="Pages" />
            </Route>
            <Route path="/setting">
              {" "}
              <HeaderLeft name="General Settings" />
            </Route>
            <Route path="/add-setting">
              {" "}
              <HeaderLeft name="Update Settings" />
            </Route>
            <Route path="/add-page">
              <HeaderLeft name={searchParams.get("id")} />
            </Route>

            <Route path="/applied-license">
              {" "}
              <HeaderLeft name="Applied License" />
            </Route>
            <Route path="/applied-license-detail">
              {" "}
              <HeaderLeft name="License Detail" />
            </Route>
            <Route path="/license">
              {" "}
              <HeaderLeft name="License" />
            </Route>

            <Route path="/gene">
              {" "}
              <HeaderLeft name="License" />
            </Route>

            <Route path="/general-settings">
              {" "}
              <HeaderLeft name="General Settings" />
            </Route>
            <Route path="/loyalty-points">
              {" "}
              <HeaderLeft name="Loyalty Points" />
            </Route>
            <Route path="/loyalty-add-points">
              {" "}
              <HeaderLeft name="Add Loyalty Points" />
            </Route>
            <Route path="/support-desk">
              {" "}
              <HeaderLeft name="Support Desk" />
            </Route>
            <Route path="/support-info">
              {" "}
              <HeaderLeft name="Support Desk" />
            </Route>
            <Route path="/conversations">
              {" "}
              <HeaderLeft name="Conversations" />
            </Route>
            <Route path="/conversation-info">
              {" "}
              <HeaderLeft name="Conversations" />
            </Route>
            <Route path="/my-notifications">
              {" "}
              <HeaderLeft name="Notifications" />
            </Route>
            <div className="col-md-5 user-head">
              <span className="notification-icons">
                {/* <span>
                <i className="far fa-question-circle"></i>
              </span>
              <span>
                <i className="fas fa-headset"></i>
              </span>
              <span>
                <i className="far fa-bell"></i>
              </span> */}
              </span>

              <span className="navbar-text">
                <div className="container d-flex justify-content-center">
                  <div className="">
                    <div
                      className="top-container"
                      onClick={() => setShowProfile(!showProfile)}
                      style={{ cursor: "pointer" }}
                    >
                      {image ? (
                        <img
                          alt="yo4"
                          src={image}
                          className="img-fluid profile-image"
                          width="53"
                        />
                      ) : (
                        <div
                          className="image-thumbnail-div"
                          style={{
                            backgroundColor: "green",
                            width: "40px",
                            height: "40px",
                            borderRadius: "5px",
                          }}
                        >
                          <span>{admin[0]?.adminName?.substring(0, 1)}</span>
                        </div>
                      )}
                      {/* <img
                        alt="yo4"
                        src={image}
                        className="img-fluid profile-image"
                        width="53"
                      /> */}
                      <div className="ml-3">
                        <h5 className="name">{name}</h5>
                        <p className="mail">
                          {admin
                            ? admin[0]?.isSubOutlet
                              ? "Sole Chain Admin"
                              : "Multi Chain Admin"
                            : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </nav>
      {showProfile && (
        <div className="profile-section">
          <div className="profile-parent">
            <label
              onClick={() => setShowProfile(false)}
              className="label-close"
              style={{ cursor: "pointer", margin: "15px" }}
            >
              <i class="fas fa-times"></i>
            </label>
            <span className="navbar-text">
              <div className="parent-right-nav container d-flex justify-content-center">
                <div className="">
                  <div className="top-container-rnav">
                    <form>
                      <div className="rnav-image">
                        <div className="image-container-rnav">
                          {editImage ? (
                            <img
                              alt="yo4"
                              src={editImage}
                              className="img-fluid profile-image"
                              width="53"
                            />
                          ) : (
                            <div
                              className="image-thumbnail-div"
                              style={{
                                backgroundColor: "green",
                                width: "120px",
                                height: "120px",
                                borderRadius: "60px",
                              }}
                            >
                              <span style={{ fontSize: "40px" }}>
                                {admin[0]?.adminName?.substring(0, 1)}
                              </span>
                            </div>
                          )}

                          <label className="custom-file-upload-rnav">
                            <input
                              type="file"
                              id="image1"
                              disabled={!editAdmin}
                              name="Image1"
                              onChange={imageSelector}
                              accept="image/png, image/jpeg"
                            />
                            <i className="fas fa-pen"></i>
                          </label>
                          {showCrop && (
                            <Dialog
                              fullScreen={fullScreen}
                              open={open}
                              fullWidth={true}
                              onClose={() => setOpen(false)}
                              aria-labelledby="responsive-dialog-title"
                            >
                              <div
                                className="App"
                                style={{
                                  padding: "1em",
                                  width: "auto",
                                  height: "auto",
                                }}
                              >
                                <div className="crop-container">
                                  <Cropper
                                    image={editImage}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={4 / 3}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                  />
                                </div>
                                <div className="controls">
                                  <Slider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => setZoom(zoom)}
                                    // classes={{ root: "slider" }}
                                  />
                                </div>
                                <div>
                                  <button
                                    style={{
                                      textAlign: "center",
                                      width: "auto",
                                      padding: "10px 30px",
                                    }}
                                    className="btn btn-primary crop-btn"
                                    onClick={(e) => showCroppedImage(e)}
                                  >
                                    Crop
                                  </button>
                                </div>
                              </div>
                            </Dialog>
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          "rnav-name " + (editAdmin ? "rnav-input-active" : "")
                        }
                      >
                        <label>Name*</label>
                        <input
                          type="text"
                          disabled={!editAdmin}
                          // disabled
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="name"
                          style={{ backgroundColor: "#e9ecef" }}
                        />
                      </div>
                      {validationMessages?.name ? (
                        <span
                          className="validation-help"
                          style={{ marginLeft: "10px" }}
                        >
                          {validationMessages?.name}
                        </span>
                      ) : (
                        ""
                      )}
                      <div
                        className={
                          "rnav-phone " + (editAdmin ? "rnav-input-active" : "")
                        }
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <label className="phone-label">Code*</label>
                          </div>
                          <div className="col-md-8" style={{ padding: "0" }}>
                            <label className="phone-label">Phone*</label>
                          </div>
                          <div
                            className="col-md-4 pin-input-rnav"
                            style={{ paddingRight: "4px" }}
                          >
                            <select
                              style={{ backgroundColor: "#e9ecef" }}
                              disabled={!editAdmin}
                              className="form-control"
                              id="exampleFormControlSelect1"
                              value={countryCode}
                              onChange={(e) => {
                                setCountryCode(e.target.value);
                              }}
                            >
                              {data?.map((item) => {
                                return (
                                  <option value={item.dial_code}>
                                    {item.flag + " " + item.dial_code}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-md-8  phone-input-rnav">
                            <input
                              type="number"
                              disabled={!editAdmin}
                              value={phoneNo}
                              onKeyPress={(e) => {
                                var charCode =
                                  typeof e.which == "undefined"
                                    ? e.keyCode
                                    : e.which;
                                var charStr = String.fromCharCode(charCode);

                                if (!charStr.match(/^[0-9]+$/))
                                  e.preventDefault();
                              }}
                              // onInput={(e) => {
                              //   e.target.value = Math.abs(e.target.value);
                              //   if (e.target.value > 10000000000) {
                              //     e.target.value = Math.floor(
                              //       e.target.value / 10
                              //     );
                              //     setValue(e.target.value);
                              //   } else if (e.target.value < 1000000) {
                              //     e.target.value = Math.floor(
                              //       e.target.value * 10 + (value % 10)
                              //     );
                              //     setValue(e.target.value);
                              //   }
                              // }}
                              onChange={(e) => setPhoneNo(e.target.value)}
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="phone no"
                              style={{ backgroundColor: "#e9ecef" }}
                            />
                            {validationMessages?.phone ? (
                              <span className="validation-help">
                                {validationMessages?.phone}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          "rnav-name " + (editAdmin ? "rnav-input-active" : "")
                        }
                      >
                        <label>Email*</label>
                        <input
                          type="text"
                          value={email}
                          disabled={!editAdmin}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="email"
                          style={{ backgroundColor: "#e9ecef" }}
                        />
                        {validationMessages?.email ? (
                          <span
                            className="validation-help"
                            style={{ color: "red" }}
                          >
                            {validationMessages?.email}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </form>
                    <div className="rnav-btn-grp">
                      {!editAdmin && (
                        <button
                          className="btn btn-rnav"
                          onClick={() => setEditAdmin(true)}
                        >
                          Edit
                        </button>
                      )}
                      {editAdmin && (
                        <div>
                          <button
                            style={{ marginTop: "0" }}
                            className="btn btn-cannaby"
                            onClick={() => fileUpdate()}
                          >
                            Save
                          </button>
                          <button
                            className="btn btn-rnav"
                            onClick={() => {
                              setEditAdmin(false);
                              getAdminDetails();
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </span>
            {/* <div className="nav-msg">
              <p>This is a super admin account</p>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
