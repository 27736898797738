import React, { useState, useEffect } from 'react'
import url from '../../config/axios'
import { useHistory } from "react-router-dom";
import data from '../../helpers/countries.json'
import Select from 'react-select';
import { State, City } from 'country-state-city';
import { countries } from '../../helpers/countriesList'

export default function EditOutlet() {
    const history = useHistory();
    const [outletName, setOutletName] = useState("");
    const [adminName, setAdminName] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneCode, setPhoneCode] = useState("");
    const [address, setAddress] = useState("");
    const [activeStatus, setActiveStatus] = useState(false);
    const [selectCountry, setSelectCountry] = useState([]);
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [country, setCountry] = useState("")
    const [statesList, setStatesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);

  const Outlet = JSON.parse(localStorage.getItem("outlet"));

  useEffect(() => {
      console.log(Outlet);
      setOutletName(Outlet.outletName);
      setAdminName(Outlet.adminName);
      setAdminEmail(Outlet.adminEmail);
      setPhoneNo(Outlet.phone);
      setAddress(Outlet.address);
      setCity(Outlet.city);
      setState(Outlet.state);
      setActiveStatus(Outlet.isActive);
      getCountryCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCountryCode = () => {
    let x = selectCountry;
    data.map((e) => {
      return x.push({ value: e.dial_code, label: e.flag + e.dial_code, flag: e.flag, code: e.code, dial_code: e.dial_code })
    })
    setSelectCountry(x)
    // console.log(x)
    }

    const setCountryAndGetState = (country) => {
        // console.log(country.split(','), 'country:');
        setCountry(country.split(',')[0]);
        setStatesList(State.getStatesOfCountry(country.split(',')[1]));
        // setStatesList(State.getStatesOfCountry('US'));
    }

    const setStateAndGetCity = (state) => {
        // console.log(state.split(','), 'state:');
        setState(state.split(',')[0]);
        setCitiesList(City.getCitiesOfState(state.split(',')[2], state.split(',')[1]));
    }

    const EditOutlet = () => {
        let body = {
            outletName: outletName,
            adminName: adminName,
            adminEmail: adminEmail,
            phone: phoneCode + phoneNo,
            address: address,
            city: city,
            state: state,
            isActive: activeStatus
        }
        // console.log(body, 'body')

        url.put(`/oc/?outletChainID=${Outlet.outletChainID}`, body).then(async(res) => {
            // console.log(res, 'res')
            if(res.data.Message === 'Failure'){
                alert(res.data.data);
            }
            else{
                alert("Outlet Updated");
                history.push('/outlets')
            }
        }).catch(e => console.log(e));

    }
    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div>
                            <h4>Outlet Details</h4>
                            <br></br>
                            <form className="">

                                <div className="group-comb">
                                    <div className="form-group">
                                        <label  >Outlet Chain Name *</label>
                                        <input type="text" value={outletName} onChange={(e) => setOutletName(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="name" />
                                    </div>
                                </div>

                                <div className="group-comb">
                                    <div className="form-group">
                                        <label  >Admin's Full Name *</label>
                                        <input type="text" value={adminName} onChange={(e) => setAdminName(e.target.value)} className="form-control" id="exampleFormControlInput" placeholder="name" />
                                    </div>
                                    <p>Full name</p>
                                </div>

                                <div className="group-comb">
                                    <div className="form-group">
                                        <label  >Admin's Email Address *</label>
                                        <input type="email" value={adminEmail} onChange={(e) => setAdminEmail(e.target.value)} className="form-control" id="exampleForControlInput1" placeholder="jeremy@gmail.com" />
                                    </div>
                                    <p>temporary password will be shared in this email</p>
                                </div>
                                <div className="group-comb">
                                    <div className="form-group">

                                        <div className="row">
                                            <div className="col-md-3 pin-input" >
                                            <Select
                                                name="colors"
                                                options={selectCountry}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={(e) => {
                                                console.log(e.dial_code);
                                                setPhoneCode(e.dial_code)
                                                }}
                                            />
                                            </div>
                                            <div className="col-md-9">
                                                <label  >Phone No</label>
                                                <input type="text" value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} className="form-control" id="exapleFormControlInput1" placeholder="phone no" />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="group-comb">
                                    <div className="form-group">
                                        <label  >Set Location on MAP</label>
                                        <input type="text" className="form-control" id="exampleFormContolInput1" placeholder="Geo Location" />
                                    </div>
                                </div>

                                <div className="group-comb">
                                    <div className="form-group">
                                        <label  >Address</label>
                                        <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} className="form-control" id="exampleFrmControlInput1" placeholder="36 merly street" />
                                    </div>
                                    <p>please type the chain registered name</p>
                                </div>
                                <div className="group-comb">
                                    <div className="form-group">
                                        <label >Country</label>
                                        <select style={{ width: '100%' }} onChange={(e) => setCountryAndGetState(e.target.value)}>
                                            {country !== "" && <option>{country !== "" ? country : ''}</option>}
                                            {country === "" && <option>{country.length === 0 ? 'Select Country' : country}</option>}

                                            {countries.map((ctr, i) => {
                                                // console.log(ctr, 'ctr');
                                                return <option key={i} value={`${ctr.name},${ctr.isoCode}`}>{ctr.name}</option>
                                            })}
                                        </select>
                                        {/* <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Input Country" /> */}
                                    </div>
                                </div>
                                <div className="group-comb">
                                    <div className="form-group">
                                        <label>State</label>
                                        <select style={{ width: '100%' }} onChange={(e) => setStateAndGetCity(e.target.value)}>
                                            {state !== "" && <option>{state !== "" ? state : ''}</option>}
                                            {state === "" && <option>{statesList.length === 0 ? 'Select Country First' : 'Select State'}</option>}
                                            {statesList.map((str, i) => {
                                                // console.log(str, 'ctr');
                                                return (
                                                    <option key={i} value={`${str.name},${str.isoCode},${str.countryCode}`}>{str.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="group-comb">
                                    <div className="form-group">
                                        <label>City</label>
                                        <select style={{ width: '100%' }} onChange={(e) => setCity(e.target.value)}>
                                            {city !== "" && <option>{city !== "" ? city : ''}</option>}
                                            {city === "" && <option>{citiesList.length === 0 ? 'Select State First' : 'Select City'}</option>}
                                            {citiesList.map((cty, i) => {
                                                // console.log(cty, 'ctr');
                                                return (
                                                    <option key={i} value={cty.name}>{cty.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="group m-4">
                                    {/* {editBoolean && <button type="button" class="btn btn-primary m-2">Update</button>}
                                    {!editBoolean && <button type="button" onClick={addOutlet} class="btn btn-primary m-2">Make Live</button>} */}
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <span className="solo-chain">Status</span>
                                    </div>
                                    <div className="col-md-6 switch-col">
                                        <label className="switch">
                                            <input type="checkbox" onChange={() => setActiveStatus(!activeStatus)} />
                                            <span className="slider round"></span>
                                        </label>

                                    </div>
                                </div>
                                <p className="createdOn">Last Modified on <span>Jul. 13, 2021 3:20 PM</span></p>

                                <button type="button" onClick={() =>EditOutlet()} className="btn btn-primary m-2">Update</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
