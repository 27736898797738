import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState, useRef, Fragment } from "react";
import InvokeLayoutComponent from "../../components/common/InvokeLogout";
import { serializeQueryStringsFromObject } from "../../helpers/serializeToQueryStrings";
//eslint-disable-next-line
import url from "../../config/axios";
//eslint-disable-next-line
import moment from "moment";
import Popup from "reactjs-popup";
import debounce from "../../helpers/debounce";
import { CircularProgress } from "@mui/material";

const dummyData = new Array(10).fill({
  id: "#1000929928",
  sendingDate: "2021-03-08 01:36:13",
  subject:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam nobis beatae dolorum velit earum, quo distinctio culpa odit minus odio dignissimos, eaque porro. Corrupti laborum quos repellat veritatis ipsam sequi!",
  user: "Mr. customer",
  status: "Pending",
  lastReplyDate: "2021-03-08 01:36:13",
});

const SupportDeskPage = () => {
  const history = useHistory();
  //eslint-disable-next-line
  const [conversations, setConversations] = useState([...dummyData]);
  //eslint-disable-next-line
  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 30,
    latestFirst: 1,
    search: "",
    total: 10,
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const notFoundRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");

  const performSearch = debounce(() => {
    if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
      setPreviousSearchTerm(searchTerm);
      handleChange("search", searchTerm, true);
    } else if (searchTerm === "") {
      setPreviousSearchTerm("");
      handleChange("search", "", true);
    }
  }, 1500);
  useEffect(
    () => {
      if (parsed && !loading) {
        performSearch();
      }
    },
    //eslint-disable-next-line
    [searchTerm]
  );
  //parse passed queries from the URL first
  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 30
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );

  //eslint-disable-next-line
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 1,
    total: 10,
  });
  const [loading, setLoading] = useState(true);

  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllConversations({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    let query = {};
    if (flush) {
      query = {
        ...initialPaginationData,
        [propName]: propValue,
      };
    } else {
      query = { ...queryData, [propName]: propValue };
    }
    const queryString = serializeQueryStringsFromObject(query);
    history.push(`/support-desk${queryString}`);
    setQueryData({ ...query });
  };

  const GetAllConversations = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    // url
    //   .get(`/v2/conversations/paginated`, {
    //     params: {
    //       ...queryParams,
    //     },
    //   })
    //   .then(async (res) => {
    //     // console.log('res', res)
    //     if (res.data.Message === "Success") {
    //       if (!res.data?.data?.conversations?.length) {
    //         notFoundRef.current.open();
    //       }
    //       setConversations(res.data?.data?.conversations);
    //       console.log(res?.data?.data?.paginationData);
    //       setPaginationData({
    //         ...paginationData,
    //         ...res?.data?.data?.paginationData,
    //       });
    //     } else {
    //       console.error(res);
    //     }
    //     setLoading(false);
    //   })
    //   .catch((e) => {
    //     if (+e?.response?.status === 401) {
    //       setInvokeLogout(true);
    //     }
    //     setLoading(false);
    //     console.error(e?.response);
    //   });
    setLoading(false);
  };
  //eslint-disable-next-line
  // const [toDelete, setToDelete] = useState(null);
  // const ref = useRef();
  // const openTooltip = () => ref.current.open();
  // const closeTooltip = () => {
  //   setToDelete(null);
  //   ref.current.close();
  // };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          {loading ? (
            <div
              className="row"
              style={{
                padding: "1em",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <CircularProgress />
            </div>
          ) : (
            <Fragment>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <input
                          type="text"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                          value={searchTerm}
                          className="form-control table-search"
                          placeholder="Type ticket code"
                          aria-label="Input group example"
                          aria-describedby="btnGroupAddon"
                        />
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text table-search-icon"
                            id="btnGroupAddon">
                            <i className="fas fa-search"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 filters-bar">
                      <div className="dropdown">
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink">
                          <a className="dropdown-item" href="/">
                            Action
                          </a>
                          <a className="dropdown-item" href="/">
                            Another action
                          </a>
                          <a className="dropdown-item" href="/">
                            Something else here
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink">
                          <a className="dropdown-item" href="/">
                            Action
                          </a>
                          <a className="dropdown-item" href="/">
                            Another action
                          </a>
                          <a className="dropdown-item" href="/">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pagination">
                      {paginationData.totalPages !== 0 &&
                        paginationData.currentPage <=
                        paginationData.totalPages && (
                          <Fragment>
                            <span>
                              {`${paginationData.perPage *
                                (paginationData.currentPage - 1) +
                                1
                                } - ${paginationData.perPage *
                                (paginationData.currentPage - 1) +
                                conversations.length
                                } of ${paginationData.total ?? 0}`}
                            </span>
                            <div
                              className={`btn ${paginationData.currentPage <= 1
                                  ? ""
                                  : "cannaby-light"
                                }`}
                              onClick={() => {
                                if (!(paginationData.currentPage <= 1)) {
                                  handleChange(
                                    "page",
                                    paginationData.currentPage - 1
                                  );
                                }
                              }}
                              role="button">
                              <i className="fas fa-chevron-left"></i>
                            </div>
                            <div
                              onClick={() => {
                                if (
                                  !(
                                    paginationData.currentPage >=
                                    paginationData.totalPages
                                  )
                                ) {
                                  handleChange(
                                    "page",
                                    paginationData.currentPage + 1
                                  );
                                }
                              }}
                              className={`btn ${paginationData.currentPage >=
                                  paginationData.totalPages
                                  ? ""
                                  : "cannaby-light"
                                }`}
                              role="button">
                              <i className="fas fa-chevron-right"></i>
                            </div>
                          </Fragment>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  {conversations.length === 0 ? null : (
                    <table className="table table-striped-cust">
                      <thead>
                        <tr>
                          <th>
                            <span
                              style={{
                                fontSize: "100%",
                              }}>
                              Ticket ID
                            </span>
                          </th>
                          <th className="text-center">
                            <span
                              style={{
                                fontSize: "100%",
                              }}>
                              Sending Date
                            </span>
                          </th>
                          <th className="text-center">
                            <span
                              style={{
                                fontSize: "100%",
                              }}>
                              Subject
                            </span>
                          </th>
                          <th className="text-center">
                            <span
                              style={{
                                fontSize: "100%",
                              }}>
                              User
                            </span>
                          </th>
                          <th className="text-center">
                            <span
                              style={{
                                fontSize: "100%",
                              }}>
                              Status
                            </span>
                          </th>
                          <th className="text-center">
                            <span
                              style={{
                                fontSize: "100%",
                              }}>
                              Last Reply
                            </span>
                          </th>
                          <th className="text-center">
                            <span
                              style={{
                                fontSize: "100%",
                              }}>
                              Option
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {conversations.map((element, i) => {
                          //   console.log(coupon, 'coupon:');
                          return (
                            <tr key={i}>
                              <td style={{ width: "10%" }}>{element?.id}</td>
                              <td
                                style={{ width: "20%" }}
                                className="text-center">
                                {element?.sendingDate}
                              </td>
                              <td
                                style={{
                                  width: "20%",
                                  maxWidth: "60px",
                                  whiteSpace: "pre",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                className="text-center">
                                {element?.subject}
                              </td>
                              <td
                                style={{ width: "15%" }}
                                className="text-center">
                                {element?.user}
                              </td>
                              <td className="text-center">{element?.status}</td>
                              <td
                                className="text-center"
                                style={{ width: "20%" }}>
                                {element?.lastReplyDate}
                              </td>
                              <td
                                // style={{ width: "15%" }}
                                className="text-center">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2em",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}>
                                  <i
                                    className="fa-solid fa-eye"
                                    style={{
                                      color: "green",
                                      cursor: "pointer",
                                      fontStyle: "normal",
                                    }}
                                    onClick={() => {
                                      //TO DO
                                      history.push(
                                        `/support-info?key=${"testing"}`
                                      );
                                    }}></i>
                                  {/* onClick={() => moveToEdit(product)} */}
                                  {/* <i
                                    className="fa fa-trash-alt"
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => {
                                      setToDelete(element);
                                      openTooltip();
                                    }}></i> */}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
      {invokeLogOut && <InvokeLayoutComponent />}
      {/* <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <h4 className="popup-heading">
            Do you want to delete this conversation ?
          </h4>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              closeTooltip();
            }}>
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeTooltip}>
            No
          </button>
        </div>
      </Popup> */}
      <Popup
        ref={notFoundRef}
        position="center"
        onClose={() => {
          notFoundRef.current.close();
          setSearchTerm("");
        }}>
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Conversation(s) not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={() => {
              notFoundRef.current.close();
              setSearchTerm("");
            }}>
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
};
export default SupportDeskPage;
