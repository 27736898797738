import "./Categories.css";
// import { useHistory } from "react-router-dom";
import { useState } from "react";
// import {useHistory} from 'react-router-dom'
const HeaderLeft = (props) => {
  const [value, setValue] = useState(props.name);
  // const history = useHistory();
  if (value === "terms") {
    setValue("Edit Terms & Conditions");
  } else if (value === "privacy") {
    setValue("Edit Privacy Policy");
  } else if (value === "about") {
    setValue("Edit About Us");
  }

  return (
    <div className="col-md-7 col-header">
      {/* <span className="navbar-brand" onClick={() => history.goBack()}> */}
      <span className="navbar-brand">
        {/* <span className="goback-nav" style={{ cursor: "pointer" }}>
          <i className="fas fa-chevron-left"></i>
        </span> */}
        <h2>{value}</h2>
      </span>
      {/* <button className="btn btn-primary">
        <i className="fas fa-plus-circle"></i>
        Add {props.name}
      </button> */}
    </div>
  );
};

export default HeaderLeft;
