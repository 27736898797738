import React, { useRef } from "react";
import "./discounts.css";
import url from "../../config/axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Popup from "reactjs-popup";
export default function Index() {
  const history = useHistory();
  const [outlets, setOutlets] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const [minPro, setminPro] = useState(0);
  const [page, setPage] = useState(1);
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  const notFoundRef = useRef();

  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
  };
  const incrementPage = () => {
    if (totalPage < 50) {
    } else {
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  useEffect(() => {
    GetAllOutlets();
    GetAllCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    // console.log(adminID[0].adminID);
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          //  (res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetAllCoupons = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    url
      .get(`/coupon/all/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        // console.log('coupons:', res)
        if (res.data.Message === "Success") {
          setCoupons(res.data.data);
          setTotalPage(res.data.data.length);
          setminPro(page * 50 - 50 + 1);
        } else if (res.data.data.length === 0) {
          openNotFoundPopUp();
        }
      })
      .catch((e) => openNotFoundPopUp());
  };

  const GetCouponsByOutlet = (outletId) => {
    if (outletId !== "select") {
      url
        .get(
          `/coupon/outlet/?consumerId=${adminID[0].consumerId}&outletChainID=${outletId}`
        )
        .then(async (res) => {
          console.log("coupons Outlet:", res);
          if (res.data.Message === "Success") {
            setCoupons(res.data.data);
            setTotalPage(res.data.data.length);
            setminPro(page * 50 - 50 + 1);
          } else {
            openNotFoundPopUp();
          }
        })
        .catch((e) => console.log(e));
    } else {
      GetAllCoupons();
    }
  };

  const editCoupon = (data) => {
    localStorage.setItem("couponEdit", JSON.stringify(data));
    history.push("/edit-coupon");
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          {/* <button
            className="btn btn-primary btn-pro mr-5"
            onClick={() => history.push("/add-coupon")}>
            <i className="fas fa-plus-circle"></i>
            Add Coupon
          </button> */}
          {/* onClick={() => {
              history.push("/addCatalog");
            }} */}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                {!adminID[0].soleOutlet && (
                  <div className="col-md-3">
                    <div className="group-comb">
                      <div className="input-group">
                        <select
                          className="form-control"
                          onChange={(e) => GetCouponsByOutlet(e.target.value)}
                          id="exampleFormControlSelect1"
                        >
                          <option value="select">Select Outlet</option>
                          {outlets.map((outlet, i) => {
                            return (
                              <option value={outlet.outletChainID} key={i}>
                                {outlet.outletName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      placeholder="Search Discounts"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <button
                    className="btn btn-primary"
                    onClick={() => history.push("/add-coupon")}
                  >
                    <i className="fas fa-plus-circle"></i>
                    Add Coupon
                  </button>
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  <span>
                    {minPro}-{coupons.length + minPro - 1} of{" "}
                    {totalPage ? totalPage : 0}
                  </span>
                  <div
                    className="btn cannaby-light"
                    onClick={() => decrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-left"></i>
                  </div>
                  <div
                    className="btn cannaby-light"
                    onClick={() => incrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Coupon Code</th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Applicable to</th>
                    <th scope="col">Value</th>
                    <th scope="col">Expires On</th>
                    <th scope="col">Usage</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {coupons ? (
                    coupons.map((coupon, i) => {
                      //   console.log(coupon, 'coupon:');
                      return (
                        <tr key={i}>
                          <td colSpan="4">{coupon.couponCode}</td>
                          <td>
                            {coupon.selectedProducts.length !== 0 &&
                              coupon.selectedProducts.map((prod, i) => {
                                return (
                                  <span key={i}>
                                    {prod.name +
                                      (i !== coupon.selectedProducts.length - 1
                                        ? ", "
                                        : "")}
                                  </span>
                                );
                              })}
                            {coupon.selectedCategories.length !== 0 &&
                              coupon.selectedCategories.map((prod, i) => {
                                return (
                                  <span key={i}>
                                    {prod.name +
                                      (i !==
                                      coupon.selectedCategories.length - 1
                                        ? ", "
                                        : "")}
                                  </span>
                                );
                              })}
                          </td>
                          <td>{coupon.discountValue}</td>
                          <td>
                            {!!coupon.neverExpires
                              ? "Never Expires"
                              : moment(coupon.endDate).format(
                                  "MMMM Do YYYY h:mm A"
                                )}
                          </td>
                          <td className="status">
                            <span>{coupon.rules.LimitUsesPerCustomer}</span>
                          </td>
                          <td className="status">
                            {coupon.status ? (
                              <span>Disabled</span>
                            ) : (
                              <span>Enabled</span>
                            )}
                          </td>
                          <td>
                            <i
                              className="fas fa-pen"
                              onClick={() => {
                                editCoupon(coupon);
                              }}
                            ></i>
                            {/* onClick={() => moveToEdit(product)} */}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <td colSpan="12" style={{ textAlign: "center" }}>
                          No Coupons found
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Coupons not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={closeNotFoundPopUp}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
