import React from "react";
import { Route, Switch } from "react-router-dom";
import Catalog from "../Catalog";
import Inventory from "../Inventory/Inventory";
import OutletChainAdmin from "../OutletChainAdmin/OutletChainAdmin";
import AddOutletChain from "../../components/OutletChainAdminComponents/AddOutlet";
import EditOutletChain from "../../components/OutletChainAdminComponents/EditOutlet";
// import NotFound from "../../components/NotFound";
import GaurdedRoutes from "./GaurdedRoutes.js";
import CatalogOutlet from "../OutletAdminPages/Catalog";
import DealsOutlet from "../OutletAdminPages/deals";
import InventoryOutlet from "../OutletAdminPages/Inventory/index.v2";
import OrdersOutlet from "../OutletAdminPages/Orders";
import Deals from "../Deals/index.v1";
import Order from "../Order";
import OrderDetail from "../Order/OrderDetail";
// import AddDeal from "../Deals/AddDeal";
// import EditDeal from "../Deals/EditDeal";
import AddDeal from "../Deals/AddDeal.v1";
import EditDeal from "../Deals/EditDeal.v1";
import Discounts from "../Discounts";
import DiscountsOutlet from "../OutletAdminPages/discounts/coupons";
import AddMissing from "../OutletAdminPages/AddMissingProduct";
// import Users from "../Users";
import UserDetails from "../Users/userDetails";
import AddMembership from "../Membership/AddMembership";
import EditMembership from "../Membership/EditMembership";
import Membership from "../Membership/";
import ExcelSample from "../ExcelSample";
import AddCoupons from "../Discounts/AddCoupon";
import EditCoupon from "../Discounts/EditCoupon";
import Banner from "../Banner/Index";
import AddBanner from "../Banner/AddBanner.v2";
import CreatePage from "../PrivacyPage/AddPrivacy";
import AllPage from "../PrivacyPage/Privacy";
import AddSetting from "../Settings/AddSetting";
import Setting from "../Settings/Setting";
import AppliedLicense from "../AppliedLicense/AppliedLicense";
import License from "../License/License";
import LicenseDetail from "../AppliedLicense/LicenseDetail";
import Categories from "../../components/Categories/Categories.v2";
import Brands from "../../components/Brands/Brands.v2";
import Dashboard from "../Dashboard";
import EditBanner from "../Banner/EditBanner.v2";
import GeneralSettingsPage from "../GeneralSettings";
import UsersIndexV2 from "../Users/index.v2";

import SupportDeskPage from "../Support/support-desk";
import ConversationPage from "../Support/conversations";
import ConversationInfoPage from "../Support/conversation-info";
import SupportInfoPage from "../Support/support-info";
import LoyaltyPoints from "../LoyaltyPoints/index";
import AddPoints from "../LoyaltyPoints/AddPoints";
import NotificationsIndex from "../Notifications";

const isAdmin = JSON.parse(localStorage.getItem("Admin"));
export default function Routes() {
  // console.log(isAdmin ? isAdmin[0].soleOutlet : false, 'isAdmin');
  return (
    <Switch>
      <GaurdedRoutes
        path="/catalog"
        component={Catalog}
        auth={isAdmin ? isAdmin[0].soleOutlet : false}
      />
      <Route exact path="/outlets" component={OutletChainAdmin} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/users" component={UsersIndexV2} />
      <Route exact path="/user-detail" component={UserDetails} />
      <Route exact path="/add-outlet" component={AddOutletChain} />
      <Route exact path="/edit-outlet" component={EditOutletChain} />
      <Route exact path="/inventory" component={Inventory} />
      <Route exact path="/order-outlet" component={OrdersOutlet} />
      <Route exact path="/order" component={Order} />
      <Route exact path="/order-detail" component={OrderDetail} />
      <Route exact path="/inventory-outlet" component={InventoryOutlet} />
      <Route exact path="/catalog-outlet" component={CatalogOutlet} />
      <Route exact path="/deals-outlet" component={DealsOutlet} />
      <Route exact path="/deals" component={Deals} />
      <Route exact path="/banner" component={Banner} />
      <Route exact path="/add-banner" component={AddBanner} />
      <Route exact path="/edit-banner" component={EditBanner} />
      <Route exact path="/add-deal" component={AddDeal} />
      <Route exact path="/edit-deal" component={EditDeal} />
      <Route exact path="/discounts" component={Discounts} />
      <Route exact path="/discounts-outlet" component={DiscountsOutlet} />
      <Route exact path="/add-coupon" component={AddCoupons} />
      <Route exact path="/edit-coupon" component={EditCoupon} />
      <Route exact path="/add-missing" component={AddMissing} />
      <Route exact path="/add-membership" component={AddMembership} />
      <Route exact path="/edit-membership" component={EditMembership} />
      <Route exact path="/membership" component={Membership} />
      <Route exact path="/excel" component={ExcelSample} />
      <Route exact path="/add-page" component={CreatePage} />
      <Route exact path="/page" component={AllPage} />
      <Route exact path="/add-setting" component={AddSetting} />
      <Route exact path="/setting" component={Setting} />
      <Route exact path="/applied-license" component={AppliedLicense} />
      <Route exact path="/applied-license-detail" component={LicenseDetail} />
      <Route exact path="/license" component={License} />
      <Route exact path="/categories" component={Categories}></Route>
      <Route exact path="/brands" component={Brands}></Route>
      <Route exact path="/loyalty-points" component={LoyaltyPoints}></Route>
      <Route exact path="/loyalty-add-points" component={AddPoints}></Route>
      <Route
        exact
        path="/general-settings"
        component={GeneralSettingsPage}
      ></Route>

      {/* <Route path="*" component={NotFound} /> */}
      <Route exact path="/support-desk" component={SupportDeskPage} />
      <Route exact path="/conversations" component={ConversationPage} />
      <Route exact path="/conversation-info" component={ConversationInfoPage} />
      <Route exact path="/support-info" component={SupportInfoPage} />
      <Route exact path="/my-notifications" component={NotificationsIndex} />
    </Switch>
  );
}
