/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import url from "../../config/axios";

function SearchBar({ setStepOne, stepOne, setSingleProductDetails }) {
  const [text, setText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [icon, setIcon] = useState(false);

  // useEffect(() => {
  //   url.get(`/product/search/?name=${text}`)
  //     .then(async (res) => {
  //       // console.log(res);
  //       await setProducts(res.data.data);
  //     })
  //     .catch((e) => console.log(e));
  // }, []);

  const onChangeHandler = (text) => {
    setText(text);
    url
      .get(`/product/search/?name=${text}`)
      .then(async (res) => {
        // console.log(res);
        if (res.data.Message === "Success") {
          await setSuggestions(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const onSuggestionsHandler = (text) => {
    setSingleProductDetails(text);
    setText(text.name);
    // setProduct(products[text]);
    // console.log("tests2", product);
    setSuggestions([]);
    setStepOne(!stepOne);
  };

  return (
    <>
      <div className="input-group">
        <input
          type="text"
          className="form-control table-search"
          placeholder={suggestions.length ? "" : "Search by product or brand name.."}
          onChange={(e) => onChangeHandler(e.target.value)}
          value={text}
          onFocus={() => setIcon(!icon)}
        />
        <div className="input-group-prepend">
          <div className="input-group-text table-search-icon" id="btnGroupAddon">
            <i className={icon ? "fas fa-times" : "fas fa-search"}></i>
          </div>
        </div>
      </div>
      <ul className="dropdown-menu dropdown-search drp-menu ml-4" style={{ display: suggestions.length ? "block" : "none" }}>
        {suggestions &&
          suggestions.map((suggestion, i) => {
            console.log(suggestion.name)
            return <li key={i}>
              <a class="dropdown-item" onClick={() => onSuggestionsHandler(suggestion)}>
                <img class="dropdown-img" alt="" src={suggestion.image}/>
                <span>{suggestion.name}</span>
              </a>
            </li>
        })}
      </ul>
    </>
  );
}

export default SearchBar;
