/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function EditMembership() {
  const [level, setLevel] = useState("1");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [amountSpent, setAmountSpent] = useState("");
  const [discountList, setDiscountList] = useState([
    { discounType: "Product Discount", pickProducts: [], type: "%", value: "" },
  ]);
  const theme = useTheme();
  const history = useHistory();
  const [prodName, setProdName] = useState([]);
  const [catName, setCatName] = useState([]);
  const membership = JSON.parse(localStorage.getItem("membership"));
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => ref.current.close();

  // handle input change
  const handleInputChange = (e, i) => {
    if (["discounType", "type", "value"].includes(e.target.name)) {
      console.log("not working");
      let discountDetails = [...discountList];
      discountDetails[i][e.target.name] = e.target.value;
      setDiscountList(discountDetails);
    } else {
      if (discountList[0].discounType === "Product Discount") {
        console.log("working 1");
        let result = selectedProducts(e.target.value);
        let discountDetails = [...discountList];
        discountDetails[i][e.target.name] = result;
        setDiscountList(discountDetails);
      } else if (discountList[0].discounType === "Category Discount") {
        console.log("working 2");
        let result = selectedCategories(e.target.value);
        let discountDetails = [...discountList];
        discountDetails[i][e.target.name] = result;
        setDiscountList(discountDetails);
      }
    }
    console.log(discountList[0], "type;", e.target.name);
  };

  const selectedProducts = async (prod) => {
    setProdName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? prod.split("split") : prod
    );
    //   console.log(outl, 'selected One:');
    let obj = [];
    for (let i = 0; i < prod.length; i++) {
      var splitted = prod[i].split("split", 3);
      obj.push({ productID: splitted[0], name: splitted[1] });
      await console.log(obj, "new array");
      return obj;
    }
  };

  const selectedCategories = async (cate) => {
    setCatName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? cate.split("split") : cate
    );
    //   console.log(outl, 'selected One:');
    let obj = [];
    for (let i = 0; i < cate.length; i++) {
      var splitted = cate[i].split("split", 3);
      obj.push({ categoryID: splitted[0], name: splitted[1] });
      await console.log(obj, "new array");
      return obj;
    }
  };

  // handle click event of the Remove button
  // const handleRemoveClick = index => {
  //     const list = [...discountList];
  //     list.splice(index, 1);
  //     setDiscountList(list);
  // };

  // handle click event of the Add button
  const handleAddClick = () => {
    setDiscountList([
      ...discountList,
      {
        discounType: "",
        pickProducts: [],
        pickCategories: [],
        type: "",
        value: "",
      },
    ]);
  };

  const getAllProducts = () => {
    url.get("/product/all/").then(async (res) => {
      console.log(res, "all products");
      if (res.data.Message === "Success") {
        setProducts(res.data.data);
      } else {
        alert(res.data.data);
      }
    });
  };

  const getAllCategories = () => {
    url.get("/category/").then(async (res) => {
      console.log(res, "all categories");
      if (res.data.Message === "Success") {
        setCategories(res.data.data);
      } else {
        alert(res.data.data);
      }
    });
  };

  useEffect(() => {
    getAllProducts();
    getAllCategories();
    getMembershipFromLocalStorage();
  }, []);

  const getMembershipFromLocalStorage = () => {
    setLevel(membership.level);
    setName(membership.name);
    setAmountSpent(membership.unlocksAtAmountSpent);
  };

  const updateMembership = () => {
    const admin = JSON.parse(localStorage.getItem("Admin"));
    let outlet = [
      {
        outletChainID: admin[0].outletChainID,
        outletName: admin[0].outletName,
      },
    ];
    let body = {
      consumerID: admin[0].consumerId,
      name: name,
      level: level,
      unlocksAt: amountSpent,
      membershipDiscounts: discountList,
      outlets: outlet,
    };
    url.put(`/membership/`, body).then(async (res) => {
      if (res.data.Message === "Success") {
        setMessage({
          text: "Update",
          status: true,
          display: true,
        });
        openTooltip();
        // alert("Membership Updated");
        // history.push("/membership");
      } else {
        // alert(res.data.data);
        setMessage({
          text: "Failed",
          status: res.data.data,
          display: true,
        });
        openTooltip();
      }
    });
  };

  // const deletePopup = () => {
  //   setMessage({
  //     text: "Delete",
  //     status: true,
  //     display: true,
  //   });
  //   openTooltip();
  // };

  return (
    <div>
      <div className="row">
        <div className="col-md-10">
          <div className="card">
            <div>
              <div>
                <h5>Basic Information</h5>
                <br></br>
              </div>
              <form className="row">
                <div className="col-md-7">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Select Level*</label>
                      <select
                        className="form-control"
                        onChange={(e) => setLevel(e.target.value)}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Membership Name</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        id="exampleFormControlInpu1"
                        placeholder="membership name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Unlocks at Amount Spent</label>
                      <input
                        type="number"
                        value={amountSpent}
                        onChange={(e) => setAmountSpent(e.target.value)}
                        className="form-control"
                        id="exampleFormControlInpu1"
                        placeholder="$"
                      />
                    </div>
                  </div>
                </div>
              </form>

              <div>
                <h5>Membership Discounts</h5>
              </div>

              {discountList.map((x, i) => {
                return (
                  <form className="row" key={i}>
                    <div className="col-md-3">
                      <div className="group-comb">
                        <div className="form-group">
                          <label>Discount Type</label>
                          <select
                            className="form-control"
                            name="discounType"
                            onChange={(e) => handleInputChange(e, i)}
                          >
                            <option value="Product Discount">
                              Product Discount
                            </option>
                            <option value="Category Discount">
                              Category Discount
                            </option>
                            <option value="Free Shiping">Free Shiping</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="group-comb">
                        <div className="form-group select-product">
                          <label>
                            {x.discounType === "Product Discount"
                              ? "Pick Products"
                              : "Pick Categories"}
                          </label>
                          {
                            x.discounType === "Product Discount" && (
                              <FormControl sx={{ m: 1, width: 625 }}>
                                <Select
                                  labelId="demo-multiple-chip-label"
                                  id="demo-multiple-chip"
                                  name="pickProducts"
                                  multiple
                                  value={prodName}
                                  onChange={(e) => handleInputChange(e, i)}
                                  input={
                                    <OutlinedInput
                                      id="select-multiple-chip"
                                      label="Select Products"
                                    />
                                  }
                                  renderValue={(selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}
                                    >
                                      {selected.map((value) => (
                                        <Chip
                                          key={value}
                                          label={`${value.split("split")[1]}`}
                                        />
                                      ))}
                                    </Box>
                                  )}
                                  IconComponent={() => (
                                    <KeyboardArrowDownIcon />
                                  )}
                                  MenuProps={MenuProps}
                                >
                                  {products.map((prod, i) => (
                                    <MenuItem
                                      key={i}
                                      value={
                                        prod.productID + "split" + prod.name
                                      }
                                      style={getStyles(prod, prodName, theme)}
                                    >
                                      {prod.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )
                            // <div className="form-group">
                            //     <label>Pick Products</label>
                            //     <option>Select Products</option>
                            //     <select className="form-control" name="pickProducts" onChange={e => handleInputChange(e, i)} >
                            //         {products.map((product, i) => (
                            //             <option value={product.productID} key={i}>{product.name}</option>
                            //         ))}
                            //     </select>
                            // </div>
                          }
                          {
                            x.discounType === "Category Discount" && (
                              <FormControl sx={{ m: 1, width: 625 }}>
                                <Select
                                  labelId="demo-multiple-chip-label"
                                  id="demo-multiple-chip"
                                  multiple
                                  name="pickCategories"
                                  value={catName}
                                  onChange={(e) => handleInputChange(e, i)}
                                  input={
                                    <OutlinedInput
                                      id="select-multiple-chip"
                                      label="Select Outlet (s)"
                                    />
                                  }
                                  renderValue={(selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}
                                    >
                                      {selected.map((value) => (
                                        <Chip
                                          key={value}
                                          label={`${value.split("split")[1]}`}
                                        />
                                      ))}
                                    </Box>
                                  )}
                                  IconComponent={() => (
                                    <KeyboardArrowDownIcon />
                                  )}
                                  MenuProps={MenuProps}
                                >
                                  {categories.map((cate, i) => (
                                    <MenuItem
                                      key={i}
                                      value={
                                        cate.categoryID + "split" + cate.name
                                      }
                                      style={getStyles(cate, catName, theme)}
                                    >
                                      {cate.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )
                            //     <div className="form-group">
                            //         <label>Pick Categories</label>
                            //         <option>Select Categories</option>
                            //         <select className="form-control" name="pickProducts" onChange={e => handleInputChange(e, i)} >
                            //             {categories.map((category, i) => (
                            //                 <option value={category.categoryID} key={i}>{category.name}</option>
                            //             ))}
                            //         </select>
                            //     </div>
                          }
                          {x.discounType === "Free Shiping" && (
                            <input
                              type="text"
                              name="value"
                              disabled
                              value="Free Shiping"
                              onChange={(e) => handleInputChange(e, i)}
                              className="form-control"
                              placeholder="value"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="group-comb">
                        <div className="form-group">
                          <label>Type*</label>
                          <select
                            className="form-control"
                            name="type"
                            onChange={(e) => handleInputChange(e, i)}
                          >
                            <option value="%">%</option>
                            <option value="$">$</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="group-comb">
                        <div className="form-group">
                          <label>Value*</label>
                          <input
                            type="text"
                            name="value"
                            value={x.value}
                            onChange={(e) => handleInputChange(e, i)}
                            className="form-control"
                            placeholder="value"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="btn-box">
                                                {inputList.length !== 1 && <button
                                                    className="mr10"
                                                    onClick={() => handleRemoveClick(i)}>Remove</button>}
                                                {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
                                            </div> */}
                  </form>
                );
              })}
              {/* <div style={{ marginTop: 20 }}>{JSON.stringify(discountList)}</div> */}

              {/* Buttons */}

              <button
                type="button"
                onClick={handleAddClick}
                className="btn btn-outline-primary"
              >
                Add Discount
              </button>
              <br />
              <br />
              <button
                type="button"
                onClick={updateMembership}
                className="btn  btn-cannaby"
              >
                Update
              </button>
              <button
                style={{ marginLeft: "20px", marginTop: "30px" }}
                type="button"
                onClick={() => history.push("/membership")}
                className="btn btn-rnav"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        {message.text === "Update" ? (
          <div style={{ textAlign: "center" }}>
            <i class="fa fa-check"></i>
            <h2 className="popup-heading" style={{ marginTop: "20px" }}>
              Success
            </h2>
            <p>Membership updated successfully</p>
            <button
              className="btn btn-cannaby"
              style={{ marginTop: "0" }}
              onClick={() => {
                closeTooltip();
              }}
            >
              OK
            </button>
          </div>
        ) : message.text === "Failed" ? (
          <div style={{ textAlign: "center" }}>
            <i
              style={{
                color: "green",
                fontSize: "70px",
              }}
              className="fa fa-ban"
            ></i>
            <h2 style={{ marginTop: "20px" }}>Failed</h2>
            <button
              style={{ marginTop: "10px" }}
              type="button"
              onClick={closeTooltip}
              className="btn btn-primary"
            >
              Try Again
            </button>
            <br />
            <br />
            <p>Issue: {message.status}</p>
          </div>
        ) : message.text === "Delete" ? (
          <div style={{ textAlign: "center" }}>
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p className="popup-heading">Do you want to delete this deal ?</p>
            <button
              className="btn btn-cannaby"
              onClick={() => {
                // deleteDeal();
              }}
            >
              Yes
            </button>
            <button
              style={{ marginLeft: "20px", marginTop: "30px" }}
              type="button"
              className="btn popup-cancel-button"
              onClick={closeTooltip}
            >
              No
            </button>
          </div>
        ) : (
          ""
        )}
      </Popup>
    </div>
  );
}
