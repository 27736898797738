import "./inventory.css";
import url from "../../config/axios";
import { useEffect, useState } from "react";
import EditModal from "./EditModal";
import AddModal from "./AddModal";

function InventoryList() {
  const [outlets, setOutlets] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const [minPro, setminPro] = useState("");
  const [page, setPage] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  // const [track, setTrack] = useState(false);
  // const [checkedState, setCheckedState] = useState(null);

  useEffect(() => {
    GetAllOutlets();
  }, []);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    // console.log(adminID[0].adminID);
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const incrementPage = () => {
    if (totalPage < 50) {
    } else {
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const GetAllInventory = (outletID) => {
    // console.log(outletID, 'asdf');
    url.get(`/inventory/?outletChainID=${outletID}`).then(async (res) => {
      console.log("Inventory All:", res.data);
      if (res.data.Message === "Success") {
        // const respPromises = res.data.data.map((data, i) => data.featuredProduct);
        setInventory(res.data.data);
        setTotalPage(res.data.data.length);
        setminPro(page * 50 - 50 + 1);
        // console.log('promise resp', respPromises);
        // setCheckedState(
        //   new Array(res.data.data.length).fill(respPromises)
        // )
      } else {
        alert(res.data.data);
        setInventory([]);
      }
    });
  };

  const updateLocalData = (invent, i) => {
    // const index = inventory.findIndex((inv) => inv.productId === invent.productId);
    let obj = invent;
    const new_obj = { ...obj, featuredProduct: !obj.feafeaturedProduct };
    // console.log(new_obj, 'boj');
    let newArr = [...inventory]; // copying the old datas array
    newArr[i] = new_obj; // replace e.target.value with whatever you want to change it to
    setInventory(newArr);
  };

  const updateInventory = (outletId, productId, featuredOne) => {
    console.log(outletId, productId, featuredOne);
    url
      .put(`/inventory/?productID=${productId}&outletChainID=${outletId}`, {
        featuredProduct: featuredOne,
      })
      .then(async (res) => {
        console.log("updated", res.data);
        if (res.data.Message === "Success") {
          GetAllInventory(outletId);
        } else {
          alert(res.data.data);
        }
      });
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          <button
            className="btn btn-primary btn-pro mr-5"
            onClick={() => {
              setOpenAddModal(true);
            }}
          >
            <i className="fas fa-plus-circle"></i>
            Add Product
          </button>
          {/* onClick={() => {
              history.push("/addCatalog");
            }} */}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <div className="group-comb">
                    <div className="input-group">
                      <select
                        className="form-control"
                        onChange={(e) => GetAllInventory(e.target.value)}
                        id="exampleFormControlSelect1"
                      >
                        <option value="asdf">Select Outlet</option>
                        {outlets.map((outlet, i) => {
                          return (
                            <option value={outlet.outletChainID} key={i}>
                              {outlet.outletName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      placeholder="Search Product"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  <span>
                    {minPro}-{inventory.length + minPro - 1} of{" "}
                    {totalPage ? totalPage : 0}
                  </span>
                  <div
                    className="btn cannaby-light"
                    onClick={() => decrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-left"></i>
                  </div>
                  <div
                    className="btn cannaby-light"
                    onClick={() => incrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Stock</th>
                    <th scope="col">Price/Item</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Category</th>
                    <th scope="col">Featured Product</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {inventory.map((inv, index) => {
                    // console.log(inv);
                    return (
                      <tr key={index}>
                        <td colSpan="6">
                          <img
                            alt=""
                            src={inv.product.image}
                            className="img-fluid"
                            width="40"
                          />
                          {Math.floor(inv.product.name).toFixed(0)}
                        </td>
                        <td>{inv.quantity}</td>
                        <td>{inv.originalPrice}</td>
                        <td>{inv.product.brandName}</td>
                        <td className="">
                          <span>
                            {inv.product.category.length &&
                              inv.product.category[0].name}
                          </span>
                        </td>
                        <td>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={inv.featuredProduct}
                              onChange={() => {
                                updateLocalData(inv, index);
                                updateInventory(
                                  inv.outletChainID,
                                  inv.productID,
                                  !inv.featuredProduct
                                );
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td className="status">
                          {inv.status ? (
                            <span>Not Published</span>
                          ) : (
                            <span>Published</span>
                          )}
                        </td>
                        <td>
                          <i
                            className="fas fa-pen"
                            onClick={() => {
                              setSelectedProduct(inv);
                              setOpenModal(true);
                            }}
                          ></i>
                          {/* onClick={() => moveToEdit(product)} */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <EditModal
          closeModal={setOpenModal}
          product={selectedProduct}
          GetAllInventory={GetAllInventory}
        />
      )}
      {openAddModal && (
        <AddModal
          closeAddModal={setOpenAddModal}
          GetAllInventory={GetAllInventory}
        />
      )}
    </div>
  );
}

export default InventoryList;
