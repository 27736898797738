/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import url from "../../config/axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function Setting() {
  const history = useHistory();
  // const [banners, setBanners] = useState([]);
  // const [totalPage, setTotalPage] = useState("");
  // const [minPro, setminPro] = useState("");

  const [outlets, setOutlets] = useState([]);

  const admin = JSON.parse(localStorage.getItem("Admin"));

  // const incrementPage = () => {
  //   if (totalPage < 50) {
  //   } else {
  //     setPage(page + 1);
  //   }
  // };

  // const decrementPage = () => {
  //   if (page > 1) {
  //     setPage(page - 1);
  //   }
  // };

  useEffect(() => {
    GetAllOutlets();
  }, []);

  const GetAllOutlets = () => {
    url
      .get(`/oc/?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          {!admin[0].isSubOutlet && (
            <button
              className="btn btn-primary btn-pro mr-5"
              onClick={() => {
                history.push("/add-setting");
              }}
            >
              <i className="fas fa-plus-circle"></i>
              Add Settings
            </button>
          )}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  {/* {admin[0].isSubOutlet && (
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control table-search"
                        placeholder="Search Product"
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                      />
                      <div className="input-group-prepend">
                        <div
                          className="input-group-text table-search-icon"
                          id="btnGroupAddon"
                        >
                          <i className="fas fa-search"></i>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="col-md-3">
                  {/* <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      placeholder="Search Product"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text table-search-icon" id="btnGroupAddon">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  {/* <span>
                    {minPro}-{banners.length + minPro - 1} of{" "}
                    {totalPage ? totalPage : 0}
                  </span> */}
                  {/* <div
                    className="btn cannaby-light"
                    onClick={() => decrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-left"></i>
                  </div>
                  <div
                    className="btn cannaby-light"
                    onClick={() => incrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-right"></i>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Outlet Name</th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Admin Name</th>
                    <th scope="col">Admin Email</th>
                    <th scope="col">Assessment Fee</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {outlets.map((outlet, i) => {
                    //   console.log(coupon, 'coupon:');
                    return (
                      <tr key={i}>
                        <td colSpan="4">{outlet.outletName}</td>
                        <td>{outlet.adminName}</td>
                        <td>
                          {outlet.adminEmail}
                          <br />
                        </td>
                        <td>
                          <span>{outlet.assessmentFee} </span>
                        </td>
                        <td className="status">
                          <span>
                            <i
                              onClick={() =>
                                history.push(
                                  `add-setting?outletId=${outlet.outletChainID}`
                                )
                              }
                              className="fa fa-pen"
                            ></i>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
