// eslint-disable-line react-hooks/exhaustive-deps

import React, { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddMembership() {
  const [level, setLevel] = useState("1");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [amountSpent, setAmountSpent] = useState("0");
  const [validationMessage, setValidationMessage] = useState({
    name: "",
    amount: "",
  });
  const [discountList, setDiscountList] = useState([
    {
      discounType: "Product Discount",
      pickProducts: [],
      pickCategories: [],
      type: "%",
      value: "0",
      prodNamedummy: [],
      categoryNameDummy: [],
    },
  ]);
  const theme = useTheme();
  const history = useHistory();
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => ref.current.close();

  // handle input change
  const handleInputChange = (e, i) => {
    if (["discounType", "type", "value"].includes(e.target.name)) {
      // console.log("not working");
      let discountDetails = [...discountList];
      if (e.target.name === "type") {
        discountDetails[i]["value"] = "0";
        discountDetails[i][e.target.name] = e.target.value;
      } else if (e.target.name === "value") {
        let val = Math.abs(e.target.value);
        if (discountDetails[i].type === "%") {
          if (val < 100) {
            discountDetails[i]["value"] = parseInt(val) + "";
          } else {
            discountDetails[i]["value"] = "0";
          }
        } else {
          discountDetails[i]["value"] = val + "";
        }
      } else {
        discountDetails[i][e.target.name] = e.target.value;
      }
      setDiscountList(discountDetails);
    } else {
      if (discountList[i].discounType === "Product Discount") {
        // console.log("working 1", i);
        let dummy = [...discountList];
        let data =
          typeof value === "string"
            ? e.target.value.split("split")
            : e.target.value;
        dummy[i].prodNamedummy = data;
        setDiscountList(dummy);
        // selectedProducts(e.target.value, i);
      } else if (discountList[i].discounType === "Category Discount") {
        // console.log("working 2", i);
        let dummy = [...discountList];
        let data =
          typeof value === "string"
            ? e.target.value.split("split")
            : e.target.value;
        dummy[i].categoryNameDummy = data;
        setDiscountList(dummy);
        // selectedCategories(e.target.value, i);
      }
    }
    // console.log(discountList[0], 'type;', e.target.value);
  };

  // const selectedProducts = async (prod, index) => {
  //   //   console.log(outl, 'selected One:');
  //   let obj = [];
  //   for (let i = 0; i < prod.length; i++) {
  //     var splitted = prod[i].split("split", 3);
  //     obj.push({ productID: splitted[0], name: splitted[1] });
  //     await console.log(obj, "new array");
  //     let discountDetails = [...discountList];
  //     discountDetails[index].pickProducts = await obj;
  //     await setDiscountList(discountDetails);
  //   }
  // };

  // const selectedCategories = async (cate, index) => {
  //   //   console.log(outl, 'selected One:');
  //   let obj = [];
  //   for (let i = 0; i < cate.length; i++) {
  //     var splitted = cate[i].split("split", 3);
  //     obj.push({ categoryID: splitted[0], name: splitted[1] });
  //     await console.log(obj, "new array");
  //     let discountDetails = [...discountList];
  //     discountDetails[index].pickCategories = await obj;
  //     setDiscountList(discountDetails);
  //   }
  // };

  // handle click event of the Remove button
  // const handleRemoveClick = index => {
  //     const list = [...discountList];
  //     list.splice(index, 1);
  //     setDiscountList(list);
  // };

  // handle click event of the Add button
  const handleAddClick = () => {
    setDiscountList([
      ...discountList,
      {
        discounType: "Product Discount",
        pickProducts: [],
        pickCategories: [],
        type: "",
        value: "",
        prodNamedummy: [],
        categoryNameDummy: [],
      },
    ]);
  };
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  const getAllProducts = () => {
    url
      .get(`/inventory/?outletChainID=${adminID[0].outletChainID}`)
      .then(async (res) => {
        // console.log(res, 'all products')
        if (res.data.Message === "Success") {
          setProducts(res.data.data.slice(0, 100));
        } else {
          alert(res.data.data);
        }
      });
  }; // eslint-disable-line react-hooks/exhaustive-deps

  const getAllCategories = () => {
    url.get("/category/").then(async (res) => {
      // console.log(res, 'all categories')
      if (res.data.Message === "Success") {
        setCategories(res.data.data);
      } else {
        alert(res.data.data);
      }
    });
  };

  useEffect(() => {
    getAllProducts();
    getAllCategories();
    //eslint-disable-next-line
  }, []);

  const addMembership = () => {
    const admin = JSON.parse(localStorage.getItem("Admin"));
    let outlet = [
      {
        outletChainID: admin[0].outletChainID,
        outletName: admin[0].outletName,
      },
    ];

    if (name === "") {
      setValidationMessage({ name: "Membership name is required" });
    } else if (amountSpent === "") {
      setValidationMessage({ amount: "Membership name is required" });
    } else if (
      discountList[0].pickProducts.length === undefined ||
      discountList[0].value === "" ||
      discountList[0].type === ""
    ) {
      alert("Select Discount Options");
    } else {
      let body = {
        consumerID: admin[0].consumerId,
        name: name,
        level: level,
        unlocksAt: amountSpent,
        membershipDiscounts: discountList.reduce((acc, obj) => {
          let tempObj = { ...obj };
          tempObj.pickCategories =
            tempObj.discounType === "Category Discount"
              ? tempObj.categoryNameDummy?.map((obj) => {
                  let splitted = obj.split("split");
                  return {
                    categoryID: splitted[0],
                    name: splitted[1],
                  };
                })
              : [];
          tempObj.pickProducts =
            tempObj.discounType === "Product Discount"
              ? tempObj.prodNamedummy?.map((obj) => {
                  let splitted = obj.split("split");
                  return {
                    productID: splitted[0],
                    name: splitted[1],
                  };
                })
              : [];
          return [...acc, tempObj];
        }, []),
        outlets: outlet,
      };
      // console.log("Body is: ", body);
      url.post(`/membership/`, body).then(async (res) => {
        if (res.data.Message === "Success") {
          setMessage({
            text: "Success",
            status: true,
            display: true,
          });
          openTooltip();
          // alert("Membership Added");
          // history.push("/membership");
        } else {
          setMessage({
            text: "Failed",
            status: true,
            display: true,
          });
          openTooltip();
        }
      });
    }
  };

  const [formValidationErrors, setFormValidationErrors] = useState({});
  const validateForm = () => {
    let data = { ...formValidationErrors };
    //validate deal name: dealError
    if (name.trim().length === 0) {
      data = {
        ...data,
        membershipName: "Name of the membership is required is required",
      };
    } else {
      let { membershipName, ...others } = data;
      data = { ...others };
    }
    //manipulateDiscounts
    let tempDiscError = { ...(data.discError ?? {}) };
    for (let i = 0; i < discountList.length; i++) {
      let tempError = { ...(tempDiscError[i] ?? {}) };
      let obj = discountList[i];
      if (obj?.discounType === "Product Discount") {
        if (obj?.prodNamedummy?.length === 0) {
          tempError = {
            ...tempError,
            selectError: "Select at least one product",
          };
        } else {
          let { selectError, ...others } = tempError;
          tempError = { ...others };
        }
      } else if (obj?.discounType === "Category Discount") {
        if (obj?.categoryNameDummy?.length === 0) {
          tempError = {
            ...tempError,
            selectError: "Select at least one category",
          };
        } else {
          let { selectError, ...others } = tempError;
          tempError = { ...others };
        }
      } else {
        let { selectError, ...others } = tempError;
        tempError = { ...others };
      }
      if (!!Object.keys(tempError).length) {
        tempDiscError[i] = tempError;
      } else {
        let { [i]: id, ...others } = tempDiscError;
        tempDiscError = { ...others };
      }
    }
    if (!!Object.keys(tempDiscError).length) {
      data = { ...data, discError: tempDiscError };
    } else {
      let { discError, ...others } = data;
      data = { ...others };
    }
    console.log("Validation logs are: ", data);
    setFormValidationErrors({ ...data });
  };
  useEffect(() => {
    validateForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, discountList]);

  return (
    <div>
      <div className="row">
        <div className="col-md-10">
          <div className="card">
            <div>
              <div>
                <h5>Membership Details</h5>
                <br></br>
              </div>
              <form className="row">
                <div className="col-md-7">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Select Level*</label>
                      <select
                        className="form-control"
                        onChange={(e) => setLevel(e.target.value)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Membership Name*</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        id="exampleFormControlInpu1"
                        placeholder="membership name"
                      />
                      {!!formValidationErrors.membershipName && (
                        <span className="emsg">
                          {formValidationErrors.membershipName}
                        </span>
                      )}
                    </div>
                    {validationMessage.name ? (
                      <span className="validation-help">
                        {validationMessage.name}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Unlocks at Amount Spent*</label>
                      <input
                        type="number"
                        value={amountSpent}
                        onChange={(e) =>
                          setAmountSpent(Math.abs(e.target.value) + "")
                        }
                        className="form-control"
                        id="exampleFormControlInpu1"
                        placeholder="$"
                      />
                    </div>
                    {validationMessage.amount ? (
                      <span className="validation-help">
                        {validationMessage.amount}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </form>
              <br />
              <div>
                <h5>Membership Discounts</h5>
              </div>

              {discountList.map((x, i) => {
                let { discError: tempDiscError } = formValidationErrors;
                let { [i]: tempErrObj } = tempDiscError ?? {};
                return (
                  <form className="row" key={i}>
                    <div className="col-md-3">
                      <div className="group-comb">
                        <div className="form-group">
                          <label>Discount Type</label>
                          <select
                            className="form-control"
                            name="discounType"
                            onChange={(e) => handleInputChange(e, i)}
                            required>
                            <option value="Product Discount">
                              Product Discount
                            </option>
                            <option value="Category Discount">
                              Category Discount
                            </option>
                            <option value="Free Shiping">Free Shiping</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="group-comb">
                        <div className="form-group select-product">
                          <label>
                            {x.discounType === "Product Discount"
                              ? "Pick Products"
                              : "Pick Categories"}
                          </label>
                          {
                            x.discounType === "Product Discount" && (
                              <FormControl sx={{ m: 1, width: 625 }}>
                                <Select
                                  required
                                  labelId="demo-multiple-chip-label"
                                  id="demo-multiple-chip"
                                  name="pickProducts"
                                  multiple
                                  value={x.prodNamedummy}
                                  onChange={(e) => handleInputChange(e, i)}
                                  input={
                                    <OutlinedInput
                                      id="select-multiple-chip"
                                      label="Select Products"
                                    />
                                  }
                                  renderValue={(selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}>
                                      {selected.map((value) => {
                                        return (
                                          <Chip
                                            key={value}
                                            label={`${value.split("split")[1]}`}
                                          />
                                        );
                                      })}
                                    </Box>
                                  )}
                                  IconComponent={() => (
                                    <KeyboardArrowDownIcon />
                                  )}
                                  MenuProps={MenuProps}>
                                  {products.map((obj, i) => {
                                    const prod = obj.product;
                                    return (
                                      <MenuItem
                                        key={i}
                                        value={
                                          prod.productID + "split" + prod.name
                                        }
                                        style={getStyles(
                                          prod,
                                          x.prodNamedummy,
                                          theme
                                        )}>
                                        {prod.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            )
                            // <div className="form-group">
                            //     <label>Pick Products</label>
                            //     <option>Select Products</option>
                            //     <select className="form-control" name="pickProducts" onChange={e => handleInputChange(e, i)} >
                            //         {products.map((product, i) => (
                            //             <option value={product.productID} key={i}>{product.name}</option>
                            //         ))}
                            //     </select>
                            // </div>
                          }
                          {
                            x.discounType === "Category Discount" && (
                              <FormControl sx={{ m: 1, width: 625 }}>
                                <Select
                                  required
                                  labelId="demo-multiple-chip-label"
                                  id="demo-multiple-chip"
                                  multiple
                                  name="pickCategories"
                                  value={x.categoryNameDummy}
                                  onChange={(e) => handleInputChange(e, i)}
                                  input={
                                    <OutlinedInput
                                      id="select-multiple-chip"
                                      label="Select Outlet (s)"
                                    />
                                  }
                                  renderValue={(selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}>
                                      {selected.map((value) => (
                                        <Chip
                                          key={value}
                                          label={`${value.split("split")[1]}`}
                                        />
                                      ))}
                                    </Box>
                                  )}
                                  IconComponent={() => (
                                    <KeyboardArrowDownIcon />
                                  )}
                                  MenuProps={MenuProps}>
                                  {categories.map((cate, i) => (
                                    <MenuItem
                                      key={i}
                                      value={
                                        cate.categoryID + "split" + cate.name
                                      }
                                      style={getStyles(
                                        cate,
                                        x.categoryNameDummy,
                                        theme
                                      )}>
                                      {cate.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )
                            //     <div className="form-group">
                            //         <label>Pick Categories</label>
                            //         <option>Select Categories</option>
                            //         <select className="form-control" name="pickProducts" onChange={e => handleInputChange(e, i)} >
                            //             {categories.map((category, i) => (
                            //                 <option value={category.categoryID} key={i}>{category.name}</option>
                            //             ))}
                            //         </select>
                            //     </div>
                          }
                          {x.discounType === "Free Shiping" && (
                            <input
                              type="text"
                              name="value"
                              disabled
                              value="Free Shiping"
                              onChange={(e) => handleInputChange(e, i)}
                              className="form-control"
                              placeholder="value"
                              required
                            />
                          )}
                        </div>
                        {!!tempErrObj?.selectError?.length ? (
                          <span className="emsg">
                            {tempErrObj?.selectError}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="group-comb">
                        <div className="form-group">
                          <label>Type</label>
                          <select
                            className="form-control"
                            name="type"
                            onChange={(e) => handleInputChange(e, i)}
                            required>
                            <option value="%">%</option>
                            <option value="$">$</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="group-comb">
                        <div className="form-group">
                          <label>Value</label>
                          <input
                            type="text"
                            name="value"
                            value={x.value}
                            onChange={(e) => handleInputChange(e, i)}
                            className="form-control"
                            placeholder="value"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="btn-box">
                                                {inputList.length !== 1 && <button
                                                    className="mr10"
                                                    onClick={() => handleRemoveClick(i)}>Remove</button>}
                                                {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
                                            </div> */}
                  </form>
                );
              })}
              {/* <div style={{ marginTop: 20 }}>{JSON.stringify(discountList)}</div> */}

              {/* Buttons */}
              <button
                type="button"
                onSubmit={handleAddClick}
                className="btn btn-outline-cannaby">
                Add Discount
              </button>
              <br />
              <br />

              <button
                type="button"
                disabled={Object.keys(formValidationErrors).length !== 0}
                onClick={addMembership}
                className="btn  btn-cannaby">
                <i className="fa fa-plus invert"></i>Create Membership
              </button>
              <button
                style={{ marginLeft: "20px", marginTop: "30px" }}
                type="button"
                onClick={() => history.push("/membership")}
                className="btn btn-rnav">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>{" "}
      <Popup ref={ref} position="center">
        {message.status ? (
          <div style={{ textAlign: "center" }}>
            <i
              // style={{
              //   color: "green",
              //   fontSize: "60px",
              //   border: "10px solid green",
              //   borderRadius: "50px",
              //   padding: "10px",
              // }}
              class="fa fa-check"
            ></i>
            <h2 className="popup-heading" style={{ marginTop: "20px" }}>
              Success
            </h2>
            <p>Membership added successfully</p>
            <button
              className="btn btn-cannaby"
              style={{ marginTop: "0" }}
              onClick={() => {
                closeTooltip();
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <i
              style={{
                color: "green",
                fontSize: "70px",
              }}
              className="fa fa-ban"
            ></i>
            <h2 style={{ marginTop: "20px" }}>Failed</h2>
            <button
              style={{ marginTop: "10px" }}
              type="button"
              onClick={closeTooltip}
              className="btn btn-primary"
            >
              Try Again
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}
