/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import url from "../../config/axios";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import "./banner.css";

export default function Index() {
  const history = useHistory();
  const [banners, setBanners] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  // const [minPro, setminPro] = useState("");
  // const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [outlets, setOutlets] = useState([]);
  const [bannerId, setBannerId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  var perPage = 10;

  const admin = JSON.parse(localStorage.getItem("Admin"));
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => ref.current.close();
  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    setSearchTerm("");
  };

  const incrementPage = () => {
    // if (totalPage < 5) {
    // } else {
    //   setPage(page + 1);
    // }
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const decrementPage = () => {
    // if (page > 1) {
    //   setPage(page - 1);
    // }
    if (currentPage >= 2) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if (admin[0].isSubOutlet) {
      GetAllBanners();
    } else {
      GetAllOutlets();
    }
  }, []);
  //search
  useEffect(() => {
    if (searchTerm.trim().length !== 0) {
      let filtered = unfiltered.filter((el) =>
        (el.title + "").toLowerCase().includes(searchTerm.toLowerCase())
      );
      setBanners(filtered);
      setTotalPage(Math.ceil(filtered.length / perPage));
      setCurrentPage(1);
      if (!filtered.length) {
        openNotFoundPopUp();
      }
    } else {
      setBanners(unfiltered);
      setTotalPage(Math.ceil(unfiltered.length / perPage));
      setCurrentPage(1);
    }
  }, [searchTerm]);
  const GetAllBanners = () => {
    // console.log(adminID[0].adminID);
    url
      .get(`/banner/?outletChainID=${admin[0].outletChainID}&isAdmin=1`)
      .then(async (res) => {
        console.log("respO", res);
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
            openNotFoundPopUp();
          }
          setUnfiltered(res.data.data);
          setBanners(res.data.data);
          setTotalPage(Math.ceil(res.data.data.length / perPage));
          // setminPro(page * 5 - 5 + 1);
        } else {
          openNotFoundPopUp();
        }
      })
      .catch((e) => openNotFoundPopUp());
  };

  const deleteBanner = (bannerId) => {
    url
      .delete(`/banner/?bannerId=${bannerId}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          GetAllBanners();
          closeTooltip();
        } else {
          // alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetBannersByOutlet = (id) => {
    url
      .get(`/banner/?outletChainID=${id}&isAdmin=1`)
      .then(async (res) => {
        // console.log("resi", res);
        if (res.data.Message === "Success") {
          if (res.data.data.length === 0) {
            // alert("No banner found");
            openTooltip();
          } else {
            // setBanners(res.data.data);
            // setTotalPage(res.data.data.length);
            // setminPro(page * 50 - 50 + 1);
            setUnfiltered(res.data.data);
            setBanners(res.data.data);
            setTotalPage(Math.ceil(res.data.data.length / perPage));
          }
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetAllOutlets = () => {
    url
      .get(`/oc/?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const editBanner = (data) => {
    localStorage.setItem("editBanner", JSON.stringify(data));
    history.push("/edit-banner");
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          {/* <button
            className="btn btn-primary btn-pro mr-5"
            onClick={() => {
              history.push("/add-banner");
            }}>
            <i className="fas fa-plus-circle"></i>
            Add Banner
          </button> */}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      placeholder="Search banners by title"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  {!admin[0].isSubOutlet && (
                    <div className="group-comb">
                      <div className="input-group">
                        <select
                          className="form-control"
                          onChange={(e) => GetBannersByOutlet(e.target.value)}
                          id="exampleFormControlSelect1">
                          <option value="select">Select Outlet</option>
                          {outlets.map((outlet, i) => {
                            return (
                              <option value={outlet.outletChainID} key={i}>
                                {outlet.outletName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  )}
                  {/* 
                  {admin[0].isSubOutlet && (
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control table-search"
                        placeholder="Search Product"
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                      />
                      <div className="input-group-prepend">
                        <div
                          className="input-group-text table-search-icon"
                          id="btnGroupAddon"
                        >
                          <i className="fas fa-search"></i>
                        </div>
                      </div>
                    </div>
                  )} */}
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      history.push("/add-banner");
                    }}>
                    <i className="fas fa-plus-circle"></i>
                    Add Banner
                  </button>
                </div>
                <div
                  // hidden for col-md-o and
                  className="col-md-0 filters-bar">
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                {!!banners.length && (
                  <div className="col-md-4 pagination">
                    <span>
                      {`${perPage * (currentPage - 1) + 1} - ${
                        perPage * (currentPage - 1) +
                        banners.slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        ).length
                      } of ${banners?.length ?? 0}`}
                    </span>
                    <div
                      className="btn cannaby-light"
                      onClick={() => decrementPage()}
                      role="button">
                      <i className="fas fa-chevron-left"></i>
                    </div>
                    <div
                      className="btn cannaby-light"
                      onClick={() => incrementPage()}
                      role="button">
                      <i className="fas fa-chevron-right"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Banner Image</th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    {/* <th scope="col">Link</th> */}
                    <th scope="col">Title</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {banners.length === 0 ? (
                    <>
                      <tr>
                        <td colSpan="12" style={{ textAlign: "center" }}>
                          No Banners found
                        </td>
                      </tr>
                    </>
                  ) : (
                    banners
                      .slice((currentPage - 1) * perPage, currentPage * perPage)
                      .map((banner, i) => {
                        //   console.log(coupon, 'coupon:');
                        return (
                          <tr key={i}>
                            <td colSpan="4">
                              {!!banner.link ? (
                                <a href={`${banner.link}`} target="blank">
                                  <img
                                    src={banner.image}
                                    style={{ width: "220px", height: "120px" }}
                                    alt="banner"
                                  />
                                </a>
                              ) : (
                                <img
                                  src={banner.image}
                                  style={{ width: "220px", height: "120px" }}
                                  alt="banner"
                                />
                              )}
                            </td>
                            {/* <td>
                                {!!banner.link
                                  ? `${(banner.link + "").substring(0, 41)}${(banner.link + "").length > 40 ? "..." : ""
                                  }`
                                  : "none"}
                              </td> */}
                            <td>
                              {`${(banner.title + "").substring(0, 41)}${
                                (banner.title + "").length > 40 ? "..." : ""
                              }`}
                              <br />
                            </td>
                            <td className="status">
                              {banner.status ? (
                                <span>Published</span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  Not Published
                                </span>
                              )}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "2em",
                                  flexWrap: "wrap",
                                }}>
                                <i
                                  className="fa fa-pen"
                                  onClick={() => editBanner(banner)}></i>
                                <i
                                  className="fa fa-trash-alt"
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setBannerId(banner.bannerId);
                                    openTooltip();
                                  }}></i>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <p className="popup-heading">Do you want to delete this banner ?</p>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              deleteBanner(bannerId);
            }}>
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeTooltip}>
            No
          </button>
        </div>
      </Popup>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Banners not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={closeNotFoundPopUp}>
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
