/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from "react";
import url from "../../config/axios";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import InputLabel from "@mui/material/InputLabel";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import getCroppedImg from "../../helpers/cropImage";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import storage from "../../config/firebase.js";
import { addDays, subDays } from "date-fns";
import Popup from "reactjs-popup";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddDeal() {
  const history = useHistory();
  const [activeStatus, setActiveStatus] = useState(false);
  const [applyToAll, setApplyToAll] = useState(false);
  const [dealName, setDealName] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [outlets, setOutlets] = useState([]);
  const [selOutlet, setSelOutlet] = useState([]);
  const [selProduct, setSelProduct] = useState([]);
  const [selCategory, setSelCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [appliesTo, setAppliesTo] = useState("Selected Products");
  const [neverExpires, setNeverExpires] = useState(false);
  const [specificDates, setSpecificDates] = useState(true);
  const theme = useTheme();
  const [inProgress, setInProgress] = useState(false);
  const [file, setFile] = useState("");
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [success, setSuccess] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));
  const adminID = JSON.parse(localStorage.getItem("Admin"));
  const [triedBefore, setTriedbefore] = useState(false);

  const [outName, setOutName] = useState([]);
  const [prodName, setProdName] = useState([]);
  const [catName, setCatName] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [validationMessages, setvalidationMessages] = React.useState({
    name: "",
    discountValue: "",
  });
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    if (!success) {
      ref.current.close();
    } else {
      history.push("/deals");
    }
  };
  // const [isFormValid, setIsFormValid] = useState(false);

  // const [search, setSearch] = useState("");

  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    GetAllOutlets();
    GetAllProducts();
    GetAllCategories();
  }, []);

  const handleChange = (newValue) => {
    setStartDate(newValue);
  };

  const handleChange1 = (newValue) => {
    setEndDate(newValue);
  };

  const GetAllOutlets = () => {
    // console.log(adminID, 'admina');
    // console.log(adminID.isSubOutlet, 'check');
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        // console.log('All Outlets', res.data.data)
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          // alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetAllProducts = () => {
    url
      .get(
        `/v1/deals/possible-products?outletChainID=${adminID[0].outletChainID}`
      )
      .then(async (res) => {
        // console.log("Products", res.data.data);
        if (res.data.Message === "Success") {
          //   setProducts(res.data.data.slice(0, 100));
          setProducts(res.data.data);
        } else {
          alert(res.data.data);
          setProducts([]);
        }
      });
  };

  // const SearchProducts = () => {
  //     url.get(`/inventory/search/?outletChainID=${adminID.outletChainID}&name=${search}`)
  // }

  const GetAllCategories = () => {
    url
      .get(`/v1/deals/possible-categories`)
      .then(async (res) => {
        console.log("Categories", res.data.data);
        if (res.data.Message === "Success") {
          setCategories(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
        console.log("donee", { croppedImage });
        localStorage.setItem("ImageSet", croppedImage);
        setImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, image]
  );

  const fileUpload = () => {
    setTriedbefore(true);
    let errors = validateForm() ?? {};
    if (Object.keys(errors).length === 0) {
      setInProgress(true);
      try {
        // const storageRef = ref(storage, `images/${file.name}`);
        const uploadTask = storage
          .ref(`images/${file.name}`)
          .putString(localStorage.getItem("ImageSet"), "data_url");
        if (file !== "") {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // const progress = Math.round(
              //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              // );
            },
            (error) => {
              console.log(error);
            },
            () => {
              storage
                .ref("images")
                .child(file.name)
                .getDownloadURL()
                .then(async (blob) => {
                  console.log(blob);
                  await addDeal(blob);
                });
            }
          );
        } else {
          addDeal("");
        }
      } catch (e) {
        addDeal("");
      }
    }
  };

  const selectedOutlets = async (outl) => {
    setOutName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? outl.split("split") : outl
    );
    //   console.log(outl, 'selected One:');
    let obj = [];
    for (let i = 0; i < outl.length; i++) {
      var splitted = outl[i].split("split", 3);
      obj.push({ outletChainID: splitted[0], name: splitted[1] });
      await console.log(obj, "new array");
      await setSelOutlet(obj);
    }
    // console.log(effect, 'effects');
  };

  const selectedProducts = async (prod) => {
    setProdName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? prod.split("split") : prod
    );
    //   console.log(outl, 'selected One:');
    let obj = [];
    for (let i = 0; i < prod.length; i++) {
      var splitted = prod[i].split("split", 3);
      obj.push({ productID: splitted[0], name: splitted[1] });
    }
    await setSelProduct(obj);
  };

  const selectedCategories = async (cate) => {
    setCatName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? cate.split("split") : cate
    );
    //   console.log(outl, 'selected One:');
    let obj = [];
    for (let i = 0; i < cate.length; i++) {
      var splitted = cate[i].split("split", 3);
      obj.push({ categoryID: splitted[0], name: splitted[1] });
    }
    await setSelCategory(obj);
  };

  const setSelectedOne = (value) => {
    setAppliesTo(value);
  };

  const addDeal = (blob) => {
    if (dealName === "") {
      setvalidationMessages({ name: "Deal name is required" });
    } else if (discountValue === "") {
      setvalidationMessages({ discountValue: "Discount value is required" });
    } else {
      const admin = JSON.parse(localStorage.getItem("Admin"));
      console.log(!adminID[0].isSubOutlet);
      const selectedOne = adminID[0].isSubOutlet
        ? [
            {
              outletChainID: adminID[0].outletChainID,
              name: adminID[0].outletName,
            },
          ]
        : selOutlet;
      let body = {
        consumerId: admin[0].consumerId,
        name: dealName,
        image: blob,
        selectedOutlets: selectedOne,
        discountValue: discountValue,
        selectedProducts: appliesTo === "Selected Products" ? selProduct : [],
        selectedCategories: !(appliesTo === "Selected Products")
          ? selCategory
          : [],
        applyToAllOutlets: applyToAll,
        startDate: startDate,
        endDate: endDate,
        neverExpires: neverExpires,
        status: activeStatus,
      };
      //sanity
      Object.keys(body).forEach((el) => {
        if (!el) {
          delete body[el];
        }
      });
      // console.log(selectedOne, "body");
      url.patch(`/v1/deals`, body).then(async (res) => {
        // console.log(res, 'deal add:')
        if (res.data.Message === "Success") {
          // alert("Product Added");
          // adminID[0].isSubOutlet
          //   ? history.push("/deals-outlet")
          //   : history.push("/deals");
          setMessage({
            text: "Success",
            status: true,
            display: true,
          });
          setSuccess(true);
          openTooltip();
          // history.push("/banner");
        } else {
          setMessage({
            text: "Failed",
            status: true,
            display: true,
          });
          openTooltip();
        }
        setInProgress(false);
      });
    }
  };
  const [dateRelatedErrors, setDateRelatedErrors] = useState({});
  useEffect(() => {
    // console.log("**********Incoming", { startDate, endDate });
    let data = { ...dateRelatedErrors };
    //manipualte start date
    if (new Date(startDate).toString() === "Invalid Date") {
      data = { ...data, start: "Invalid date" };
    } else if (new Date(startDate) < subDays(new Date(), 1)) {
      data = { ...data, start: "Must be a date today or in the future" };
    } else if (new Date(startDate) > new Date(endDate)) {
      data = { ...data, start: "Happening after the date of being end" };
    } else {
      const { start, ...others } = data;
      data = { ...others };
    }
    //manipulate end date
    if (new Date(endDate).toString() === "Invalid Date") {
      data = { ...data, end: "Invalid date" };
    } else if (new Date(startDate) > new Date(endDate)) {
      data = { ...data, end: "Happening before the date of being started" };
    } else {
      let { end, ...others } = data;
      data = { ...others };
    }
    // console.log("*************Manipulated date is: ", data);
    setDateRelatedErrors({ ...data });
  }, [startDate, endDate]);

  const [formValidationErrors, setFormValidationErrors] = useState({});
  const validateForm = () => {
    let data = { ...formValidationErrors };
    //validate deal name: dealError
    if (dealName.trim().length === 0) {
      data = { ...data, dealError: "Name of the deal is required" };
    } else {
      let { dealError, ...others } = data;
      data = { ...others };
    }
    //validate discount value: discountError
    if (discountValue.trim().length === 0) {
      data = { ...data, discountError: "Discount value is required" };
    } else if (discountValue <= 0 || discountValue >= 100) {
      data = {
        ...data,
        discountError: "Please a choose a postive value within 100",
      };
    } else {
      let { discountError, ...others } = data;
      data = { ...others };
    }
    if (appliesTo === "Selected Products") {
      if (selProduct.length === 0) {
        data = { ...data, selectError: "Select at least one product" };
      } else {
        let { selectError, ...others } = data;
        data = { ...others };
      }
    } else {
      if (selCategory.length === 0) {
        data = { ...data, selectError: "Select at least one category" };
      } else {
        let { selectError, ...others } = data;
        data = { ...others };
      }
    }
    setFormValidationErrors({ ...data });
    return data;
  };
  useEffect(() => {
    if (triedBefore) {
      validateForm();
    }
  }, [
    discountValue,
    dealName,
    selCategory,
    selProduct,
    appliesTo,
    triedBefore,
  ]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="col-md-12">
              {!adminID[0].isSubOutlet && (
                <div>
                  <h5>Applies to all outlets?</h5>
                  <br></br>
                </div>
              )}
              <form className="row">
                {!adminID[0].isSubOutlet && (
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-3 switch-col">
                        <label className="switch switch-demo">
                          <input
                            type="checkbox"
                            checked={applyToAll}
                            onChange={() => setApplyToAll(!applyToAll)}
                          />
                          <span className="slider round"></span>
                          <span style={{ paddingLeft: "81px" }}>
                            {applyToAll ? "Yes" : "No"}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-9 group-comb">
                        <div className="form-group">
                          <label>Select Outlet (s)</label>
                          <FormControl sx={{ m: 1, width: 490 }}>
                            <InputLabel id="demo-multiple-chip-label">
                              Select Outlet(s)
                            </InputLabel>

                            <Select
                              labelId="demo-multiple-chip-label"
                              id="demo-multiple-chip"
                              multiple
                              value={outName}
                              onChange={(e) => selectedOutlets(e.target.value)}
                              input={
                                <OutlinedInput
                                  id="select-multiple-chip"
                                  style={{ color: "black" }}
                                  label="Select Outlet(s)"
                                />
                              }
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected.map((value) => (
                                    <Chip
                                      key={value}
                                      label={`${value.split("split")[1]}`}
                                    />
                                  ))}
                                </Box>
                              )}
                              IconComponent={() => <KeyboardArrowDownIcon />}
                              MenuProps={MenuProps}
                            >
                              {outlets.map((outlet, i) => (
                                <MenuItem
                                  key={i}
                                  value={
                                    outlet.outletChainID +
                                    "split" +
                                    outlet.outletName
                                  }
                                  style={getStyles(outlet, outName, theme)}
                                >
                                  {outlet.outletName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {/* <input type="text" value={outletName} onChange={(e) => setOutletName(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="name" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-12">
                  <h5>Deal Details</h5>
                  <br></br>
                </div>
                <div className="col-md-8">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Deal Name*</label>
                      <input
                        type="text"
                        value={dealName}
                        onChange={(e) => setDealName(e.target.value)}
                        className="form-control"
                        // id="exampleFormControlInput1"
                        placeholder="Deal Name"
                      />
                      {formValidationErrors.dealError && (
                        <span className="emsg">
                          {formValidationErrors.dealError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="group-comb">
                    <div
                      className="form-group img-drop"
                      style={{
                        width: "92%",
                        position: "absolute",
                        top: "250%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          id="image"
                          name="Image"
                          onChange={imageSelector}
                          accept="image/png, image/jpeg"
                        />
                        Browse
                      </label>
                      <img
                        alt=""
                        width="150"
                        height="120"
                        src={image}
                        className="rounded mx-auto d-block galimage"
                      ></img>
                      {showCrop && (
                        <Dialog
                          fullScreen={fullScreen}
                          open={open}
                          fullWidth={true}
                          onClose={() => setOpen(false)}
                          aria-labelledby="responsive-dialog-title"
                        >
                          <div
                            className="App"
                            style={{
                              padding: "1em",
                              width: "auto",
                              height: "auto",
                            }}
                          >
                            <div className="crop-container">
                              <Cropper
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={4 / 3}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                              />
                            </div>
                            <div className="controls">
                              <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => setZoom(zoom)}
                                // classes={{ root: "slider" }}
                              />
                            </div>
                            <div>
                              <button
                                className="btn btn-primary crop-btn"
                                style={{
                                  justifyContent: "center",
                                }}
                                onClick={(e) => showCroppedImage(e)}
                              >
                                Crop
                              </button>
                            </div>
                          </div>
                        </Dialog>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Discount Value*</label>
                      <input
                        type={"number"}
                        value={discountValue}
                        onKeyPress={(e) => {
                          var charCode =
                            typeof e.which == "undefined" ? e.keyCode : e.which;
                          var charStr = String.fromCharCode(charCode);

                          if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
                        }}
                        onChange={(e) => {
                          let val = parseInt(Math.abs(e.target.value));
                          if (val < 100) {
                            setDiscountValue(val + "");
                          } else {
                            setDiscountValue("");
                          }
                        }}
                        className="form-control"
                        id="exampleFormControlInpu1"
                        placeholder="10 %"
                        min="0"
                        max="99"
                      />

                      {discountValue !== "" ? (
                        <span
                          style={{
                            position: "absolute",
                            top: "31px",
                            left: `${
                              Math.pow((discountValue + "").length, 3) + 40
                            }px`,
                          }}
                        >
                          %
                        </span>
                      ) : (
                        ""
                      )}
                      {formValidationErrors.discountError && (
                        <span className="emsg">
                          {formValidationErrors.discountError}
                        </span>
                      )}
                    </div>
                    {/* {validationMessages.discountValue ? (
                      <span className="validation-help">
                        {validationMessages.discountValue}
                      </span>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>Applies to</label>
                      <select
                        onChange={(e) => setSelectedOne(e.target.value)}
                        className="form-control"
                        value={appliesTo}
                        id="exampleFormControlSelect1"
                      >
                        <option value="Selected Products">
                          Selected Products
                        </option>
                        <option value="Selected Categories">
                          Selected Categories
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>
                        {appliesTo === "Selected Products"
                          ? "Pick Products*"
                          : "Pick Categories*"}
                      </label>
                      {appliesTo === "Selected Products" && (
                        <FormControl sx={{ m: 1, width: 625 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                              marginBottom: "1em",
                            }}
                          >
                            {prodName.map((value, i) => {
                              return (
                                <Chip
                                  key={value}
                                  label={`${value.split("split")[1]}`}
                                  onDelete={() =>
                                    selectedProducts(
                                      prodName.filter(
                                        (el) =>
                                          el.split("split")[0] !==
                                          value.split("split")[0]
                                      )
                                    )
                                  }
                                />
                              );
                            })}
                          </Box>
                          <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={prodName}
                            onChange={(e) => selectedProducts(e.target.value)}
                            displayEmpty
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="Select Outlet (s)"
                              />
                            }
                            renderValue={(selected) =>
                              selected?.length ? "" : "Choose Products"
                            }
                            // renderValue={(selected) => (
                            //   <Box
                            //     sx={{
                            //       display: "flex",
                            //       flexWrap: "wrap",
                            //       gap: 0.5,
                            //     }}>
                            //     {selected.map((value) => (
                            //       <Chip
                            //         key={value}
                            //         label={`${value.split("split")[1]}`}
                            //         onDelete={() => console.log("Deleted")}
                            //       />
                            //     ))}
                            //   </Box>
                            // )}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={MenuProps}
                          >
                            {products.map((prod, i) => {
                              // console.log('product', prod.product);
                              return (
                                <MenuItem
                                  key={i}
                                  value={
                                    prod.product.productID +
                                    "split" +
                                    prod.product.name
                                  }
                                  style={getStyles(
                                    prod.product,
                                    outName,
                                    theme
                                  )}
                                >
                                  {prod.product.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}

                      {appliesTo === "Selected Categories" && (
                        <FormControl sx={{ m: 1, width: 625 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              marginBottpm: "1em",
                              gap: 0.5,
                            }}
                          >
                            {catName.map((value, i) => {
                              return (
                                <Chip
                                  key={value}
                                  label={`${value.split("split")[1]}`}
                                  onDelete={() =>
                                    selectedCategories(
                                      catName.filter(
                                        (el) =>
                                          el.split("split")[0] !==
                                          value.split("split")[0]
                                      )
                                    )
                                  }
                                />
                              );
                            })}
                          </Box>
                          <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={catName}
                            onChange={(e) => selectedCategories(e.target.value)}
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="Select Outlet (s)"
                              />
                            }
                            displayEmpty
                            renderValue={(selected) =>
                              selected?.length ? "" : "Choose Categories"
                            }
                            // renderValue={(selected) => (
                            //   <Box
                            //     sx={{
                            //       display: "flex",
                            //       flexWrap: "wrap",
                            //       gap: 0.5,
                            //       overflow: "auto",
                            //     }}>
                            //     {selected.map((value, image) => (
                            //       <Chip
                            //         key={value}
                            //         label={`${value.split("split")[1]}`}
                            //       />
                            //     ))}
                            //   </Box>
                            // )}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={MenuProps}
                          >
                            {categories.map((cate, i) => (
                              <MenuItem
                                key={i}
                                value={cate.categoryID + "split" + cate.name}
                                style={getStyles(cate, outName, theme)}
                              >
                                {cate.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      {/* <input type="text" value={outletName} onChange={(e) => setOutletName(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="name" /> */}
                      {formValidationErrors.selectError && (
                        <span className="emsg">
                          {formValidationErrors.selectError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <h5>Validity</h5>
                  <br />
                </div>

                <div className="col-md-8 validity-form">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      checked={neverExpires}
                      onChange={(e) => {
                        setNeverExpires(!neverExpires);
                        setSpecificDates(neverExpires);
                      }}
                      className="form-check-input "
                      id="exampleCheck1"
                    />
                    <label
                      className="form-check-label checkmark"
                      for="exampleCheck1"
                    >
                      Never Expires
                    </label>
                  </div>
                  <div className="form-check sds">
                    <input
                      type="checkbox"
                      checked={specificDates}
                      onChange={(e) => {
                        setSpecificDates(!specificDates);
                        setNeverExpires(specificDates);
                      }}
                      className="form-check-input"
                      id="exampleCheck2"
                    />
                    <label
                      className="form-check-label checkmark"
                      for="exampleCheck2"
                    >
                      Specific Dates
                    </label>
                  </div>
                </div>

                {neverExpires ? (
                  ""
                ) : (
                  <div className="col-md-8 date-start-end">
                    {!neverExpires ? (
                      <div className="row data-time-parent">
                        <div className="col-md-6">
                          <div className="group-comb">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>Start Date</label>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DateTimePicker
                                      style={{ width: "100%" }}
                                      value={startDate}
                                      onChange={handleChange}
                                      minDate={new Date()}
                                      // maxDate={subDays(new Date(endDate), 1)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          value={startDate}
                                          onChange={(e) =>
                                            handleChange(
                                              new Date(e.target.value)
                                            )
                                          }
                                          error={!!dateRelatedErrors.start}
                                          helperText={dateRelatedErrors.start}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Renewal Date" /> */}
                                </div>

                                {/* <div className="col-md-2 icon-input" >
                                                    <i className="far fa-calendar"></i>
                                                    </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="group-comb">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>End Date</label>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DateTimePicker
                                      style={{ width: "100%" }}
                                      value={endDate}
                                      onChange={handleChange1}
                                      minDate={addDays(startDate, 1)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          value={endDate}
                                          onChange={(e) =>
                                            handleChange1(e.target.value)
                                          }
                                          error={!!dateRelatedErrors.end}
                                          helperText={dateRelatedErrors.end}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Renewal Date" /> */}
                                </div>

                                {/* <div className="col-md-2 icon-input" >
                                                    <i className="far fa-calendar"></i>
                                                    </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}

                {/* <div className='col-md-12 date-start-end'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    
                                            <DateTimePicker
                                                label="Start Date"
                                                value={startDate}
                                                onChange={handleChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            <DateTimePicker
                                                label="End Date"
                                                value={endDate}
                                                onChange={handleChange1}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                </div> */}

                <div className="col-md-8">
                  <br />
                  <h5 className="">Status</h5>
                </div>
                <div className="col-md-8 switch-col">
                  <label className="switch  switch-demo">
                    <input
                      type="checkbox"
                      checked={activeStatus}
                      onChange={() => setActiveStatus(!activeStatus)}
                    />
                    <span className="slider round"></span>
                    <span
                      className="slider-text"
                      style={{ paddingLeft: "81px" }}
                    >
                      {activeStatus ? "Enabled" : "Disabled"}
                    </span>
                  </label>
                </div>
                <div className="col-md-8">
                  <button
                    type="button"
                    onClick={() => fileUpload()}
                    disabled={
                      (Object.keys(dateRelatedErrors).length !== 0 &&
                        !neverExpires) ||
                      inProgress
                    }
                    className="btn  btn-cannaby"
                  >
                    {inProgress ? "Creating..." : "Create Deal"}
                  </button>
                  <button
                    style={{ marginLeft: "20px", marginTop: "30px" }}
                    type="button"
                    onClick={() => history.push("/deals")}
                    className="btn btn-rnav"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        {message.status ? (
          <div style={{ textAlign: "center" }}>
            <i
              style={{
                color: "green",
                fontSize: "70px",
              }}
              // style={{
              //   color: "green",
              //   fontSize: "60px",
              //   border: "10px solid green",
              //   borderRadius: "50px",
              //   padding: "10px",
              // }}
              class="fa fa-check"
            ></i>
            <h2 className="popup-heading" style={{ marginTop: "20px" }}>
              Success
            </h2>
            <p>Deal added successfully</p>
            <button
              className="btn btn-cannaby"
              style={{ marginTop: "0" }}
              onClick={() => {
                closeTooltip();
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <i
              style={{
                color: "red",
                fontSize: "70px",
              }}
              className="fa fa-ban"
            ></i>
            <h2 style={{ marginTop: "20px" }}>Failed</h2>
            <button
              style={{ marginTop: "10px" }}
              type="button"
              onClick={closeTooltip}
              className="btn btn-primary"
            >
              Try Again
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}
