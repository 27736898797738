import { useEffect, useState } from "react";
import url from "../../config/axios";
import emptyImg from "../../assets/Empty-pana 1.png";
import "./inventory.css";

function AddModal({ closeAddModal, GetAllInventory, myProduct }) {
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [selectOutlet, setSelectOutlet] = useState("");
  const [singleProductDetails, setSingleProductDetails] = useState(null);
  const [stockQty, setStockQty] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [track, setTrack] = useState(false);

  const nextStep = () => {
    setStepTwo(!stepTwo);
    setStepOne(!stepOne);
  };

  const backStep = () => {
    setStepTwo(!stepTwo);
    setStepOne(!stepOne);
  };

  useEffect(() => {
    if (myProduct != null) {
      setSingleProductDetails(myProduct);
      setStepOne(true);
    }
    GetAllOutlets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    // console.log(adminID[0].adminID);
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        // console.log('res', res)
        setOutlets(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const addInventory = () => {
    const admin = JSON.parse(localStorage.getItem("Admin"));
    let body = {
      outletChainID: selectOutlet,
      consumerId: admin[0].consumerId,
      productID: singleProductDetails.productID,
      quantity: stockQty,
      originalPrice: originalPrice,
      discountPrice: discountPrice,
    };
    console.log(body, "body");

    url
      .post(`/inventory/`, body)
      .then(async (res) => {
        // console.log(res, 'res')
        if (res.data.Message === "Failure") {
          alert(res.data.data);
        } else {
          alert("Inventory Added");
          // history.push("/outlets");
          closeAddModal(false);
          GetAllInventory();
        }
      })
      .catch((e) => console.log(e));
  };

  const styles = {
    display: "block",
    // backgroundColor: "rgb(0,0,0)" /* Fallback color */,
    backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */,
    fontFamily: "Roboto",
    overflowX: "hidden",
    overflowY: "auto",
  };

  const title = {
    fontSize: "22px",
    fontWeight: "500",
  };

  const text1 = {
    fontSize: "16px",
    fontWeight: "500",
    color: "#1F6355",
  };

  const text2 = {
    fontSize: "12px",
    fontWeight: "400",
    // color: "#1F6355",
  };

  const mark = {
    color: "#898989",
    background: "none",
  };

  const productDetails = {
    backgroundColor: "#f9f9f9",
    borderRadius: "7px",
  };

  const title2 = {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "700",
    color: "#000000",
  };

  return (
    <div className="modal" style={styles}>
      <div className="mdl modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header" style={title}>
            <span>Add Product to Inventory</span>
            <i
              className="fas fa-times"
              onClick={() => {
                closeAddModal(false);
              }}
            />
          </div>
          <div className="modal-body">
            <div className="row mx-1 align-items-center">
              <div className="col-md-6 step-1 step-active">
                <div className="row align-items-center">
                  <div className="col-2   m-0 ">
                    <div className="step-circle step-circle-active">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-10" style={text2}>
                    <p className="m-0">Step 1:</p>
                    <p className="m-0">Select Your Product</p>
                  </div>
                </div>
              </div>
              <div
                className={`col-md-6 step-1 ${stepTwo ? "step-active" : ""}`}>
                <div className="row align-items-center">
                  <div className="col-2   m-0 ">
                    <div
                      className={`step-circle ${
                        stepTwo ? "step-circle-active" : ""
                      }`}>
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-10" style={text2}>
                    <p className="m-0">Step 2:</p>
                    <p className="m-0">Submit Billing & Inventory Details</p>
                  </div>
                </div>
              </div>
            </div>
            {!stepTwo && (
              <p style={title2} className="mt-3">
                Select Store
              </p>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-group">
                      <select
                        className="form-control mt-3"
                        disabled={stepTwo}
                        onChange={(e) => setSelectOutlet(e.target.value)}
                        onClick={(e) => GetAllOutlets(e.target.value)}
                        id="exampleFormControlSelect1">
                        <option value="asdf">Select Outlet</option>
                        {outlets.map((outlet, i) => {
                          return (
                            <option value={outlet.outletChainID} key={i}>
                              {outlet.outletName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {stepTwo && (
                <>
                  <div className="col-md-12">
                    <div
                      className="d-flex justify-content-start p-3"
                      style={productDetails}>
                      <img
                        src={singleProductDetails.image}
                        alt="product"
                        width="40px"
                        height="40px"
                      />
                      <span className="mx-3">
                        <p style={text1} className="m-0 p-0">
                          {singleProductDetails.name}
                        </p>
                        <p style={text2} className="m-0 p-0">
                          <mark style={mark}>Brand: </mark>{" "}
                          {singleProductDetails.brandID}
                        </p>
                      </span>
                    </div>
                  </div>

                  <div className="col-md-12 mt-4">
                    <p style={title2} className="my-3">
                      Inventory Details
                    </p>
                    <span className="solo-chain">Track Inventory?</span>
                    <div className="row mt-2">
                      <div className="col-md-4 switch-col">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={track}
                            onChange={() => setTrack(!track)}
                          />
                          <span className="slider round"></span>
                        </label>
                        <span className="ml-3">Yes</span>
                      </div>

                      {track && (
                        <div className="col-md-4">
                          <div className="group-comb">
                            <div className="form-group border border-secondary">
                              <label>Quantity in Stock</label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="3"
                                value={stockQty}
                                onChange={(e) => setStockQty(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-md-2"></div>
                    </div>
                    {/* )} */}
                  </div>
                  <div className="col-md-12">
                    <p style={title2} className="my-3">
                      Billing Details
                    </p>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="group-comb">
                          <div className="form-group border border-secondary">
                            <label>Discounted Price</label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput"
                              placeholder="$ 20.00"
                              value={discountPrice}
                              onChange={(e) => setDiscountPrice(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="group-comb">
                          <div className="form-group border border-secondary">
                            <label>Original Price</label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInpu"
                              placeholder="$ 25.00"
                              value={originalPrice}
                              onChange={(e) => setOriginalPrice(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {!stepOne && !stepTwo && (
              <div className="row align-items-center m-5">
                <div className="col-md-12">
                  <img
                    src={emptyImg}
                    className="mx-auto d-block"
                    width="105px"
                    height="105px"
                    alt=""
                  />
                </div>
              </div>
            )}
            {stepOne && (
              <div className="row align-items-center size-text ">
                <div className="col-md-12">
                  {/* <img src={emptyImg} className="mx-auto d-block" />{" "} */}
                  <div className="row bg-secondary p-3 rounded text-white sel-product">
                    <div className="col-md-3">
                      <img
                        src={singleProductDetails.image}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-md-9 ">
                      <div className="row">
                        <div className="col-md-12">
                          <p className="top-head">
                            {" "}
                            {singleProductDetails.name}
                          </p>
                        </div>
                        <div className="col-md-5 second-row">
                          Brand: <span>{singleProductDetails.brandName}</span>
                        </div>
                        <div className="col-md-5 second-row">
                          Categories:{" "}
                          <span>
                            {" "}
                            {singleProductDetails.category.length
                              ? singleProductDetails.category[0].name
                              : ""}
                          </span>
                        </div>
                        <div className="col-md-2"></div>

                        <div className="col-md-5 percent">
                          <i className="fas fa-sort-down mr-2" />
                          <span>{singleProductDetails.thc}% TBH</span>
                        </div>
                        <div className="col-md-5 percent">
                          <i className="fas fa-sort-up mr-2" />
                          <span>{singleProductDetails.cbd}% CBD</span>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-12">
                          <p className="prod-p">
                            {singleProductDetails.shortDescription},{" "}
                            {singleProductDetails.longDescription}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            {stepOne && (
              <>
                <button className="btn btn-outline-dark">Cancel</button>
                <button className="btn btn-primary" onClick={() => nextStep()}>
                  Next
                </button>
              </>
            )}
            {stepTwo && (
              <>
                <button
                  className="btn btn-outline-dark"
                  onClick={() => backStep()}>
                  Back
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => addInventory()}>
                  Publish
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddModal;
