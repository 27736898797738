// eslint-disable-next-line react-hooks/exhaustive-deps
// import crown from "../../assets/crown.png";
// import dollar from "../../assets/dollar.png";
import phone from "../../assets/phone.svg";
import mail from "../../assets/mail.svg";
import home from "../../assets/home.svg";
// import msg from "../../assets/msg.svg";
import url from "../../config/axios";
import blank from "../../assets/defaultImage.jpg";
import blankUser from "../../assets/defaultUser.jpg";
// import dollar from "../../assets/dollar.png";
import { useState, useEffect } from "react";
import moment from "moment";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useHistory } from "react-router-dom";

export default function UserDetails() {
  const [cart, setCart] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loyalty, setLoyalty] = useState([]);
  const user = JSON.parse(localStorage.getItem("singleUser"));
  const admin = JSON.parse(localStorage.getItem("Admin"));
  const [pointsToPrice, setPointsToPrice] = useState();
  const history = useHistory();
  useEffect(() => {
    getOrderDetails();
    getCartDetails();
    GetLoyaltyStandards();
    GetLoyaltyPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrderDetails = async () => {
    url
      .get(`/order/customer/?customerID=${user.userID}`)
      .then((res) => {
        console.log(res);
        if (res.data.Message === "Success") {
          setOrders(res.data.data);
        } else {
          console.log(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetLoyaltyPoints = async () => {
    await url
      .get(
        `/loyaltyPoints/id?userID=${user.userID}&outletID=${admin[0].adminID}`
      )
      .then(async (res) => {
        console.log("response", res);
        if (res.data.Message === "Success") {
          await setLoyalty(res.data.data);
        } else {
          url
            .post(`/loyaltyPoints?outletID=${admin[0].outletChainID}`, {
              userID: user.userID,
              points: 0,
            })
            .then(async (res) => {
              if (res.data.Message === "Success") {
                GetLoyaltyPoints();
              }
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };

  console.log(loyalty, "HAH");

  const GetLoyaltyStandards = async () => {
    await url
      .get(`/loyalty?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        console.log("ress", res);
        if (res.data.Message === "Success") {
          setPointsToPrice(res.data.data.pointsToPrice);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const getCartDetails = async () => {
    url
      .get(`/cart/?customerId=${user.userID}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          console.log(res.data.data, "cart");
          setCart(res.data.data);
        } else {
          console.log(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const calculateTotal = () => {
    var sum = 0;
    cart[0]?.products?.forEach((item) => {
      sum = sum + item.quantity * item.variant.price;
    });
    return sum;
  };

  console.log(orders.length, "K");

  function checkColor(value) {
    let result;
    switch (value) {
      case "Pending":
        result = "color-red";
        break;
      case "New":
        result = "color-blue";
        break;
      case "Cancelled":
        result = "color-brown";
        break;
      case "Delivered":
        result = "color-purple";
        break;
      case "Dispatched":
        result = "color-green";
        break;
      default:
        result = "";
        break;
    }
    return result;
  }

  return (
    <div className="row">
      <div className="col-md-4">
        <div className="card-new usr-m-b" style={{ height: "100%" }}>
          <div className="card-history-user">
            <div className="row">
              <div className="col-md-12 cust-user-img">
                <img src={user.image ? user.image : blankUser} alt="crown" />
              </div>

              <div className="col-md-12 cust-user-name">{user.name}</div>
              <div className="col-md-12 cust-user-activity">
                {moment(user.updatedAt).format("MMMM Do YYYY, h:mm A")}
              </div>
              {/* Adding membership box from here */}

              <div className="memberShip">
                <span className="membership-heading">Loyalty Points</span>
                <div className="col-md-11 mem-text">
                  {loyalty.points} Loyalty Points (
                  {parseFloat(loyalty.points * pointsToPrice).toFixed(2)} $)
                </div>
              </div>

              {/* ends here */}
              <div className="col-md-12 contact-head">
                <p>Contact</p>
              </div>

              <div className="col-md-12 contact-info icon-text-div">
                {user.email ? (
                  ""
                ) : (
                  <div style={{ display: "flex" }}>
                    <div className="cIcon">
                      <img src={phone} alt="crown" />
                    </div>
                    <div className="cText" style={{ marginLeft: "10px" }}>
                      {user.mobileNo}
                    </div>
                  </div>
                )}
              </div>
              {user.email ? (
                <div className="col-md-12 contact-info icon-text-div">
                  <div className="cIcon">
                    <img src={mail} alt="crown" />
                  </div>
                  <div className="cText">{user.email}</div>
                </div>
              ) : (
                ""
              )}

              <div className="col-md-12 contact-head">Addresses</div>

              <div className="col-md-12 contact-info icon-text-div">
                <div className="cIcon">
                  <img src={home} alt="crown" />
                </div>
                <div className="cText">
                  <span className="add-tit">Home</span>
                  <br />
                  <span className="add-desc">
                    {user.addresses.length ? user.addresses[0].Name : ""}
                  </span>
                  <span className="add-desc">
                    {user.addresses.length ? user.addresses[0].address : "none"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-8">
        <div className="row" style={{ height: "100%" }}>
          <div
            className="col-md-12"
            style={{ position: "relative", height: "inherit" }}
          >
            <div className="card-new" style={{ height: "100%" }}>
              <div className="card-history">
                <div className="row">
                  <div
                    className="col-md-12 card-title"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>Order History</span>
                    <HighlightOffRoundedIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    />
                  </div>
                  <div className="col-md-12 table-mod">
                    {orders.length === 0 || orders === undefined ? (
                      ""
                    ) : (
                      <div className="row-cust">
                        <div className="cust-row-id th">Order ID</div>
                        <div className="cust-row-price th">Price</div>
                        <div className="cust-row-date th">Purchased On</div>
                        <div className="cust-row-status th">Status</div>
                        <div className="cust-row-btn th"></div>
                      </div>
                    )}
                  </div>
                  {/* Height of scrollbar can be changed from here */}
                  <div
                    className="col-md-12 table-mod table-mod-data custom-scrollbar"
                    style={
                      orders.length === 0 || orders === undefined
                        ? { height: "45px" }
                        : { height: "425px" }
                    }
                  >
                    {orders.map((order, i) => (
                      <div className="row-cust r-c-d" key={i}>
                        <div className="cust-row-id ttd tdi">
                          {order.orderID}
                        </div>
                        <div className="cust-row-price ttd tdp">
                          $ {order.totalPrice}
                        </div>
                        <div className="cust-row-date ttd tdd">
                          {" "}
                          {moment(order.createdAt).format(
                            "MMMM Do YYYY h:mm A"
                          )}
                        </div>
                        <div>
                          <p
                            style={{ paddingTop: "1rem" }}
                            className={
                              "cust-row-status " + checkColor(order.status)
                            }
                          >
                            {order.status}
                          </p>
                        </div>

                        {/* <button
                          style={{ backgroundColor: "white", color: "#5D5FEF", borderColor: " #5D5FEF", margin: "6px", padding: "5px" }}
                          disabled={true}
                          className="btn"          >
                          <i className="fas fa-eye"></i>View Details
                        </button> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {orders.length === 0 || orders === undefined ? (
              <h5
                style={{
                  opacity: "30%",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,50%)",
                }}
              >
                No order has been placed yet
              </h5>
            ) : (
              ""
            )}
          </div>

          {/* card 2 */}
        </div>
      </div>
      <div className="col-md-12 abc-m-t" style={{ position: "relative" }}>
        <div className="card-new">
          <div className="card-history">
            <div className="row">
              <div className="col-md-12 card-title card-title-2-data">
                <span>Abandoned Cart</span>

                <span className="title-right">
                  Amount (including tax & shipping):{" "}
                  <span className="pricer" style={{ color: "grey" }}>
                    ${calculateTotal()}
                  </span>
                </span>
              </div>

              <div className="col-md-12 table-ac table-mod table-mod-data-ac custom-scrollbar">
                {cart[0]?.products?.map((item, i) => {
                  return (
                    <div className="row-cust-ac " key={i}>
                      <div className="cust-row-ac-img">
                        <img
                          alt=""
                          src={
                            item.product.image !== null
                              ? item.product.image
                              : blank
                          }
                          style={{ width: "95%", paddingRight: "25px" }}
                        />
                      </div>
                      <div className="cust-row-ac-body">
                        {item.product.name}
                      </div>
                      <div className="cust-row-ac-desc">
                        <span>
                          Brand :
                          <span className="ac-green">
                            {" "}
                            {item.product.brandName}
                          </span>
                        </span>
                        <span className="acseller">
                          Seller :
                          <span className="ac-green"> {admin[0].address}</span>
                        </span>
                      </div>
                      <div className="cust-row-ac-price">
                        {item.quantity} x {item.variant.price}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {cart[0]?.products.length === 0 || cart[0]?.products === undefined ? (
          <h5
            style={{
              opacity: "30%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,50%)",
            }}
          >
            No product has been added to cart
          </h5>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
