import url from "../../config/axios";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Popup from "reactjs-popup";

export default function Privacy() {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [Body, setBody] = useState("");
  // const [outletId, setOutletId] = useState("");
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => ref.current.close();

  const queryParams = new URLSearchParams(window.location.search);
  let pageName = queryParams.get("id");

  const admin = JSON.parse(localStorage.getItem("Admin"));

  const addPage = () => {
    console.log("Check");
    // console.log(admin[0].isSubOutlet);
    let body = {
      pageName: pageName,
      consumerId: admin[0].consumerId,
      body: Body,
      title: title,
      subtitle: subtitle,
    };

    url
      .put(`/page?consumerId=${admin[0].consumerId}&page=${pageName}`, body)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setMessage({
            text: "Success",
            status: true,
            display: true,
          });
          openTooltip();
          setTimeout(() => {
            history.push("/Page");
          }, 1000);
        } else {
          setMessage({
            text: "Failed",
            status: true,
            display: true,
          });
          openTooltip();
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    function getPage() {
      console.log(admin[0].consumerId, pageName);
      url
        .get(`/page/id?consumerId=${admin[0].consumerId}&page=${pageName}`)
        .then(async (res) => {
          if (res.data.Message === "Success") {
            if (res.data.data.length === 0) {
              console.log(res.data.data);
              setTitle("");
              setSubtitle("");
              setBody("");
            } else {
              console.log(res.data.data);
              setTitle(res.data.data.title);
              setSubtitle(res.data.data.subtitle);
              setBody(res.data.data.body);
            }
          }
        });
    }
    getPage();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {" "}
      <div className="row">
        <div className="col-md-12">
          <div className="card card-pro">
            {/* <button
              className="btn btn-primary btn-pro mr-5"
              onClick={() => history.push("/add-coupon")}
            >
              <i className="fas fa-plus-circle"></i>
              Add Coupon
            </button> */}
            {/* onClick={() => {
    history.push("/addCatalog");
  }} */}
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="group-comb">
                      <div className="form-group">
                        <label>Title *</label>
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className="form-control"
                          placeholder="Title"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="group-comb">
                      <div className="form-group">
                        <label>Subtitle *</label>
                        <input
                          type="text"
                          value={subtitle}
                          onChange={(e) => setSubtitle(e.target.value)}
                          className="form-control"
                          placeholder="Subtitle"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="group-comb">
                      <div className="form-group">
                        <label>Long Description</label>
                        {/* <textarea
                        type="text"
                        value={ldesc}
                        name="content" 
                        id="editor"
                        onChange={(e) => setLDesc(e.target.value)}
                        className="form-control t-a"
                        style={{ height: '150px' }}
                        placeholder="name"
                      /> */}
                        <CKEditor
                          row={"20"}
                          editor={ClassicEditor}
                          data={Body}
                          onReady={(editor) => {
                            editor.editing.view.change((writer) => {
                              writer.setStyle(
                                "height",
                                "400px",
                                editor.editing.view.document.getRoot()
                              );
                            });
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setBody(data);
                            // console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            console.log("Blur.", editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log("Focus.", editor);
                          }}
                          config={{
                            toolbarLocation: "bottom",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="button"
                      onClick={addPage}
                      className="btn btn-cannaby"
                    >
                      Update
                    </button>

                    <button
                      style={{ marginLeft: "20px", marginTop: "30px" }}
                      type="button"
                      onClick={() => history.push("/page")}
                      className="btn btn-rnav"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        {message.status ? (
          <div style={{ textAlign: "center" }}>
            <i
              style={{
                color: "green",
                fontSize: "70px"
              }}
              // style={{
              //   color: "green",
              //   fontSize: "60px",
              //   border: "10px solid green",
              //   borderRadius: "50px",
              //   padding: "10px",
              // }}
              class="fa fa-check"
            ></i>
            <h2 className="popup-heading" style={{ marginTop: "20px" }}>
              Success
            </h2>
            <p>Page updated successfully</p>
            <button
              className="btn btn-cannaby"
              style={{ marginTop: "0" }}
              onClick={() => {
                closeTooltip();
              }}
            >
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <i
              style={{
                color: "red",
                fontSize: "70px",
              }}
              className="fa fa-ban"
            ></i>
            <h2 style={{ marginTop: "20px" }}>Failed</h2>
            <button
              style={{ marginTop: "10px" }}
              type="button"
              onClick={closeTooltip}
              className="btn btn-primary"
            >
              Try Again
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}
