// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";

import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyD_jmaNAPyo9DN1jw3BID7LigHbajc_mN0",
  authDomain: "cannaby-firebase.firebaseapp.com",
  projectId: "cannaby-firebase",
  storageBucket: "cannaby-firebase.appspot.com",
  messagingSenderId: "258107229479",
  appId: "1:258107229479:web:606f7395394cffccc99502",
  measurementId: "G-LJGBRR2HBH",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();

export default storage;
