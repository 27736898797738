import storage from "../config/firebase";
export const fileUpload = async (file) => {
  try {
    await storage.ref(`images/${file.name}`).put(file);
    const url = await storage.ref("images").child(file.name).getDownloadURL();
    console.log("url is: ", url);
    return url;
  } catch (e) {
    console.error("File upload error ", e);
    return null;
  }
  //   await uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       // const progress = Math.round(
  //       //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //       // );
  //     },
  //     (error) => {
  //       console.error(error);

  //     },
  //     () => {
  //       storage
  //         .ref("images")
  //         .child(file.name)
  //         .getDownloadURL()
  //         .then((blob) => {
  //           console.log("Uploaded blob: ", blob);

  //         });
  //     }
  //   );
};
